import { reactive, toRefs } from "vue";
import { dispatchNote } from "@/stores/dispatchNoteStore.js";
import { dispatchNoteItem } from "@/stores/dispatchNoteItemStore.js";
import { user } from "@/stores/userStore.js";
import { customer } from "@/stores/customerStore.js";
import { domain } from "@/stores/domainStore";
import { invoice } from "@/stores/invoiceStore.js";
import { warehouse } from "@/stores/warehouseStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { invoiceItem } from "@/stores/invoiceItemStore.js";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";
import { warehouseItemStatusPriceAverage } from "@/stores/warehouseItemStatusPriceAverageStore.js";
import {
    updateWarehouseItemStatusOnNewDispatchNote,
    updateWarehouseItemStatusOnRemovedDispatchNote,
} from "@/utils/updateWarehouseItemStatus.js";
import eventBus from "../../eventBus.js";
import moment from "moment";
import { generateInvoiceData } from "@/utils/generateInvoiceData.js";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime";
export function useInvoice() {
    const state = reactive({
        dispatchNote,
        dispatchNoteItem,
        user,
        customer,
        domain,
        invoice,
        warehouse,
        domainUser,
        invoiceItem,
        businessYear,
        priceListItem,
        warehouseItemStatus,
        emailSignatureDefault,
        warehouseItemStatusPriceAverage,
        editingInvoice: null,
        editingInvoiceItem: null,
        signature: null,
        invoiceItemData: [],
        rowsToBeDeleted: [],
        invoiceItemsBeforeEdit: [],
        editingInvoiceItemOldData: [],
        columnChooserMode: "select",
        userSignature: null,
        pdfForEmail: null,
        emailFormData: {
            sourceType: "proforma",
            sourceId: null,
            senderId: null,
            dateSent: null,
            clientId: null,
            emailFrom: null,
            emailTo: null,
            subject: null,
            message: null,
        },
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        adminUsername: localStorage.getItem("username"),
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
        businessYearId: localStorage.getItem("businessYearId"),
    });
    function handleRowInsertingInvoice(e, date, number, delay, warehouseId) {
        const now = new Date();
        e.data = {
            ...e.data,
            date: date,
            number: number,
            delay: delay,
            delivery: date,
            paymentDate: null,
            exchangeRate: null,
            returnAmount: null,
            warehouseId: warehouseId,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: Number(state.domainId),
            status: "new",
            taxValue: 25,
            taxName: "PDV 25%",
        };
    }
    async function handleRowInsertedInvoice(e, invoiceItemData = null) {
        if (invoiceItemData !== null) state.invoiceItemData = invoiceItemData;
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        const userData =
            Number(state.domainId) === 1
                ? await user.byKey(e.data.userId)
                : await customer.byKey(e.data.userId);
        const now = new Date();
        const allItemPromises = state.invoiceItemData.map((item) => {
            return priceListItem.byKey(item.priceListItemId).then((res) => {
                if (item.id) delete item.id;
                if (item.dispatchNoteId) delete item.dispatchNoteId;
                delete item.__KEY__;
                Object.assign(item, {
                    invoiceId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: Number(state.adminId),
                    modifiedById: Number(state.adminId),
                    createdDate: now.toISOString(),
                    modifiedDate: now.toISOString(),
                    priceListItem: JSON.stringify(res),
                });
                return invoiceItem.insert(item).then(() => {
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                });
            });
        });
        await Promise.all(allItemPromises);
        const totalAmountAfterTax = totalAmountDiscounted * 1.25;
        const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
        await invoice.update(e.key, {
            totalAmountTaxed: totalAmountAfterTax,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty,
            user: JSON.stringify(userData),
        });
        state.invoiceItemData = [];
    }
    function handleEditingStartInvoice(e) {
        state.editingInvoice = e.data;
        invoiceItem.load();
    }
    function handleEditCanceledInvoice() {
        state.invoiceItemData = [];
        state.rowsToBeDeleted = [];
    }
    async function handleRowUpdatingInvoice(e) {
        const now = new Date();
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        const userData = e.newData.userId
            ? Number(state.domainId === 1)
                ? await user.byKey(e.newData.userId)
                : await customer.byKey(e.newData.userId)
            : null;
        const allPromises = [];
        const deletePromises = state.rowsToBeDeleted.map((item) => {
            return invoiceItem.remove(item.id).then(() => {
                state.rowsToBeDeleted = [];
            });
        });
        allPromises.push(...deletePromises);
        const insertPromises = state.invoiceItemData.map((item) => {
            return priceListItem.byKey(item.priceListItemId).then((res) => {
                if (item.id) {
                    invoiceItem.remove(item.ide);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    invoiceId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: Number(state.adminId),
                    modifiedById: Number(state.adminId),
                    createdDate: now.toISOString(),
                    modifiedDate: now.toISOString(),
                    priceListItem: JSON.stringify(res),
                });
                return invoiceItem.insert(item).then(() => {
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                });
            });
        });
        allPromises.push(...insertPromises);
        await Promise.all(allPromises);
        const totalAmountAfterTax = totalAmountDiscounted * 1.25;
        const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
        const updateData = {
            totalAmountTaxed: totalAmountAfterTax,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty,
        };
        if (userData !== null) {
            updateData.user = JSON.stringify(userData);
        }
        await invoice.update(e.key, updateData);
        state.invoiceItemData = [];
    }
    function handleCancelInvoiceItem() {
        state.editingInvoiceItemOldData.pop();
        state.editingInvoiceItem = null;
    }
    function handleDeleteInvoiceItem(e) {
        state.invoiceItemData.splice(e.row.rowIndex, 1);
        if (e.row.data.id) {
            state.rowsToBeDeleted.push(e.row.data);
        }
    }
    function handleSaveNewInvoiceItem(qty, price, priceTotal) {
        const now = new Date();
        if (state.editingInvoiceItem !== null) {
            state.editingInvoiceItem.qty = qty;
            state.editingInvoiceItem.price = price;
            state.editingInvoiceItem.priceTotal = Number(priceTotal);
            state.editingInvoiceItem.modifiedDate = now.toISOString();
            state.editingInvoiceItem.modifiedById = Number(state.adminId);
            state.invoiceItemData.find((el) => {
                if (
                    state.editingInvoiceItem.__KEY__ &&
                    el.__KEY__ === state.editingInvoiceItem.__KEY__
                ) {
                    el = state.editingInvoiceItem;
                } else if (el.id === state.editingInvoiceItem.id) {
                    el = state.editingInvoiceItem;
                }
            });
            state.editingInvoiceItem = null;
        } else {
            state.editingInvoiceItem = null;
        }
    }
    function handleRowInsertingInvoiceItem(
        e,
        qty,
        price,
        priceTotal,
        discountAmount,
        discountPercentage,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            discountAmount: discountAmount,
            discountPercentage: discountPercentage,
            priceListItemId: priceListItemId,
        };
    }
    function handleEditingStartInvoiceItem(e) {
        state.editingInvoiceItem = e.data;
        const oldData = {
            ...state.editingInvoiceItem,
        };
        state.editingInvoiceItemOldData.push(oldData);
    }
    async function handleGenerateDispatchNote(e) {
        const now = new Date();
        const invoiceData = e.row.data;
        let invoiceDataDispatchNotes = [];
        if (invoiceData.dispatchNoteId !== null) {
            invoiceDataDispatchNotes = JSON.parse(invoiceData.dispatchNoteId);
        }
        if (invoiceData.warehouseId === null) {
            const message = "Skladište nije odabrano.";
            return message;
        } else {
            const lastDispatchNote = await dispatchNote.load({
                businessYearId: invoiceData.businessYearId,
                warehouseId: invoiceData.warehouseId,
            });
            const lastNumber = lastDispatchNote
                ? lastDispatchNote[0].number + 1
                : 1;
            const dispatchNoteData = {
                number: lastNumber,
                date: now.toISOString(),
                userId: invoiceData.userId,
                invoiceId: invoiceData.id,
                priceTotal: invoiceData.totalAmountTaxed,
                remark: invoiceData.remark !== null ? invoiceData.remark : null,
                createdDate: now.toISOString(),
                modifiedDate: now.toISOString(),
                createdById: Number(state.adminId),
                modifiedById: Number(state.adminId),
                warehouseId: invoiceData.warehouseId,
                businessYearId: invoiceData.businessYearId,
                domainId: invoiceData.domainId,
            };
            const dispatchNoteRes = await dispatchNote.insert(dispatchNoteData);
            const dispatchNoteItems = await invoiceItem.load({
                invoiceId: invoiceData.id,
            });
            const insertPromises = dispatchNoteItems.map((item) => {
                delete item.id;
                delete item.invoiceId;
                delete item.priceByQty;
                delete item.discountAmount;
                delete item.discountPercentage;
                delete item.priceListItem;
                item.dispatchNoteId = dispatchNoteRes.id;
                return Promise.all([
                    dispatchNoteItem.insert(item),
                    updateWarehouseItemStatusOnNewDispatchNote(
                        item,
                        invoiceData.warehouseId
                    ),
                ]);
            });
            await Promise.all(insertPromises);
            invoiceDataDispatchNotes.push(dispatchNoteRes.id);
            await invoice.update(invoiceData.id, {
                dispatchNoteId: JSON.stringify(invoiceDataDispatchNotes),
            });
        }
    }
    async function handleDeleteInvoice(e, focusedRowKey) {
        const invoiceData = await invoice.byKey(focusedRowKey);
        if (invoiceData.dispatchNoteId !== null) {
            const parsedDispatchNoteIds = JSON.parse(
                invoiceData.dispatchNoteId
            );
            await Promise.all(
                parsedDispatchNoteIds.map(async (dispatchNoteId) => {
                    const dispatchNoteData = await dispatchNote.byKey(
                        dispatchNoteId
                    );
                    const dispatchNoteItems = await dispatchNoteItem.load({
                        dispatchNoteId: dispatchNoteId,
                    });
                    const itemPromises = dispatchNoteItems.map(async (item) => {
                        await dispatchNoteItem.remove(item.id);
                        return updateWarehouseItemStatusOnRemovedDispatchNote(
                            item,
                            dispatchNoteData.warehouseId
                        );
                    });
                    await Promise.all(itemPromises);
                    return dispatchNote.remove(dispatchNoteData.id);
                })
            );
        }
        await invoice.update(focusedRowKey, {
            status: "deleted",
            dispatchNoteId: null,
        });
    }

    async function handleSendEmailPopupData(selectedRowKey) {
        const invoiceData = await invoice.byKey(selectedRowKey);
        const invoiceUserData = JSON.parse(invoiceData.user);
        state.emailFormData.emailTo = invoiceUserData.email;
        state.emailFormData.senderId = state.adminId;
        state.emailFormData.clientId = invoiceUserData.id;
        state.emailFormData.sourceId = selectedRowKey;
        state.emailFormData.dateSent = moment().format("YYYY-MM-DD HH:mm:ss");
        const domainUserData = await domainUser.byKey(state.adminId);
        const businessYearData = await businessYear.byKey(
            invoiceData.businessYearId
        );
        const domainRes = await domain.byKey(domainUserData.domainId);
        if (domainRes) {
            const defaultEmailContent = JSON.parse(
                domainRes.defaultEmailContent
            );
            if (defaultEmailContent.ponuda) {
                if (defaultEmailContent.ponuda.subject !== null) {
                    if (defaultEmailContent.ponuda.subject.includes("{var}")) {
                        const subjectToReplace =
                            defaultEmailContent.ponuda.subject.replace(
                                "{var}",
                                `${invoiceData.createdById}-${invoiceData.number}/${businessYearData.year}`
                            );
                        state.emailFormData.subject = subjectToReplace;
                    } else {
                        state.emailFormData.subject =
                            defaultEmailContent.ponuda.subject !== null
                                ? defaultEmailContent.ponuda.subject
                                : null;
                    }
                }
                state.emailFormData.emailFrom =
                    defaultEmailContent.ponuda.email_from !== null
                        ? defaultEmailContent.ponuda.email_from
                        : null;

                state.emailFormData.message =
                    defaultEmailContent.ponuda.message !== null
                        ? defaultEmailContent.ponuda.message
                        : null;
            }
        }
        const emailSignatureDefaultRes = await emailSignatureDefault.byKey(
            domainUserData.domainId
        );
        if (domainUserData.emailSignature !== null) {
            state.signature = domainUserData.emailSignature;
        } else {
            state.signature = `--<br>
            <strong>${state.adminUsername}</strong><br>
            ${emailSignatureDefaultRes.signature}`;
        }
    }
    async function handleExportingInvoiceRecap(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular", "normal");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        const combinedFilter = table.getCombinedFilter(true);
        let filterValueDate = null;
        let filterValueYear = null;
        if (combinedFilter && combinedFilter[0][0] === "date") {
            if (typeof combinedFilter.filterValue === "string") {
                filterValueDate = combinedFilter.filterValue;
            } else {
                filterValueYear = combinedFilter.filterValue;
            }
        }
        let formattedDateRange = null;
        if (filterValueDate) {
            const [year, month] = filterValueDate.split("/").map(Number);
            const startDate = new Date(year, month - 1, 1);
            const endDate = new Date(year, month, 0);
            const formatDate = (date) => {
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            };
            const startString = formatDate(startDate);
            const endString = formatDate(endDate);
            formattedDateRange = `Datum: od ${startString}. do ${endString}.`;
        } else if (filterValueYear) {
            formattedDateRange = `Datum: Godina ${filterValueYear}.`;
        } else {
            formattedDateRange = `Datum: Nije odabran`;
        }
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 20 },
            onRowExporting: (e) => {
                if (e.rowCells[0].backgroundColor === "#D3D3D3") {
                    e.rowHeight = 9;
                } else {
                    e.rowHeight = 13;
                }
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 10;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.borderColor = "#D3D3D3";
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 9;
                }
            },
        }).then(() => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const generatedTime = getCurrentDateTime();
            const generated = `Vrijeme izrade: ${generatedTime}`;
            doc.setFontSize(10);
            doc.line(15, 15, pageWidth - 15, 15);
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Rekapitulacija računa", 15, 20);
            doc.setFont("Poppins-Regular", "normal");
            doc.text(generated, pageWidth - 15, 20, { align: "right" });
            doc.text(formattedDateRange, 15, 27);
            doc.save("Rekapitulacija-računa.pdf");
        });
    }
    async function handleSendEmail(sendEmailFlag, selectedRowKey) {
        const dontChangeStatus = ["paid", "accepted", "rejected"];
        sendEmailFlag = true;
        let responseData;
        const pdfGenerationPromise = new Promise((resolve, reject) => {
            const pdfEventListener = (pdfForEmail) => {
                if (pdfForEmail && pdfForEmail.doc) {
                    resolve(pdfForEmail);
                } else {
                    reject(new Error("PDF generation failed"));
                }
            };
            eventBus.on("invoicePdfForEmail", pdfEventListener);
            eventBus.emit("exportInvoiceItemToPDF", sendEmailFlag);
        });

        try {
            const pdfFile = await pdfGenerationPromise;
            state.emailFormData.message =
                state.emailFormData.message.replace(/\\n/g, "<br>") +
                "<br>" +
                state.signature
                    .replace(/<\/p><p>/g, "<br>")
                    .replace(/<p>/g, "")
                    .replace(/<\/p>/g, "");

            const res = await invoice.byKey(selectedRowKey);
            const formData = new FormData();
            const pdfBlob = pdfFile.doc.output("blob");
            formData.append("file", pdfBlob, pdfFile.documentName);
            formData.append("json", JSON.stringify(state.emailFormData));

            const requestOptions = {
                method: "POST",
                body: formData,
            };

            const response = await fetch(
                "http://billing.gtnet.hr/cli/procedure/emailLogUpload.php",
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            responseData = await response.json();
            if (!dontChangeStatus.includes(res.status)) {
                invoice.update(selectedRowKey, {
                    status: "pending",
                });
                sendEmailFlag = false;
                return { sendEmailFlag, responseData };
            }
        } catch (error) {
            console.error(
                "Error during PDF generation or email sending: ",
                error
            );
        } finally {
            sendEmailFlag = false;
            state.emailFormData = {
                sourceType: "proforma",
                sourceId: null,
                senderId: null,
                dateSent: null,
                clientId: null,
                emailFrom: null,
                emailTo: null,
                subject: null,
                message: null,
            };
        }
    }
    async function generateInvoiceFromDispatchNotes(selectedRowKeys) {
        const { allDispatchNoteItems, userData, warehouseId } =
            await loadDispatchNoteData(selectedRowKeys);
        const invoiceData = await generateInvoiceData(
            state.adminId,
            state.domainId,
            state.businessYearId,
            warehouseId,
            null,
            userData,
            allDispatchNoteItems,
            selectedRowKeys
        );
        const newInvoice = await invoice.insert(invoiceData);
        await handleRowInsertedInvoice(
            {
                key: newInvoice.id,
                data: invoiceData,
            },
            allDispatchNoteItems
        );
        await Promise.all(
            selectedRowKeys.map(async (dispatchNoteId) => {
                await dispatchNote.update(dispatchNoteId, {
                    invoiceId: newInvoice.id,
                });
            })
        );
        return true;
    }
    async function loadDispatchNoteData(selectedRowKeys) {
        let allDispatchNoteItems = [];
        const userIds = new Set();
        const warehouseIds = new Set();
        await Promise.all(
            selectedRowKeys.map(async (dispatchNoteId) => {
                const dispatchNoteData = await dispatchNote.byKey(
                    dispatchNoteId
                );
                warehouseIds.add(dispatchNoteData.warehouseId);
                userIds.add(dispatchNoteData.userId);
                const dispatchNoteItems = await dispatchNoteItem.load({
                    dispatchNoteId: dispatchNoteId,
                });
                allDispatchNoteItems =
                    allDispatchNoteItems.concat(dispatchNoteItems);
            })
        );
        if (userIds.size > 1) {
            throw new Error(
                "Provjerite otpremnice. Više različitih korisnika odabrano."
            );
        }
        if (warehouseIds.size > 1) {
            throw new Error(
                "Provjerite otpremnice. Više različitih skladišta odabrano."
            );
        }
        const userDataPromises = Array.from(userIds).map(async (userId) => {
            return Number(state.domainId) === 1
                ? await user.byKey(userId)
                : await customer.byKey(userId);
        });
        const warehouseIdsArray = Array.from(warehouseIds);
        const warehouseId = warehouseIdsArray[0];

        const userDataArray = await Promise.all(userDataPromises);
        const userData = userDataArray[0];
        return { allDispatchNoteItems, userData, warehouseId };
    }
    return {
        ...toRefs(state),
        handleRowInsertingInvoice,
        handleRowInsertedInvoice,
        handleEditingStartInvoice,
        handleEditCanceledInvoice,
        handleRowUpdatingInvoice,
        handleCancelInvoiceItem,
        handleDeleteInvoiceItem,
        handleSaveNewInvoiceItem,
        handleRowInsertingInvoiceItem,
        handleEditingStartInvoiceItem,
        handleGenerateDispatchNote,
        handleDeleteInvoice,
        handleSendEmailPopupData,
        handleSendEmail,
        generateInvoiceFromDispatchNotes,
        handleExportingInvoiceRecap,
    };
}
