import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { intermediateWarehouse } from "@/stores/intermediateWarehouseStore";
import { intermediateWarehouseItem } from "@/stores/intermediateWarehouseItemStore";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { divWrapper } from "@/utils/divWrapper";

export function useIntermediateWarehouseItem() {
    const state = reactive({
        priceListItem,
        businessYear,
        warehouse,
        intermediateWarehouse,
        intermediateWarehouseItem,
        toWarehouseData: null,
        businessYearData: null,
        fromWarehouseData: null,
        intermediateWarehouseData: null,
        documentHeader: null,
        documentFooter: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        adminUsername: localStorage.getItem("username"),
        domainId: localStorage.getItem("userDomainId"),
    });

    async function getIntermediateWarehouseDataByKey(id) {
        state.intermediateWarehouseData = await intermediateWarehouse.byKey(id);
    }
    async function getFromWarehouseByKey() {
        state.fromWarehouseData = await warehouse.byKey(
            state.intermediateWarehouseData.fromWarehouseId
        );
    }
    async function getToWarehouseByKey() {
        state.toWarehouseData = await warehouse.byKey(
            state.intermediateWarehouseData.toWarehouseId
        );
    }
    async function getBusinessYearByKey() {
        state.businessYearData = await businessYear.byKey(
            state.intermediateWarehouseData.businessYearId
        );
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.domainId);
        if (domainData.documentSections !== null) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    function handleIntermediateWarehouseExporting(table) {
        const doc = new jsPDF();
        doc.setFontSize(8);
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 60 },
            repeatHeaders: true,
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            columnWidths: [12, 96, 20, 15, 12, 25],
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const header = `Međuskladišnica ${state.intermediateWarehouseData.number}/${state.businessYearData.year}`;
            const fromWarehouse = `Iz skladišta: ${state.fromWarehouseData.name}`;
            const toWarehouse = `U skladište: ${state.toWarehouseData.name}`;
            const footerReceiver = "Potpis primatelja: ";
            const footerSender = "Potpis pošiljatelja: ";
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(8);
            doc.setPage(1);
            doc.text(vrijeme, pageWidth - 15, 60, { align: "right" });
            doc.setFontSize(14);
            doc.text(header, 15, 50);
            doc.setFontSize(8);
            doc.text(fromWarehouse, 15, 55);
            doc.text(toWarehouse, 15, 60);
            doc.line(15, 64, pageWidth - 15, 64);
            doc.text(footerReceiver, pageWidth - 50, pageHeight - 60, {
                align: "center",
            });
            doc.text(footerSender, 50, pageHeight - 60, {
                align: "center",
            });
            doc.line(
                pageWidth - 75,
                pageHeight - 45,
                pageWidth - 25,
                pageHeight - 45
            );
            doc.line(25, pageHeight - 45, 75, pageHeight - 45);
            const documentName = `Medjuskladisnica-${state.intermediateWarehouseData.number}-${state.businessYearData.year}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        },
                    });
                },
            });
        });
    }

    return {
        ...toRefs(state),
        handleIntermediateWarehouseExporting,
        getIntermediateWarehouseDataByKey,
        getFromWarehouseByKey,
        getToWarehouseByKey,
        getBusinessYearByKey,
        getDomainData,
    };
}
