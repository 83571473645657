<template>
    <div class="dx-list-container">
        <div class="logo-container">
            <img
                class="logo-img"
                src="@/assets/skladiste_logo.svg"
                width="90"
                height="90"
                @click="goHome"
                title="Home"
            />
        </div>
        <DxList
            :data-source="navigation"
            :active-state-enabled="false"
            :hover-state-enabled="true"
            :focus-state-enabled="false"
            :on-item-click="selectedItem"
            show-scrollbar="onScroll"
            class="panel-list"
            :grouped="true"
            :collapsible-groups="true"
        />
        <div class="user">
            <img src="@/assets/person.svg" class="user-icon" alt="" />
            <span class="user-name"> {{ adminUsername }} </span>
        </div>
        <div class="logout">
            <DxButton
                :width="100"
                text="ODJAVA"
                type="danger"
                styling-mode="contained"
                @click="logout"
            />
        </div>
    </div>
</template>
<script setup>
import { DxList } from "devextreme-vue/list";
import DxButton from "devextreme-vue/button";
import eventBus from "../../eventBus.js";
import { useRouter } from "vue-router";
import notify from "devextreme/ui/notify";
const router = useRouter();
const adminUsername = localStorage.getItem("username");
const domainId = Number(localStorage.getItem("userDomainId"));
const navigation = [
    {
        key: "Materijalno računovodstvo",
        items: [
            { text: "Početno stanje", icon: "fields" },
            { text: "Primka", icon: "arrowdown" },
            { text: "Otpremnica", icon: "arrowup" },
            { text: "Međuskladišnica", icon: "arrowright" },
            { text: "Skladište", icon: "home" },
        ],
    },
    {
        key: "Osnovni podaci",
        items: [
            { text: "Cijenik", icon: "textdocument" },
            { text: "Dobavljači", icon: "group" },
            { text: "Kupci", icon: "group" },
        ],
    },
    {
        key: "Prodaja",
        items: [
            { text: "Ponuda", icon: "box" },
            { text: "Račun", icon: "box" },
        ],
    },
    {
        key: "Postavke",
        items: [{ text: "Postavke", icon: "preferences" }],
    },
];
if (domainId === 1) {
    navigation.forEach((section) => {
        if (section.key === "Prodaja") {
            section.items = section.items.filter(
                (item) => item.text !== "Ponuda"
            );
        }
    });
}
const selectedItem = (e) => {
    eventBus.emit("tableToRender", e.itemData.text);
};
const goHome = () => {
    eventBus.emit("tableToRender", "Home");
};
const logout = () => {
    const message = "Odjavljeni ste.";
    notify(
        {
            message,
            width: 450,
        },
        "success",
        2000
    );
    localStorage.removeItem("userId");
    localStorage.removeItem("userDomainId");
    localStorage.removeItem("username");
    router.push("/login");
};
</script>
<style>
.panel-list {
    height: 80vh;
    padding-top: 60px;
    background-color: var(--dx-color-main-bg);
}
.dx-list-container {
    width: 15vw;
    height: 85vh;
}
.user-icon {
    width: 30px;
}
.user-name {
    color: #1c1c1c;
    font-size: 1.4rem;
}
.user {
    margin: 0 1vw 1vh 1vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto 1vw auto;
}
@media screen and (max-width: 1024px) {
    .dx-list-container {
        height: 100vh;
        width: 100vw;
        background-color: #ffffff;
    }
    .panel-list {
        background-color: #ffffff;
        width: 100vw;
        height: 75vh;
        padding-top: 30px;
    }
    .logo-container {
        visibility: hidden; /* Keeps the layout intact */
        height: 0;
        overflow: hidden; /* Ensures no extra spacing */
    }
}
.logo-img {
    cursor: pointer;
}
.logo-container {
    margin: 5vh auto 0 auto;
    text-align: center;
}
</style>
