<template>
    <div class="container">
        <div
            style="
                padding: 5vh;
                margin: 0 auto;
                max-width: 500px;
                text-align: center;
            "
        >
            <img
                class="logo-img"
                src="@/assets/skladiste_logo.svg"
                width="90"
                height="90"
            />
        </div>
        <div class="heading">
            <h1 style="font-weight: 500">Prijava</h1>
        </div>
        <form
            @submit.prevent="login"
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
            "
        >
            <DxForm
                :data-source="loggedUser"
                @field-data-changed="onFieldDataChanged"
                label-location="top"
                label-mode="floating"
                class="dxForm"
                :col-count="6"
            >
                <DxSimpleItem
                    data-field="email"
                    :required="true"
                    :editor-options="emailEditorOptions"
                    :col-span="6"
                >
                    <DxEmailRule message="Email nije ispravan." />
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxSimpleItem
                    data-field="password"
                    :label="{ text: 'Lozinka' }"
                    :editor-options="passwordEditorOptions"
                    :col-span="6"
                >
                    <DxRequiredRule message="Obavezno polje." />
                </DxSimpleItem>
                <DxEmptyItem :col-span="6" />
                <DxEmptyItem :col-span="8" />
                <DxButtonItem
                    :button-options="submitButtonOptions"
                    :col-span="4"
                />
            </DxForm>
        </form>
        <div class="forgotPasswordContainer">
            <span
                @click="forgotPassword"
                style="
                    cursor: pointer;
                    color: cadetblue;
                    text-decoration: underline;
                "
            >
                Zaboravili ste lozinku?
            </span>
        </div>
        <div class="registerContainer">
            <span>Nemate registriranu tvrtku?</span>
            <span @click="register" style="cursor: pointer; color: cadetblue">
                Registrirajte novu tvrtku.
            </span>
        </div>
        <!-- <div class="registerContainer">
            <span
                >Pregledajte cjenik i odaberite uslugu koja vam najbolje
                odgovara</span
            >
            <a
                href="http://gtnet.hr/minierp"
                style="cursor: pointer; color: cadetblue; text-decoration: none"
                target="_blank"
            >
                ovdje.
            </a>
        </div> -->
        <div class="copyrightContainer">
            <span>COPYRIGHT © GT-NET 2024 . ALL RIGHTS RESERVED.</span>
        </div>
    </div>
</template>

<script>
//DevExpress
import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxEmailRule,
    DxRequiredRule,
    DxEmptyItem,
} from "devextreme-vue/form";
import notify from "devextreme/ui/notify";

//Stores
import { domain } from "@/stores/domainStore";
import { useDomainUserStore } from "@/stores/customDomainUserStore.js";
import { loggedUser } from "../../user";
import { ref } from "vue";
import { useRouter } from "vue-router";
import eventBus from "../../eventBus";

export default {
    name: "loginForm",
    components: {
        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxEmailRule,
        DxRequiredRule,
        DxEmptyItem,
    },
    setup() {
        const domainUserStore = useDomainUserStore();
        const router = useRouter();
        let userInput = ref({
            email: "",
            password: "",
        });
        const userFound = ref(false);
        const domainActive = ref(false);

        const submitButtonOptions = ref({
            text: "Prijava",
            useSubmitBehavior: true,
            stylingMode: "contained",
            type: "success",
            width: "100%",
            alignment: "center",
        });

        const registerDomainButtonOptions = ref({
            text: "Registracija",
            width: "100%",
            alignment: "left",
            type: "default",
            onClick: () => {
                router.push("/register-domain");
            },
        });
        const register = () => {
            router.push("/register-domain");
        };
        const forgotPassword = () => {
            router.push("/forgot-password");
        };
        const emailEditorOptions = ref({
            height: "50px",
        });
        const passwordEditorOptions = ref({
            height: "50px",
            mode: "password",
        });
        const onFieldDataChanged = (e) => {
            userInput.value[e.dataField] = e.value;
        };
        const login = async () => {
            const res = await domainUserStore.loginDomainUser(userInput.value);
            if (res.loginUserData) {
                const domainStatus = await domain.byKey(
                    res.loginUserData.user.domainId
                );
                if (domainStatus.status === "Aktivna") {
                    localStorage.setItem("userId", res.loginUserData.user.id);
                    localStorage.setItem(
                        "userDomainId",
                        res.loginUserData.user.domainId
                    );
                    localStorage.setItem(
                        "username",
                        res.loginUserData.user.username
                    );
                    localStorage.setItem("businessYearId", 13);
                    eventBus.emit("newUserLoggedIn");
                    userFound.value = true;
                    domainActive.value = true;
                } else if (domainStatus.status === "Neaktivna") {
                    userFound.value = true;
                }
            } else {
                const message = "Netočan email ili lozinka.";
                notify(
                    {
                        message,
                        width: 450,
                    },
                    "error",
                    2000
                );
            }
            if (userFound.value && domainActive.value) {
                router.push("/home");
            } else if (userFound.value && !domainActive.value) {
                const message = "Domena neaktivna!";
                notify(
                    {
                        message,
                        width: 450,
                    },
                    "error",
                    2000
                );
            }
        };

        return {
            domainUserStore,
            userInput,
            userFound,
            domainActive,
            submitButtonOptions,
            registerDomainButtonOptions,
            passwordEditorOptions,
            onFieldDataChanged,
            login,
            loggedUser,
            register,
            emailEditorOptions,
            forgotPassword,
        };
    },
    // data() {
    //     return {
    //         userInput: {
    //             email: "",
    //             password: "",
    //         },
    //         user: loggedUser,
    //         domain: domain,
    //         userFound: false,
    //         domainActive: false,
    //         submitButtonOptions: null,
    //         passwordEditorOptions: null,
    //     };
    // },
    // setup() {
    //     const domainUserStore = useDomainUserStore();
    //     return { domainUserStore };
    // },
    // created() {
    //     // console.log(this.domainUserStore.getDomainUser);
    //     this.submitButtonOptions = {
    //         text: "Sign In",
    //         useSubmitBehavior: true,
    //         width: "100%",
    //         alignment: "left",
    //         type: "success",
    //     };
    //     this.registerDomainButtonOptions = {
    //         text: "Register",
    //         width: "100%",
    //         alignment: "left",
    //         type: "default",
    //         onClick: (e) => {
    //             console.log(e);
    //             this.$router.push("/register-domain");
    //         },
    //     };
    //     this.passwordEditorOptions = {
    //         mode: "password",
    //     };
    // },
    // methods: {
    //     onFieldDataChanged(e) {
    //         this.userInput[e.dataField] = e.value;
    //     },
    //     async login() {
    //         const res = await this.domainUserStore.loginDomainUser(
    //             this.userInput
    //         );
    //         // console.log(res);
    //         if (res.loginUserData) {
    //             const domainStatus = await this.domain.byKey(
    //                 res.loginUserData.user.domainId
    //             );
    //             // console.log(domainStatus);
    //             if (domainStatus.status === "Aktivna") {
    //                 localStorage.setItem("userId", res.loginUserData.user.id);
    //                 localStorage.setItem(
    //                     "userDomainId",
    //                     res.loginUserData.user.domainId
    //                 );
    //                 localStorage.setItem(
    //                     "username",
    //                     res.loginUserData.user.username
    //                 );
    //                 localStorage.setItem("businessYearId", 13);
    //                 eventBus.emit("newUserLoggedIn");
    //                 this.userFound = true;
    //                 this.domainActive = true;
    //             } else if (domainStatus.status === "Neaktivna") {
    //                 this.userFound = true;
    //             }
    //         } else {
    //             const message = "Invalid email or password.";
    //             notify(
    //                 {
    //                     message,
    //                     width: 450,
    //                 },
    //                 "error",
    //                 2000
    //             );
    //         }
    //         if (this.userFound && this.domainActive) {
    //             this.$router.push("/home");
    //         } else if (this.userFound && !this.domainActive) {
    //             const message = "Domain inactive!";
    //             notify(
    //                 {
    //                     message,
    //                     width: 450,
    //                 },
    //                 "error",
    //                 2000
    //             );
    //         }
    //     },
    // signIn() {
    //     // let userFound = false;
    //     // let domainActive = false;
    //     // const userName = this.userInput.email.split("@")[0];
    //     // const userDomain = this.userInput.email.split("@")[1].split(".")[0];
    //     // for (const key of this.user) {
    //     //     if (key.username === userName) {
    //     //         for (const dom of domain) {
    //     //             if (
    //     //                 dom.name === userDomain &&
    //     //                 dom.id === key.domainId &&
    //     //                 dom.status === "Active"
    //     //             ) {
    //     //                 localStorage.setItem("userId", key.id);
    //     //                 localStorage.setItem("userDomainId", key.domainId);
    //     //                 localStorage.setItem("businessYearId", 13);
    //     //                 eventBus.emit("newUserLoggedIn");
    //     //                 userFound = true;
    //     //                 domainActive = true;
    //     //             } else if (
    //     //                 dom.name === userDomain &&
    //     //                 dom.id === key.domainId &&
    //     //                 dom.status === "Inactive"
    //     //             ) {
    //     //                 userFound = true;
    //     //             }
    //     //         }
    //     //     }
    //     // }
    //     // if (userFound && domainActive) {
    //     //     this.$router.push("/home");
    //     // } else if (!userFound) {
    //     //     const message = "User not found!";
    //     //     notify(
    //     //         {
    //     //             message,
    //     //             width: 450,
    //     //         },
    //     //         "error",
    //     //         2000
    //     //     );
    //     // } else if (!domainActive) {
    //     //     const message = "Domain inactive!";
    //     //     notify(
    //     //         {
    //     //             message,
    //     //             width: 450,
    //     //         },
    //     //         "error",
    //     //         2000
    //     //     );
    //     // }
    // },
    // updateEmail(value) {
    //     this.email = value;
    // },
    // },
};
</script>
<style scoped>
.container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-image: url("../assets/background.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* This line keeps the background fixed */
}
.forgotPasswordContainer {
    max-width: 500px;
    margin: 3vh auto;
    /* padding: 3vh; */
    text-align: center;
}
.registerContainer {
    max-width: 500px;
    margin: 2vh auto;
    /* padding: 3vh; */
    text-align: center;
}
.copyrightContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    height: 15px;
    margin: 1vh auto;
    text-align: center;
}
.dxForm {
    width: 30vw;
    max-width: 30vw;
}

@media screen and (max-width: 768px) {
    .dxForm {
        width: 90vw;
        max-width: 90vw;
    }
}
@media screen and (max-width: 1024px) {
    .dxForm {
        width: 75vw; /* Adjust this value as needed for tablet view */
        max-width: 75vw;
    }
}
.heading {
    margin: 10vh auto 0 auto;
    text-align: center;
}
</style>
