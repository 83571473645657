import { reactive, toRefs } from "vue";

//Stores
import { offer } from "@/stores/offerStore.js";
import { offerItem } from "@/stores/offerItemStore.js";
import { invoiceItem } from "@/stores/invoiceItemStore.js";
import { invoice } from "@/stores/invoiceStore.js";
import { user } from "@/stores/userStore.js";
import { customer } from "@/stores/customerStore.js";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { warehouse } from "@/stores/warehouseStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";
import { warehouseItemStatusPriceAverage } from "@/stores/warehouseItemStatusPriceAverageStore.js";
import moment from "moment";
import eventBus from "../../eventBus.js";
import { generateInvoiceData } from "@/utils/generateInvoiceData.js";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { poppinsBold } from "@/poppinsboldbase64.js";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { getCurrentDateTime } from "@/utils/getCurrentDateTime";

export function useOffer() {
    const state = reactive({
        offer,
        offerItem,
        invoiceItem,
        invoice,
        user,
        customer,
        domain,
        domainUser,
        warehouse,
        businessYear,
        priceListItem,
        warehouseItemStatus,
        emailSignatureDefault,
        warehouseItemStatusPriceAverage,
        invoiceItemData: null,
        editingOffer: null,
        editingOfferItem: null,
        signature: null,
        offerItemData: [],
        rowsToBeDeleted: [],
        offerItemsBeforeEdit: [],
        editingOfferItemOldData: [],
        columnChooserMode: "select",
        userSignature: null,
        pdfForEmail: null,
        emailFormData: {
            sourceType: "proforma",
            sourceId: null,
            senderId: null,
            dateSent: null,
            clientId: null,
            emailFrom: null,
            emailTo: null,
            subject: null,
            message: null,
        },
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        adminUsername: localStorage.getItem("username"),
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
        businessYearId: localStorage.getItem("businessYearId"),
    });
    async function handleGenerateInvoice(e) {
        const offerData = e.row.data;
        const invoiceData = generateInvoiceData(
            state.adminId,
            state.domainId,
            state.businessYearId,
            null,
            offerData,
            null,
            null,
            null
        );

        const invoice = await invoice.insert(invoiceData);
        const invoiceItems = await offerItem.load({
            offerId: offerData.id,
        });
        // console.log(invoiceItems);
        for (let i = 0; i < invoiceItems.length; i++) {
            const item = invoiceItems[i];
            delete item.id;
            delete item.offerId;
            item.invoiceId = invoice.id;
            await invoiceItem.insert(item);
        }
    }
    async function handleInitNewRowOffer(e) {
        e.data = {
            businessYearId: Number(state.businessYearId),
        };
        const loadOptions = {
            createdById: state.adminId,
        };
        const res = await offer.load(loadOptions);
        let number = 1;
        if (res.length > 0) {
            number = res[0].number + 1;
        }
        state.offerItemData = [];
        return number;
    }
    function handleRowInsertingOffer(e, date, expireDate, number) {
        const now = new Date().toISOString();
        e.data = {
            ...e.data,
            date: date,
            expireDate: expireDate,
            number: number,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now,
            modifiedDate: now,
            domainId: Number(state.domainId),
            status: "new",
            taxValue: 25,
            taxName: "PDV 25%",
            usluge: null,
        };
    }
    async function handleRowInsertedOffer(e) {
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        const userData =
            Number(state.domainId) === 1
                ? await user.byKey(e.data.userId)
                : await customer.byKey(e.data.userId);
        const now = new Date();
        const allItemPromises = state.offerItemData.map((item) => {
            return priceListItem
                .byKey(item.priceListItemId)
                .then((res) => {
                    delete item.__KEY__;
                    Object.assign(item, {
                        offerId: e.key,
                        priceTotal: Number(item.priceTotal),
                        createdById: Number(state.adminId),
                        modifiedById: Number(state.adminId),
                        createdDate: now.toISOString(),
                        modifiedDate: now.toISOString(),
                        priceListItem: JSON.stringify(res),
                    });
                })
                .then(() => {
                    offerItem.insert(item);
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                });
        });
        await Promise.all(allItemPromises);
        const totalAmountAfterTax = totalAmountDiscounted * 1.25;
        const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
        await offer.update(e.key, {
            totalAmountTaxed: totalAmountAfterTax,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty,
            user: JSON.stringify(userData),
        });
        state.offerItemData = [];
    }
    function handleEditingStartOffer(e) {
        state.editingOffer = e.data;
        offerItem.load();
    }
    function handleEditCanceledOffer() {
        state.offerItemData = [];
        state.rowsToBeDeleted = [];
    }
    async function handleRowUpdatingOffer(e) {
        const now = new Date();
        let totalPriceByQty = 0;
        let totalDiscountAmount = 0;
        let totalAmountDiscounted = 0;
        const userData = e.newData.userId
            ? Number(state.domainId) === 1
                ? await user.byKey(e.newData.userId)
                : await customer.byKey(e.newData.userId)
            : null;
        const allPromises = [];
        const deletePromises = state.rowsToBeDeleted.map((item) => {
            return offerItem.remove(item.id).then(() => {
                state.rowsToBeDeleted = [];
            });
        });
        allPromises.push(...deletePromises);
        const insertPromises = state.offerItemData.map((item) => {
            return priceListItem.byKey(item.priceListItemId).then((res) => {
                if (item.id) {
                    offerItem.remove(item.id);
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    offerId: e.key,
                    priceTotal: Number(item.priceTotal),
                    createdById: Number(state.adminId),
                    modifiedById: Number(state.adminId),
                    createdDate: now.toISOString(),
                    modifiedDate: now.toISOString(),
                    priceListItem: JSON.stringify(res),
                });
                return offerItem.insert(item).then(() => {
                    totalDiscountAmount += item.discountAmount;
                    totalAmountDiscounted += Number(item.priceTotal);
                    totalPriceByQty += item.priceByQty;
                });
            });
        });
        allPromises.push(...insertPromises);
        await Promise.all(allPromises);
        const totalAmountAfterTax = totalAmountDiscounted * 1.25;
        const totalTaxAmount = totalAmountAfterTax - totalAmountDiscounted;
        const updateData = {
            totalAmountTaxed: totalAmountAfterTax,
            totalTaxAmount: totalTaxAmount,
            totalAmountDiscounted: totalAmountDiscounted,
            totalDisocuntAmount: totalDiscountAmount,
            totalAmount: totalPriceByQty,
        };
        if (userData !== null) {
            updateData.user = JSON.stringify(userData);
        }
        await offer.update(e.key, updateData);
        state.offerItemData = [];
    }
    async function handleExportingOfferRecap(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFileToVFS("@/assets/Poppins-Bold.ttf", poppinsBold);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.addFont("@/assets/Poppins-Bold.ttf", "Poppins-Bold", "bold");
        doc.setFont("Poppins-Regular", "normal");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        const combinedFilter = table.getCombinedFilter(true);
        let filterValueDate = null;
        let filterValueYear = null;
        if (combinedFilter && combinedFilter[0][0] === "date") {
            if (typeof combinedFilter.filterValue === "string") {
                filterValueDate = combinedFilter.filterValue;
            } else {
                filterValueYear = combinedFilter.filterValue;
            }
        }
        let formattedDateRange = null;
        if (filterValueDate) {
            const [year, month] = filterValueDate.split("/").map(Number);
            const startDate = new Date(year, month - 1, 1);
            const endDate = new Date(year, month, 0);
            const formatDate = (date) => {
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            };
            const startString = formatDate(startDate);
            const endString = formatDate(endDate);
            formattedDateRange = `Datum: od ${startString}. do ${endString}.`;
        } else if (filterValueYear) {
            formattedDateRange = `Datum: Godina ${filterValueYear}.`;
        } else {
            formattedDateRange = `Datum: Nije odabran`;
        }
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 20 },
            onRowExporting: (e) => {
                if (e.rowCells[0].backgroundColor === "#D3D3D3") {
                    e.rowHeight = 9;
                } else {
                    e.rowHeight = 13;
                }
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 10;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.borderColor = "#D3D3D3";
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.horizontalAlign = "center";
                    pdfCell.backgroundColor = "#D3D3D3";
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 9;
                }
            },
        }).then(() => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const generatedTime = getCurrentDateTime();
            const generated = `Vrijeme izrade: ${generatedTime}`;
            doc.setFontSize(10);
            doc.line(15, 15, pageWidth - 15, 15);
            doc.setFont("Poppins-Bold", "bold");
            doc.text("Rekapitulacija ponuda", 15, 20);
            doc.setFont("Poppins-Regular", "normal");
            doc.text(generated, pageWidth - 15, 20, { align: "right" });
            doc.text(formattedDateRange, 15, 27);
            doc.save("Rekapitulacija-ponuda.pdf");
        });
    }
    function handleCancelOfferItem() {
        state.editingOfferItemOldData.pop();
        state.editingOfferItem = null;
    }
    function handleDeleteOfferItem(e) {
        state.offerItemData.splice(e.row.rowIndex, 1);
        if (e.row.data.id) {
            state.rowsToBeDeleted.push(e.row.data);
        }
    }
    function handleSaveNewOfferItem(qty, price, priceTotal) {
        const now = new Date();
        if (state.editingOfferItem !== null) {
            state.editingOfferItem.qty = qty;
            state.editingOfferItem.price = price;
            state.editingOfferItem.priceTotal = Number(priceTotal);
            state.editingOfferItem.modifiedDate = now.toISOString();
            state.editingOfferItem.modifiedById = Number(state.adminId);
            state.offerItemData.find((el) => {
                if (
                    state.editingOfferItem.__KEY__ &&
                    el.__KEY__ === state.editingOfferItem.__KEY__
                ) {
                    el = state.editingOfferItem;
                } else if (el.id === state.editingOfferItem.id) {
                    el = state.editingOfferItem;
                }
            });
            state.editingOfferItem = null;
        } else {
            state.editingOfferItem = null;
        }
    }
    function handleRowInsertingOfferItem(
        e,
        qty,
        price,
        priceTotal,
        discountAmount,
        discountPercentage,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            discountAmount: discountAmount,
            discountPercentage: discountPercentage,
            priceListItemId: priceListItemId,
        };
    }
    function handleEditingStartOfferItem(e) {
        state.editingOfferItem = e.data;
        const oldData = {
            ...state.editingOfferItem,
        };
        state.editingOfferItemOldData.push(oldData);
    }
    async function handleSendEmailPopupData(selectedRowKey) {
        const offerData = await offer.byKey(selectedRowKey);
        let offerItemData = "";
        state.offerItemData.forEach((e, i) => {
            const item = JSON.parse(e.priceListItem);
            const itemName = item.name;
            if (state.offerItemData.length === 1) {
                offerItemData += itemName;
            } else if (i === state.offerItemData.length - 1) {
                offerItemData = offerItemData.slice(0, -2);
                offerItemData += ` i ${itemName}`;
            } else {
                offerItemData += `${itemName}, `;
            }
        });
        const offerUserData = JSON.parse(offerData.user);
        state.emailFormData.emailTo = offerUserData.email;

        state.emailFormData.senderId = state.adminId;
        state.emailFormData.clientId = offerUserData.id;
        state.emailFormData.sourceId = selectedRowKey;
        state.emailFormData.dateSent = moment().format("YYYY-MM-DD HH:mm:ss");
        const domainUserData = await domainUser.byKey(state.adminId);
        const businessYearData = await businessYear.byKey(
            offerData.businessYearId
        );
        // console.log(businessYear);
        const domainRes = await domain.byKey(domainUserData.domainId);
        if (domainRes) {
            const defaultEmailContent = JSON.parse(
                domainRes.defaultEmailContent
            );
            if (defaultEmailContent.ponuda) {
                if (defaultEmailContent.ponuda.subject !== null) {
                    if (defaultEmailContent.ponuda.subject.includes("{var}")) {
                        const subjectToReplace =
                            defaultEmailContent.ponuda.subject.replace(
                                "{var}",
                                `${offerData.createdById}-${offerData.number}/${businessYearData.year}`
                            );
                        state.emailFormData.subject = subjectToReplace;
                    } else {
                        state.emailFormData.subject =
                            defaultEmailContent.ponuda.subject !== null
                                ? defaultEmailContent.ponuda.subject
                                : null;
                    }
                }
                state.emailFormData.emailFrom =
                    defaultEmailContent.ponuda.email_from !== null
                        ? defaultEmailContent.ponuda.email_from
                        : null;

                state.emailFormData.message =
                    defaultEmailContent.ponuda.message !== null
                        ? defaultEmailContent.ponuda.message
                        : null;
            }
        }
        const emailSignatureDefaultData = await emailSignatureDefault.byKey(
            domainUserData.domainId
        );
        if (domainUserData.emailSignature !== null) {
            state.signature = domainUserData.emailSignature;
        } else {
            state.signature = `--<br>
            <strong>${state.adminUsername}</strong><br>
            ${emailSignatureDefaultData.signature}`;
        }
    }
    async function handleSendEmail(sendEmailFlag, selectedRowKey) {
        const dontChangeStatus = ["paid", "accepted", "rejected"];
        sendEmailFlag = true;
        let responseData;
        const pdfGenerationPromise = new Promise((resolve, reject) => {
            const pdfEventListener = (pdfForEmail) => {
                if (pdfForEmail && pdfForEmail.doc) {
                    resolve(pdfForEmail);
                } else {
                    reject(new Error("PDF generation failed"));
                }
            };
            eventBus.on("offerPdfForEmail", pdfEventListener);
            eventBus.emit("exportOfferItemToPDF", sendEmailFlag);
        });

        try {
            const pdfFile = await pdfGenerationPromise;
            state.emailFormData.message =
                state.emailFormData.message.replace(/\\n/g, "<br>") +
                "<br>" +
                state.signature
                    .replace(/<\/p><p>/g, "<br>")
                    .replace(/<p>/g, "")
                    .replace(/<\/p>/g, "");

            const res = await offer.byKey(selectedRowKey);

            const formData = new FormData();
            const pdfBlob = pdfFile.doc.output("blob");
            formData.append("file", pdfBlob, pdfFile.documentName);
            formData.append("json", JSON.stringify(state.emailFormData));

            const requestOptions = {
                method: "POST",
                body: formData,
            };

            const response = await fetch(
                "http://billing.gtnet.hr/cli/procedure/emailLogUpload.php",
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            responseData = await response.json();
            if (!dontChangeStatus.includes(res.status)) {
                offer.update(selectedRowKey, {
                    status: "pending",
                });
            }
            sendEmailFlag = false;
            return { sendEmailFlag, responseData };
        } catch (error) {
            console.error(
                "Error during PDF generation or email sending: ",
                error
            );
        } finally {
            sendEmailFlag = false;
            state.emailFormData = {
                sourceType: "proforma",
                sourceId: null,
                senderId: null,
                dateSent: null,
                clientId: null,
                emailFrom: null,
                emailTo: null,
                subject: null,
                message: null,
            };
        }
    }
    return {
        ...toRefs(state),
        handleGenerateInvoice,
        handleInitNewRowOffer,
        handleRowInsertingOffer,
        handleRowInsertedOffer,
        handleEditingStartOffer,
        handleEditCanceledOffer,
        handleRowUpdatingOffer,
        handleCancelOfferItem,
        handleDeleteOfferItem,
        handleSaveNewOfferItem,
        handleRowInsertingOfferItem,
        handleEditingStartOfferItem,
        handleSendEmailPopupData,
        handleSendEmail,
        handleExportingOfferRecap,
    };
}
