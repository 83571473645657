import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore";
import { supplier } from "@/stores/supplierStore";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
export function useSupplier() {
    const invoiceTypes = [{ value: "R1" }, { value: "R2" }];
    const state = reactive({
        domainUser,
        supplier,
        saveLastData: false,
        lastSavedData: {},
        columnChooserMode: "select",
        domainName: null,
        invoiceTypeSelectBoxOptions: {
            items: invoiceTypes,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip računa",
        },
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
    });

    function handleNewRowInitSupplier(e) {
        if (state.saveLastData === true) {
            e.data = {
                ...state.lastSavedData,
            };
            state.saveLastData = false;
        }
    }
    function handleRowInsertingSupplier(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: Number(state.domainId),
        };
        if (state.saveLastData) {
            state.lastSavedData = {
                ...e.data,
            };
        }
        return e.data;
    }
    function handleRowUpdatingSupplier(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: Number(state.adminId),
            modifiedDate: now.toISOString(),
        };
        return e.newData;
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.domainId);
        if (domainData !== null) state.domainName = domainData.name;
    }
    function handleExportSupplier(table) {
        const doc = new jsPDF();
        doc.setFontSize(8);
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 10 },
            repeatHeaders: true,
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            columnWidths: [100, 40, 40],
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setFontSize(14);
            doc.setPage(1);
            doc.text(state.domainName, 15, 12);
            doc.text("Dobavljači", 15, 20);
            doc.setFontSize(8);
            doc.text(vrijeme, pageWidth - 15, 20, { align: "right" });
            doc.line(15, 22, pageWidth - 15, 22);
            const documentName = `Lista-Dobavljača-${state.domainName}.pdf`;
            doc.save(documentName);
        });
    }
    return {
        ...toRefs(state),
        handleNewRowInitSupplier,
        handleRowInsertingSupplier,
        handleRowUpdatingSupplier,
        getDomainData,
        handleExportSupplier,
    };
}
