import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

// USER DOMAIN ID FILTER
let userDomainId = Number(localStorage.getItem("userDomainId"));
eventBus.on("newUserLoggedIn", () => {
    userDomainId = Number(localStorage.getItem("userDomainId"));
});

export const warehouse = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            if (userDomainId) {
                const filterCondition = {
                    where: {
                        and: [{ domainId: userDomainId }],
                    },
                };
                if (
                    loadOptions.searchValue !== null &&
                    loadOptions.searchValue !== ""
                ) {
                    filterCondition.where.and.push({
                        name: {
                            regexp: `/${loadOptions.searchValue}/i`,
                        },
                    });
                } else {
                    filterCondition.where.and.splice(1, 1);
                }
                const newData = sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.WAREHOUSE
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}`,
                "POST",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.WAREHOUSE}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    },
});
