<template>
    <div class="table-container">
        <span class="table-name">Međuskladišnica</span>
        <DxDataGrid
            id="intermediateWarehouseTable"
            :data-source="intermediateWarehouse"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getIntermediateWarehouseTableInstance"
            @init-new-row="onInitNewRowIntermediateWarehouse"
            @row-inserting="onRowInsertingIntermediateWarehouse"
            @row-inserted="onRowInsertedIntermediateWarehouse"
            @editing-start="onEditingStartIntermediateWarehouse"
            @row-updating="onRowUpdatingIntermediateWarehouse"
            @row-updated="onRowUpdatedIntermediateWarehouse"
            @row-removing="onRowRemovingIntermediateWarehouse"
            @focused-row-changed="onFocusedRowChangedIntermediateWarehouse"
            @edit-canceled="onEditCanceledIntermediateWarehouse"
            edit-template="intermediateWarehouseEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="intermediateWarehouseTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="intermediateWarehouseAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="intermediateWarehouseRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o međuskladišnici"
                    :toolbar-items="intermediateWarehousePopupToolbarItems"
                    :drag-outside-boundary="true"
                    @shown="onPopupShown"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>
                        <DxItem
                            data-field="fromWarehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="fromWarehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="toWarehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="toWarehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="remark" data-type="string">
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                    </DxItem>
                    <DxItem item-type="group" :col-span="2">
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="intermediateWarehouseItemTable"
                                    :data-source="intermediateWarehouseItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="
                                        getIntermediateWarehouseItemTableInstance
                                    "
                                    @editing-start="
                                        onEditingStartIntermediateWarehouseItem
                                    "
                                    @row-inserting="
                                        onRowInsertingIntermediateWarehouseItem
                                    "
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="350"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                intermediateWarehouseItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                    />
                                                </DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="Stanje na skladištu"
                                                    data-type="number"
                                                    :editor-options="
                                                        warehouseStatusOptions
                                                    "
                                                />
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Ukupna cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="
                                                editIntermediateWarehouseItem
                                            "
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="
                                                deleteIntermediateWarehouseItem
                                            "
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="fromWarehouseId"
                data-type="number"
                caption="Iz skladišta"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="toWarehouseId"
                data-type="number"
                caption="U skladište"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
            />
            <DxColumn data-field="remark" data-type="string" caption="Opis" />
            <DxColumn
                data-field="priceTotal"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editIntermediateWarehouse"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteIntermediateWarehouse"
                />
                <DxButton
                    hint="Izradi PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportIntermediateWarehouse"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";

//Utils
import eventBus from "../../eventBus.js";
import { useIntermediateWarehouse } from "@/composables/useIntermediateWarehouse.js";
import { ref } from "vue";

export default {
    name: "intermediateWarehouseTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
    },
    setup() {
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);

        //items instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const numberInstance = ref(null);
        const dateSelectInstance = ref(null);
        const priceTotalInstance = ref(null);
        const toWarehouseInstance = ref(null);
        const priceListItemInstance = ref(null);
        const fromWarehouseInstance = ref(null);
        const warehouseStatusInstance = ref(null);

        //general data
        const qty = ref(1);
        const price = ref(0);
        const number = ref(null);
        const priceTotal = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const priceListItemId = ref(null);

        const {
            domain,
            warehouse,
            domainUser,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            intermediateWarehouse,
            intermediateWarehouseItem,
            rowsToBeDeleted,
            priceTotalFormat,
            intermediateWarehouseItemData,
            businessYearId,
            columnChooserMode,
            handleRowInsertingIntermediateWarehouse,
            handleRowInsertedIntermediateWarehouse,
            handleEditingStartIntermediateWarehouse,
            handleEditCanceledIntermediateWarehouse,
            handleRowUpdatingIntermediateWarehouse,
            handleRowRemovingIntermediateWarehouse,
            handleCancelItermediateWarehouseItem,
            handleSaveNewIntermediateWarehouseItem,
            handleRowInsertingIntermediateWarehouseItem,
            handleEditingStartIntermediateWarehouseItem,
        } = useIntermediateWarehouse();

        //button item options
        const intermediateWarehouseAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addIntermediateWarehouse();
            },
        });
        const intermediateWarehouseItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addIntermediateWarehouseItem();
            },
        });
        const intermediateWarehouseRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });

        //toolbar items
        const intermediateWarehousePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewIntermediateWarehouse(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelIntermediateWarehouse(),
                },
            },
        ]);
        const intermediateWarehouseItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewIntermediateWarehouseItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelIntermediateWarehouseItem(),
                },
            },
        ]);

        //form item options
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: () => {
                numberInstance.value.option("disabled", false);
            },
        });
        const fromWarehouseSelectBoxOptions = ref({
            dataSource: warehouse.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                fromWarehouseInstance.value = e.component;
            },
            onSelectionChanged: async (e) => {
                if (
                    toWarehouseInstance.value.option("selectedItem") &&
                    fromWarehouseInstance.value.option("selectedItem")
                ) {
                    if (
                        fromWarehouseInstance.value.option("selectedItem")
                            .id ===
                        toWarehouseInstance.value.option("selectedItem").id
                    ) {
                        toWarehouseInstance.value.reset();
                    }
                }
                if (
                    !fromWarehouseInstance.value.option("disabled") &&
                    e.selectedItem !== null
                ) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: Number(businessYearId.value),
                    };
                    const res = await intermediateWarehouse.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        numberInstance.value.option("value", res[0].number + 1);
                        number.value = res[0].number + 1;
                    } else {
                        numberInstance.value.option("value", 1);
                        number.value = 1;
                    }
                }
            },
        });
        const toWarehouseSelectBoxOptions = ref({
            dataSource: warehouse.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                toWarehouseInstance.value = e.component;
            },
            onSelectionChanged: () => {
                if (
                    fromWarehouseInstance.value.option("selectedItem") &&
                    toWarehouseInstance.value.option("selectedItem")
                ) {
                    if (
                        toWarehouseInstance.value.option("selectedItem").id ===
                        fromWarehouseInstance.value.option("selectedItem").id
                    ) {
                        fromWarehouseInstance.value.reset();
                    }
                }
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: priceListItem.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                // console.log(e);
                const selectedWarehouseId =
                    fromWarehouseInstance.value.option("value");

                // console.log(warehouseInstance.option("value"));
                // let res = [];
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const loadOptions = {
                    priceListItemId: e.value,
                };
                const res = await warehouseItemStatus.value.load(loadOptions);
                if (res.length > 0) {
                    if (res[0].priceAverage !== null)
                        priceInstance.value.option(
                            "value",
                            res[0].priceAverage
                        );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
            },
        });
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear.value,
            valueExpr: "id",
            readOnly: true,
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceOptions = ref({
            disabled: true,
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: "###,###,###,##0.00",
            disabled: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });

        //instances
        const getIntermediateWarehouseTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getIntermediateWarehouseItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };

        //intermediate warehouse methods
        const onFocusedRowChangedIntermediateWarehouse = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit(
                        "newFocusedRowIntermediateWarehouse",
                        selectedRowKey.value
                    );
                }
            } else {
                eventBus.emit("newFocusedRowIntermediateWarehouse", null);
            }
        };
        const addIntermediateWarehouse = () => {
            tableInstance.value.addRow();
        };
        const editIntermediateWarehouse = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelIntermediateWarehouse = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteIntermediateWarehouse = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowIntermediateWarehouse = (e) => {
            const now = new Date().toISOString();
            e.data = {
                businessYearId: Number(businessYearId.value),
            };
            priceListItemId.value = null;
            tableInstance.value.option("focusedRowIndex", -1);
            intermediateWarehouseItemData.value = [];
            setTimeout(() => {
                dateSelectInstance.value.option("value", now);
            }, 100);
        };
        const saveNewIntermediateWarehouse = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingIntermediateWarehouse = (e) => {
            handleRowInsertingIntermediateWarehouse(
                e,
                number.value,
                selectedDate.value
            );
        };
        const onRowInsertedIntermediateWarehouse = async (e) => {
            await handleRowInsertedIntermediateWarehouse(e);
            qty.value = 1;
            tableInstance.value.refresh();
        };
        const onEditingStartIntermediateWarehouse = async (e) => {
            await handleEditingStartIntermediateWarehouse(e);
            const now = new Date().toISOString();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now
                );
                fromWarehouseInstance.value.option("disabled", true);
                toWarehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledIntermediateWarehouse = () => {
            handleEditCanceledIntermediateWarehouse();
        };
        const onRowUpdatingIntermediateWarehouse = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingIntermediateWarehouse(e, priceTotal.value);
            tableInstance.value.refresh();
            qty.value = 1;
        };
        const onRowUpdatedIntermediateWarehouse = () => {
            eventBus.emit("newIntermediateWarehouseItemAdded");
        };
        const onRowRemovingIntermediateWarehouse = async (e) => {
            await handleRowRemovingIntermediateWarehouse(e);
        };
        const exportIntermediateWarehouse = () => {
            eventBus.emit("exportIntermediateWarehouse");
        };

        //intermediate warehouse item table methods
        const addIntermediateWarehouseItem = () => {
            tableInstance.value.addRow();
        };
        const editIntermediateWarehouseItem = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelIntermediateWarehouseItem = () => {
            handleCancelItermediateWarehouseItem();
            tableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteIntermediateWarehouseItem = (e) => {
            intermediateWarehouseItemData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            tableInstance.value.refresh();
        };
        const saveNewIntermediateWarehouseItem = () => {
            handleSaveNewIntermediateWarehouseItem(
                qty.value,
                price.value,
                priceTotal.value
            );
            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingIntermediateWarehouseItem = (e) => {
            handleRowInsertingIntermediateWarehouseItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
            qty.value = 1;
        };
        const onEditingStartIntermediateWarehouseItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartIntermediateWarehouseItem(e);
            price.value = e.data.price;
            priceListItemId.value = null;
            priceListItemInstance.value.option("disabled", true);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };

        //general methods
        const updateTotalValue = () => {
            priceTotal.value = (qty.value * price.value).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };
        const onPopupShown = () => {
            fromWarehouseInstance.value.focus();
        };

        //subscribe to events
        eventBus.on("intermediateWarehouseItems", (data) => {
            intermediateWarehouseItemData.value = data;
        });
        return {
            domain,
            warehouse,
            domainUser,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            intermediateWarehouse,
            intermediateWarehouseItem,
            priceTotalFormat,
            intermediateWarehouseAddButtonOptions,
            intermediateWarehouseItemAddButtonOptions,
            intermediateWarehouseRefreshButtonOptions,
            columnChooserButtonOptions,
            intermediateWarehousePopupToolbarItems,
            intermediateWarehouseItemPopupToolbarItems,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            intermediateWarehouseItemData,
            fromWarehouseSelectBoxOptions,
            toWarehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceTotalOptions,
            getIntermediateWarehouseTableInstance,
            getIntermediateWarehouseItemTableInstance,
            onFocusedRowChangedIntermediateWarehouse,
            addIntermediateWarehouse,
            editIntermediateWarehouse,
            cancelIntermediateWarehouse,
            deleteIntermediateWarehouse,
            onInitNewRowIntermediateWarehouse,
            saveNewIntermediateWarehouse,
            onRowInsertingIntermediateWarehouse,
            onRowInsertedIntermediateWarehouse,
            onEditingStartIntermediateWarehouse,
            onEditCanceledIntermediateWarehouse,
            onRowUpdatingIntermediateWarehouse,
            onRowUpdatedIntermediateWarehouse,
            onRowRemovingIntermediateWarehouse,
            addIntermediateWarehouseItem,
            editIntermediateWarehouseItem,
            cancelIntermediateWarehouseItem,
            deleteIntermediateWarehouseItem,
            saveNewIntermediateWarehouseItem,
            onRowInsertingIntermediateWarehouseItem,
            onEditingStartIntermediateWarehouseItem,
            onPopupShown,
            columnChooserMode,
            exportIntermediateWarehouse,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#intermediateWarehouseTable {
    max-width: 100%;
}
#intermediateWarehouseItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
