<template>
    <div class="settings-container">
        <span class="table-name">Postavke</span>
        <span style="color: rgb(70, 120, 178)"
            >Ne zaboravite spremiti promjene prije nastavka korištenja
            aplikacije.</span
        >
        <DxSelectBox
            class="bussiness-year-select-box"
            :data-source="businessYearData"
            display-expr="year"
            value-expr="id"
            styling-mode="underlined"
            label="Poslovna godina"
            @initialized="getBusinessYearSelectBoxInstance"
            @value-changed="onValueChanged"
            :value="startValue"
        />
        <div class="button-container">
            <DxButton
                class="save-button"
                text="Spremi novu poslovnu godinu"
                @click="saveNewBusinessYear"
                type="success"
            />
        </div>
        <div class="html-editor-title">
            <span>Zadani podaci za email (Ponuda)</span>
        </div>
        <DxTextBox
            class="email-data"
            width="500px"
            label="Pošiljatelj"
            placeholder="primjer@primjer.com"
            styling-mode="underlined"
            :value="offerEmailData.email_from"
            @initialized="getOfferEmailFromInstance"
        >
            <DxValidator
                @initialized="getOfferEmailValidatorInstance"
                validationGroup="offerEmail"
            >
                <DxEmailRule />
            </DxValidator>
        </DxTextBox>

        <DxTextBox
            class="email-data"
            width="500px"
            label="Predmet"
            placeholder="Ponuda br. {var}*"
            styling-mode="underlined"
            :value="offerEmailData.subject"
            @initialized="getOfferSubjectInstance"
        />
        <DxTextArea
            class="email-data"
            height="250px"
            width="500px"
            label="Poruka"
            styling-mode="underlined"
            :value="offerEmailData.message"
            @initialized="getOfferMessageInstance"
        />
        <div class="html-editor-title">
            <DxButton
                class="header-button"
                text="Spremi podatke"
                @click="saveDefaultEmailDataOffer"
                type="success"
            />
            <DxButton
                class="header-button"
                text="Obriši podatke"
                @click="clearDefaultEmailDataOffer"
                type="danger"
            />
        </div>
        <div class="html-editor-title">
            <span>Zadani podaci za email (Račun)</span>
        </div>
        <DxTextBox
            class="email-data"
            width="500px"
            label="Pošiljatelj"
            placeholder="primjer@primjer.com"
            styling-mode="underlined"
            :value="invoiceEmailData.email_from"
            @initialized="getInvoiceEmailFromInstance"
        >
            <DxValidator
                @initialized="getInvoiceEmailValidatorInstance"
                validationGroup="invoiceEmail"
            >
                <DxEmailRule />
            </DxValidator>
        </DxTextBox>
        <DxTextBox
            class="email-data"
            width="500px"
            label="Predmet"
            placeholder="Račun br. {var}*"
            styling-mode="underlined"
            :value="invoiceEmailData.subject"
            @initialized="getInvoiceSubjectInstance"
        />
        <DxTextArea
            class="email-data"
            height="250px"
            width="500px"
            label="Poruka"
            styling-mode="underlined"
            :value="invoiceEmailData.message"
            @initialized="getInvoiceMessageInstance"
        />
        <div class="html-editor-title">
            <DxButton
                class="header-button"
                text="Spremi podatke"
                @click="saveDefaultEmailDataInvoice"
                type="success"
            />
            <DxButton
                class="header-button"
                text="Obriši podatke"
                @click="clearDefaultEmailDataInvoice"
                type="danger"
            />
        </div>
        <div class="html-editor-title">
            <span>Email potpis</span>
        </div>
        <DxHtmlEditor
            value-type="html"
            :value="signature"
            height="625px"
            class="dx-html-editor"
            @initialized="getHtmlEditorSignatureInstance"
            @value-changed="onValueChangedHTMLEditorSignature"
            :toolbar="htmlEditorToolbarItems"
        >
            <DxMediaResizing :enabled="true" />
            <DxImageUpload file-upload-mode="base64" />
        </DxHtmlEditor>
        <div class="html-editor-title">
            <DxButton
                class="header-button"
                text="Spremi potpis"
                @click="saveEmailSignature"
                type="success"
            />
            <DxButton
                class="header-button"
                text="Obriši potpis"
                @click="clearEmailSignature"
                type="danger"
            />
        </div>
        <span>PDF zaglavlje</span>
        <DxHtmlEditor
            value-type="html"
            :value="header"
            height="625px"
            class="dx-html-editor"
            @initialized="getHtmlEditorHeaderInstance"
            @value-changed="onValueChangedHTMLEditorHeader"
            :toolbar="htmlEditorToolbarItems"
        >
            <DxMediaResizing :enabled="true" />
            <DxImageUpload file-upload-mode="base64" />
        </DxHtmlEditor>
        <div class="html-editor-title">
            <DxButton
                class="header-button"
                text="Spremi zaglavlje"
                @click="saveHeader"
                type="success"
            />
            <DxButton
                class="header-button"
                text="Testiraj zaglavlje"
                @click="testExportHeader"
                type="default"
            />
        </div>
        <span>PDF podnožje</span>
        <DxHtmlEditor
            value-type="html"
            :value="footer"
            height="625px"
            class="dx-html-editor"
            @initialized="getHtmlEditorFooterInstance"
            @value-changed="onValueChangedHTMLEditorFooter"
            :toolbar="htmlEditorToolbarItems"
        >
            <DxMediaResizing :enabled="true" />
            <DxImageUpload file-upload-mode="base64" />
        </DxHtmlEditor>
        <div class="html-editor-title">
            <DxButton
                class="header-button"
                text="Spremi podnožje"
                @click="saveFooter"
                type="success"
            />
            <DxButton
                class="header-button"
                text="Testiraj podnožje"
                @click="testExportFooter"
                type="default"
            />
        </div>

        <div>
            <p style="font-size: 10px">
                *{var} označava postavljanje varijable ako želite dinamičko
                postavljanje iz podataka. (Npr. Ponuda br. 30-31/2024 - 30 = ID
                korisnika, 31 = broj ponude, 2024 = godina).
            </p>
        </div>
    </div>
</template>
<script>
//DevExpress
import {
    DxHtmlEditor,
    DxMediaResizing,
    DxImageUpload,
} from "devextreme-vue/html-editor";
import "devextreme-vue/text-area";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import { DxValidator, DxEmailRule } from "devextreme-vue/validator";
// import themes from "devextreme/ui/themes";

//Stores
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { businessYear } from "@/stores/businessYearStore";
import { emailSignatureDefault } from "@/stores/emailSignatureDefaultStore.js";

//Utils
import { jsPDF } from "jspdf";
import { divWrapper } from "@/utils/divWrapper";

export default {
    name: "settingsComponent",
    components: {
        DxSelectBox,
        DxTextBox,
        DxTextArea,
        DxButton,
        DxMediaResizing,
        DxImageUpload,
        DxHtmlEditor,
        DxEmailRule,
        DxValidator,
    },
    data() {
        return {
            //Stores
            domain: domain,
            domainUser: domainUser,
            businessYear: businessYear,
            emailSignatureDefault: emailSignatureDefault,

            //Instances
            selectBoxInstance: null,
            offerTextBoxInstance: null,
            offerTextAreaInstance: null,
            invoiceTextBoxInstance: null,
            invoiceTextAreaInstance: null,
            htmlEditorHeaderInstance: null,
            htmlEditorFooterInstance: null,
            htmlEditorSignatureInstance: null,
            offerEmailValidatorInstance: null,
            invoiceEmailValidatorInstance: null,
            businessYearSelectBoxInstance: null,
            offerEmailFromInstance: null,
            offerSubjectInstance: null,
            offerMessageInstance: null,
            invoiceEmailFromInstance: null,
            invoiceSubjectInstance: null,
            invoiceMessageInstance: null,

            //General data
            adminId: null,
            adminDomainId: null,
            offerEmailData: {
                subject: null,
                message: null,
                email_from: null,
            },
            invoiceEmailData: {
                subject: null,
                message: null,
                email_from: null,
            },
            businessYearData: null,
            startValue: null,
            newValue: null,
            isDirty: false,

            //HTML Editor data
            signature: null,
            header: null,
            footer: null,

            //HTML editor toolbar items
            htmlEditorToolbarItems: {
                items: [
                    "undo",
                    "redo",
                    "separator",
                    {
                        name: "size",
                        acceptedValues: [
                            "8pt",
                            "10pt",
                            "12pt",
                            "14pt",
                            "18pt",
                            "24pt",
                            "36pt",
                        ],
                    },
                    {
                        name: "font",
                        acceptedValues: [
                            "Arial",
                            "Georgia",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                        ],
                    },
                    "separator",
                    "bold",
                    "italic",
                    "strike",
                    "underline",
                    "separator",
                    "alignLeft",
                    "alignCenter",
                    "alignRight",
                    "alignJustify",
                    "separator",
                    "orderedList",
                    "bulletList",
                    "separator",
                    {
                        name: "header",
                        acceptedValues: [false, 1, 2, 3, 4, 5],
                    },
                    "separator",
                    "color",
                    "background",
                    "separator",
                    "link",
                    "image",
                    "separator",
                    "clear",
                    "codeBlock",
                    "blockquote",
                    "separator",
                ],
                multiline: true,
            },
        };
    },
    async created() {
        // console.log(themes.current());
        // themes.current("generic.light");
        this.adminId = localStorage.getItem("userId");
        this.adminDomainId = localStorage.getItem("userDomainId");
        this.businessYearData = await this.businessYear.load();
        this.startValue = Number(localStorage.getItem("businessYearId"));
        const domainUser = await this.domainUser.byKey(this.adminId);
        const emailSignatureDefault = await this.emailSignatureDefault.load({
            domainId: domainUser.domainId,
        });
        if (domainUser.emailSignature !== null) {
            this.signature = domainUser.emailSignature;
        } else if (emailSignatureDefault[0].signature !== null) {
            this.signature = `--<br>
                <strong>${domainUser.username}</strong><br>
                ${emailSignatureDefault.signature}`;
        } else {
            this.signature = "Ovdje postavite svoj potpis za email.";
        }
        const domain = await this.domain.byKey(this.adminDomainId);
        if (domain.documentSections !== null) {
            const sections = JSON.parse(domain.documentSections);
            this.header = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            this.footer = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            this.header = "Ovdje postavite svoje zaglavlje za dokumente.";
            this.footer = "Ovdje postavite svoje podnožje za dokumente.";
        }
        if (domain.defaultEmailContent !== null) {
            const defaultEmailContent = JSON.parse(domain.defaultEmailContent);
            if (defaultEmailContent.ponuda) {
                this.offerEmailData = defaultEmailContent.ponuda;
            }
            if (defaultEmailContent.racun) {
                this.invoiceEmailData = defaultEmailContent.racun;
            }
        }
    },
    methods: {
        //Instances
        getBusinessYearSelectBoxInstance(e) {
            this.businessYearSelectBoxInstance = e.component;
            this.businessYearSelectBoxInstance.option("value", this.startValue);
        },
        getHtmlEditorSignatureInstance(e) {
            this.htmlEditorSignatureInstance = e.component;
            this.htmlEditorSignatureInstance.option("value", this.signature);
        },
        getHtmlEditorHeaderInstance(e) {
            this.htmlEditorHeaderInstance = e.component;
            this.htmlEditorHeaderInstance.option("value", this.header);
        },
        getHtmlEditorFooterInstance(e) {
            this.htmlEditorFooterInstance = e.component;
            this.htmlEditorFooterInstance.option("value", this.footer);
        },
        getInvoiceEmailValidatorInstance(e) {
            this.invoiceEmailValidatorInstance = e.component;
        },
        getOfferEmailValidatorInstance(e) {
            this.offerEmailValidatorInstance = e.component;
        },
        getOfferEmailFromInstance(e) {
            this.offerEmailFromInstance = e.component;
            this.offerEmailFromInstance.option(
                "value",
                this.offerEmailData.email_from
            );
        },
        getOfferSubjectInstance(e) {
            this.offerSubjectInstance = e.component;
            this.offerSubjectInstance.option(
                "value",
                this.offerEmailData.subject
            );
        },
        getOfferMessageInstance(e) {
            this.offerMessageInstance = e.component;
            this.offerMessageInstance.option(
                "value",
                this.offerEmailData.message
            );
        },
        getInvoiceEmailFromInstance(e) {
            this.invoiceEmailFromInstance = e.component;
            this.invoiceEmailFromInstance.option(
                "value",
                this.invoiceEmailData.email_from
            );
        },
        getInvoiceSubjectInstance(e) {
            this.invoiceSubjectInstance = e.component;
            this.invoiceSubjectInstance.option(
                "value",
                this.invoiceEmailData.subject
            );
        },
        getInvoiceMessageInstance(e) {
            this.invoiceMessageInstance = e.component;
            this.invoiceMessageInstance.option(
                "value",
                this.invoiceEmailData.message
            );
        },

        //Business year select box methods
        onValueChanged(e) {
            this.newValue = e.value;
            if (this.newValue !== this.startValue) {
                this.isDirty = true;
            } else {
                this.isDirty = false;
            }
        },

        //HTML editor methods
        onValueChangedHTMLEditorSignature(e) {
            if (this.signature !== e.value) {
                this.isDirty = true;
            } else {
                this.isDirty = false;
            }
        },
        onValueChangedHTMLEditorHeader(e) {
            if (this.header !== e.value) {
                this.isDirty = true;
            } else {
                this.isDirty = false;
            }
        },
        onValueChangedHTMLEditorFooter(e) {
            if (this.footer !== e.value) {
                this.isDirty = true;
            } else {
                this.isDirty = false;
            }
        },

        //Button methods
        saveDefaultEmailDataOffer() {
            const isValid =
                this.offerEmailValidatorInstance.validate("offerEmail");
            if (isValid.isValid) {
                this.offerEmailData = {
                    email_from: this.offerEmailFromInstance.option("value"),
                    subject: this.offerSubjectInstance.option("value"),
                    message: this.offerMessageInstance.option("value"),
                };

                this.invoiceEmailData = {
                    email_from: this.invoiceEmailFromInstance.option("value"),
                    subject: this.invoiceSubjectInstance.option("value"),
                    message: this.invoiceMessageInstance.option("value"),
                };
                // console.log(this.offerEmailData);
                const defaultEmailContent = {
                    ponuda: this.offerEmailData,
                    racun: this.invoiceEmailData,
                };
                const defaultEmailContentString =
                    JSON.stringify(defaultEmailContent);
                this.domain.update(this.adminDomainId, {
                    defaultEmailContent: defaultEmailContentString,
                });
                const message = "Podaci spremljeni.";
                notify({ message, width: 450 }, "success");
            }
        },
        clearDefaultEmailDataOffer() {
            this.offerEmailData = {
                email_from: null,
                subject: null,
                message: null,
            };
            this.offerEmailFromInstance.option("value", null);
            this.offerSubjectInstance.option("value", null);
            this.offerMessageInstance.option("value", null);
            const message = "Podaci obrisani.";
            notify({ message, width: 450 }, "success");
        },
        saveDefaultEmailDataInvoice() {
            const isValid =
                this.invoiceEmailValidatorInstance.validate("offerEmail");
            if (isValid.isValid) {
                this.offerEmailData = {
                    email_from: this.offerEmailFromInstance.option("value"),
                    subject: this.offerSubjectInstance.option("value"),
                    message: this.offerMessageInstance.option("value"),
                };

                this.invoiceEmailData = {
                    email_from: this.invoiceEmailFromInstance.option("value"),
                    subject: this.invoiceSubjectInstance.option("value"),
                    message: this.invoiceMessageInstance.option("value"),
                };
                const defaultEmailContent = {
                    ponuda: this.offerEmailData,
                    racun: this.invoiceEmailData,
                };
                const defaultEmailContentString =
                    JSON.stringify(defaultEmailContent);
                this.domain.update(this.adminDomainId, {
                    defaultEmailContent: defaultEmailContentString,
                });
                const message = "Podaci spremljeni.";
                notify({ message, width: 450 }, "success");
                // console.log(this.offerEmailData);
            }
        },
        clearDefaultEmailDataInvoice() {
            this.invoiceEmailData = {
                email_from: null,
                subject: null,
                message: null,
            };
            this.invoiceEmailFromInstance.option("value", null);
            this.invoiceSubjectInstance.option("value", null);
            this.invoiceMessageInstance.option("value", null);
            const message = "Podaci obrisani.";
            notify({ message, width: 450 }, "success");
        },
        async saveFooter() {
            this.header = this.htmlEditorHeaderInstance.option("value");
            this.header = this.header.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text
                            .replace(/ /g, "&nbsp;")
                            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") +
                        closeTag
                    );
                }
            );
            this.footer = this.htmlEditorFooterInstance.option("value");
            this.footer = this.footer.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text
                            .replace(/ /g, "&nbsp;")
                            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") +
                        closeTag
                    );
                }
            );
            const documentSections = {
                header: this.header,
                footer: this.footer,
            };
            const documentSectionsString = JSON.stringify(documentSections);
            const updateData = {
                documentSections: documentSectionsString,
            };
            await this.domain.update(this.adminDomainId, updateData);
            const message = "Podaci spremljeni.";
            notify({ message, width: 450 }, "success");
        },
        testExportFooter() {
            const doc = new jsPDF();
            let htmlContent = this.htmlEditorFooterInstance.option("value");
            htmlContent = htmlContent.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text
                            .replace(/ /g, "&nbsp;")
                            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") +
                        closeTag
                    );
                }
            );
            htmlContent = divWrapper(htmlContent);

            const pageHeight = doc.internal.pageSize.getHeight();
            doc.html(htmlContent, {
                callback: () => {
                    doc.save("test-footer.pdf");
                },
                x: 15,
                y: pageHeight - 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 794,
            });
        },
        async saveHeader() {
            this.header = this.htmlEditorHeaderInstance.option("value");
            this.header = this.header.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text
                            .replace(/ /g, "&nbsp;")
                            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") +
                        closeTag
                    );
                }
            );
            this.footer = this.htmlEditorFooterInstance.option("value");
            this.footer = this.footer.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text
                            .replace(/ /g, "&nbsp;")
                            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") +
                        closeTag
                    );
                }
            );
            const documentSections = {
                header: this.header,
                footer: this.footer,
            };
            const documentSectionsString = JSON.stringify(documentSections);
            const updateData = {
                documentSections: documentSectionsString,
            };
            await this.domain.update(this.adminDomainId, updateData);
            const message = "Podaci spremljeni.";
            notify({ message, width: 450 }, "success");
        },
        testExportHeader() {
            const doc = new jsPDF();
            let htmlContent = this.htmlEditorHeaderInstance.option("value");
            htmlContent = htmlContent.replace(
                /(>)([^<]+)(<)/g,
                function (match, openTag, text, closeTag) {
                    return (
                        openTag +
                        text
                            .replace(/ /g, "&nbsp;")
                            .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") +
                        closeTag
                    );
                }
            );
            htmlContent = divWrapper(htmlContent);
            doc.html(htmlContent, {
                callback: () => {
                    // doc.line(15, 130, 580, 130);
                    doc.setFontSize(10);
                    doc.line(15, 60, 195, 60);
                    doc.text("Pocetak dokumenta", 15, 65);
                    doc.save("test-header.pdf");
                },
                x: 15,
                y: 15,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 794,
            });
        },
        saveEmailSignature() {
            this.signature = this.htmlEditorSignatureInstance.option("value");
            this.domainUser.update(this.adminId, {
                emailSignature: this.signature,
            });
            const message = "Podaci spremljeni.";
            notify({ message, width: 450 }, "success");
        },
        clearEmailSignature() {
            this.htmlEditorSignatureInstance.option("value", null);
            this.signature = null;
            const message = "Podaci obrisani.";
            notify({ message, width: 450 }, "success");
        },
        saveNewBusinessYear() {
            if (this.newValue) {
                localStorage.setItem("businessYearId", this.newValue);
                const message = "Postavke spremljene.";
                notify({ message, width: 450 }, "success");
            }
            this.isDirty = false;
        },
    },
};
</script>
<style scoped>
.settings-container {
    margin: 2vh;
    max-width: 100%;
    width: 85vw;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}
#warehouseTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    /* text-align: center; */
    margin-bottom: 2vh;
}
.dx-html-editor {
    margin: auto;
    width: 75%;
    text-align: left;
}
.html-editor-title {
    display: flex;
    width: 500px;
    margin: auto;
    align-items: flex-start;
    justify-content: space-around;
}
.bussiness-year-select-box {
    width: 25%;
    margin: auto;
}
.save-button {
    width: 40%;
    margin: 25px;
}
.cancel-button {
    width: 40%;
    margin: 25px;
}
.header-button {
    width: 45%;
}
.button-container {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.email-data {
    margin: auto;
}
</style>
