import { reactive, toRefs } from "vue";
import { domainUser } from "@/stores/domainUserStore";
import { priceList } from "@/stores/priceListStore";
import { domain } from "@/stores/domainStore";

export function usePriceList() {
    const state = reactive({
        domain,
        domainUser,
        priceList,
        columnChooserMode: "select",
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
    });

    function handleRowInitPriceList(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: Number(state.domainId),
        };
    }

    function handleRowUpdatingPriceList(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: Number(state.adminId),
            modifiedDate: now.toISOString(),
        };
    }

    return {
        ...toRefs(state),
        handleRowInitPriceList,
        handleRowUpdatingPriceList,
    };
}
