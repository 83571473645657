<template>
    <div class="table-container">
        <span class="table-name">Dobavljači</span>
        <DxDataGrid
            id="supplierTable"
            :data-source="supplier"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            @initialized="getSupplierTableInstance"
            @row-updating="onRowUpdatingSupplier"
            @row-inserting="onRowInsertingSupplier"
            @row-inserted="onRowInsertedSupplier"
            @init-new-row="onInitNewRowSupplier"
        >
            <DxToolbar>
                <DxItem
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="exportButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="supplierTableState"
            />

            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :allowed-page-sizes="[10, 20, 30]"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />

            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Dodaj novog dobavljača"
                    :toolbar-items="popupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Informacije o dobavljaču"
                    >
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="oib" data-type="string">
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="name" data-type="string" />
                        <DxItem data-field="lastname" data-type="string" />
                        <DxItem data-field="companyName" data-type="string" />
                        <DxItem data-field="vatNumber" data-type="string" />
                        <DxItem
                            data-field="invoiceType"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="invoiceTypeSelectBoxOptions"
                        >
                            <DxRequiredRule
                        /></DxItem>
                    </DxItem>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Adresa/Kontakt"
                    >
                        <DxItem data-field="zipCode" data-type="string" />
                        <DxItem data-field="city" data-type="string" />
                        <DxItem data-field="street" data-type="string" />
                        <DxItem data-field="country" data-type="string" />
                        <DxItem data-field="region" data-type="string" />
                        <DxItem data-field="contact" data-type="string" />
                        <DxItem data-field="phone" data-type="string" />
                        <DxItem data-field="fax" data-type="string" />
                        <DxItem data-field="email" data-type="string">
                            <DxEmailRule />
                        </DxItem>
                        <DxItem data-field="web" data-type="string" />
                    </DxItem>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Ostale informacije"
                    >
                        <DxItem data-field="discount" data-type="number" />
                        <DxItem data-field="profitCenter" data-type="string" />
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="oib"
                data-type="string"
                caption="OIB"
                :allow-hiding="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="vatNumber"
                data-type="string"
                caption="VAT broj"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="name"
                data-type="string"
                caption="Ime"
                :allow-hiding="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="lastname"
                data-type="string"
                caption="Prezime"
                :allow-hiding="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="companyName"
                data-type="string"
                caption="Naziv firme"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="invoiceType"
                data-type="string"
                caption="Tip računa"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="zipCode"
                data-type="string"
                caption="Poštanski broj"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="city"
                data-type="string"
                caption="Grad"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="street"
                data-type="string"
                caption="Ulica"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="country"
                data-type="string"
                caption="Država"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="region"
                data-type="string"
                caption="Regija"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="discount"
                data-type="number"
                caption="Popust"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="profitCenter"
                data-type="string"
                caption="Profitni centar"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="contact"
                data-type="string"
                caption="Kontakt"
            />
            <DxColumn data-field="phone" data-type="string" caption="Telefon" />
            <DxColumn
                data-field="fax"
                data-type="string"
                caption="Fax"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="email"
                data-type="string"
                caption="E-mail"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="web"
                data-type="string"
                caption="Web"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            />
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editSupplier"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteSupplier"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxItem,
    DxForm,
    DxPager,
    DxPopup,
    DxButton,
    DxPaging,
    DxColumn,
    DxLookup,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxEmailRule,
    DxScrolling,
    DxSearchPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxRequiredRule,
    DxColumnChooser,
    DxColumnChooserSelection,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";

import { ref, onMounted } from "vue";
import { useSupplier } from "@/composables/useSupplier.js";

export default {
    name: "supplierTableComponent",
    components: {
        DxItem,
        DxForm,
        DxPager,
        DxPopup,
        DxButton,
        DxPaging,
        DxColumn,
        DxLookup,
        DxToolbar,
        DxEditing,
        DxDataGrid,
        DxEmailRule,
        DxScrolling,
        DxSearchPanel,
        DxHeaderFilter,
        DxStateStoring,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection,
    },
    setup() {
        const {
            supplier,
            domainUser,
            invoiceTypes,
            saveLastData,
            lastSavedData,
            columnChooserMode,
            handleNewRowInitSupplier,
            handleRowUpdatingSupplier,
            handleRowInsertingSupplier,
            invoiceTypeSelectBoxOptions,
            handleExportSupplier,
            getDomainData,
        } = useSupplier();
        onMounted(async () => {
            await getDomainData();
        });

        //instances
        const tableInstance = ref(null);

        //button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addSupplier();
            },
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });
        const exportButtonOptions = ref({
            icon: "export",
            onClick: () => {
                exportSupplier();
            },
        });

        //toolbar items
        const popupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi i novi",
                    onClick: () => saveAndContinueSupplier(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveSupplier(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelSupplier(),
                },
            },
        ]);

        //instance getters
        const getSupplierTableInstance = (e) => {
            tableInstance.value = e.component;
        };

        //supplier table methods
        const addSupplier = () => {
            tableInstance.value.addRow();
        };
        const editSupplier = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelSupplier = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteSupplier = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowSupplier = (e) => {
            handleNewRowInitSupplier(e);
        };
        const saveSupplier = () => {
            saveLastData.value = false;
            lastSavedData.value = {};
            tableInstance.value.saveEditData();
        };
        const saveAndContinueSupplier = () => {
            saveLastData.value = true;
            tableInstance.value.saveEditData();
        };
        const onRowInsertingSupplier = (e) => {
            e.data = handleRowInsertingSupplier(e);
        };
        const onRowInsertedSupplier = () => {
            if (saveLastData.value) {
                addSupplier();
            }
            tableInstance.value.refresh();
        };
        const onRowUpdatingSupplier = (e) => {
            e.newData = handleRowUpdatingSupplier(e);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const exportSupplier = () => {
            handleExportSupplier(tableInstance.value);
        };
        return {
            invoiceTypeSelectBoxOptions,
            popupToolbarItems,
            columnChooserButtonOptions,
            refreshButtonOptions,
            addButtonOptions,
            getSupplierTableInstance,
            columnChooserMode,
            invoiceTypes,
            supplier,
            domainUser,
            addSupplier,
            editSupplier,
            cancelSupplier,
            deleteSupplier,
            onInitNewRowSupplier,
            saveSupplier,
            saveAndContinueSupplier,
            onRowInsertingSupplier,
            onRowInsertedSupplier,
            onRowUpdatingSupplier,
            refreshData,
            openColumnChooser,
            exportButtonOptions,
        };
    },
    // data() {
    //     return {
    //         //Models
    //         supplierModel: new SupplierModel(),

    //         //Stores
    //         supplier: supplier,
    //         domainUser: domainUser,

    //         //Instances
    //         tableInstance: null,

    //         //Options
    //         invoiceTypeSelectBoxOptions: null,

    //         //Data
    //         adminId: null,
    //         domainId: null,
    //         lastSavedData: {},
    //         saveLastData: false,
    //         invoiceTypes: [{ value: "R1" }, { value: "R2" }],

    //         //General data
    //         mode: "select",

    //         //Button options
    //         addButtonOptions: {
    //             icon: "add",
    //             onClick: () => {
    //                 this.addRow();
    //             },
    //         },
    //         refreshButtonOptions: {
    //             icon: "refresh",
    //             onClick: () => {
    //                 this.refreshData();
    //             },
    //         },
    //         columnChooserButtonOptions: {
    //             icon: "columnchooser",
    //             onClick: () => {
    //                 this.openColumnChooser();
    //             },
    //         },

    //         //Toolbar items
    //         popupToolbarItems: [
    //             {
    //                 widget: "dxButton",
    //                 location: "after",
    //                 toolbar: "bottom",
    //                 options: {
    //                     text: "Spremi i novi",
    //                     onClick: this.saveAndContinue,
    //                 },
    //             },
    //             {
    //                 widget: "dxButton",
    //                 location: "after",
    //                 toolbar: "bottom",
    //                 options: {
    //                     text: "Spremi",
    //                     onClick: this.save,
    //                 },
    //             },
    //             {
    //                 widget: "dxButton",
    //                 location: "after",
    //                 toolbar: "bottom",
    //                 options: {
    //                     text: "Odustani",
    //                     onClick: this.cancel,
    //                 },
    //             },
    //         ],
    //     };
    // },
    // created() {
    //     this.adminId = localStorage.getItem("userId");
    //     this.domainId = localStorage.getItem("userDomainId");
    //     this.invoiceTypeSelectBoxOptions = {
    //         items: this.invoiceTypes,
    //         displayExpr: "value",
    //         valueExpr: "value",
    //         placeholder: "Izaberi tip računa",
    //     };
    // },
    // methods: {
    //     //Instances
    //     supplierTableInstance(e) {
    //         this.tableInstance = e.component;
    //     },

    //     //Supplier table methods
    //     addRow() {
    //         this.tableInstance.addRow();
    //     },
    //     editRow(e) {
    //         this.tableInstance = e.component;
    //         this.tableInstance.editRow(e.row.rowIndex);
    //     },
    //     cancel() {
    //         this.tableInstance.cancelEditData();
    //     },
    //     deleteRow(e) {
    //         this.tableInstance = e.component;
    //         this.tableInstance.deleteRow(e.row.rowIndex);
    //     },
    //     onInitNewRow(e) {
    //         if (this.saveLastData) {
    //             e.data = {
    //                 ...this.lastSavedData,
    //             };
    //             this.saveLastData = false;
    //         }
    //     },
    //     save() {
    //         this.saveLastData = false;
    //         this.lastSavedData = {};
    //         this.tableInstance.saveEditData();
    //     },
    //     saveAndContinue() {
    //         this.saveLastData = true;
    //         this.tableInstance.saveEditData();
    //     },
    //     onRowInserting(e) {
    //         e.data = this.supplierModel.handleRowInserting(
    //             e,
    //             this.adminId,
    //             this.domainId
    //         );
    //         if (this.saveLastData) {
    //             this.lastSavedData = {
    //                 ...e.data,
    //             };
    //         }
    //     },
    //     onRowInserted() {
    //         if (this.saveLastData) {
    //             this.addRow();
    //         }
    //         this.tableInstance.refresh();
    //     },
    //     onRowUpdating(e) {
    //         e.newData = this.supplierModel.handleRowUpdating(e, this.adminId);
    //     },

    //     //Button methods
    //     refreshData() {
    //         const message = "Podaci osvježeni!";
    //         this.tableInstance.refresh();
    //         notify({ message, width: 450 }, "success");
    //     },
    //     openColumnChooser() {
    //         this.tableInstance.showColumnChooser();
    //     },
    // },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#supplierTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
