<template>
    <div class="table-container">
        <h1>Dobar dan, {{ adminUsername }}! 😁</h1>
    </div>
</template>
<script>
export default {
    name: "dashboardComponent",

    data() {
        return {
            adminUsername: null,
        };
    },
    created() {
        this.adminUsername = localStorage.getItem("username");
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerItemTable {
    width: 100%;
}
</style>
