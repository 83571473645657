<template>
    <div class="table-container">
        <span class="table-name">Ponuda</span>
        <DxDataGrid
            id="offerTable"
            :data-source="offer"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getOfferTableInstance"
            @init-new-row="onInitNewRowOffer"
            @row-inserting="onRowInsertingOffer"
            @row-inserted="onRowInsertedOffer"
            @editing-start="onEditingStartOffer"
            @row-updating="onRowUpdatingOffer"
            @row-updated="onRowUpdatedOffer"
            @focused-row-changed="onFocusedRowChangedOffer"
            @edit-canceled="onEditCanceledOffer"
            edit-template="offerEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="offerTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="offerAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="offerRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="offerRecapExportButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o ponudi"
                    :toolbar-items="offerPopupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>

                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>

                        <DxItem
                            data-field="remark"
                            data-type="string"
                            col-span="2"
                        >
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="userId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="userSelectBoxOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem
                            data-field="expireDate"
                            data-type="date"
                            :editor-options="expireDateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                        <DxItem item-type="group" :col-span="2"></DxItem>
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="offerItemTable"
                                    :data-source="offerItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="getOfferItemTableInstance"
                                    @editing-start="onEditingStartOfferItem"
                                    @row-inserting="onRowInsertingOfferItem"
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="550"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                offerItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            99999999999.99
                                                        " /><DxRequiredRule
                                                /></DxItem>

                                                <DxItem
                                                    data-field="discountPercentage"
                                                    data-type="number"
                                                    :editor-options="
                                                        discountPercentageOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            100
                                                        " /><DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            99999999999.99
                                                        " /><DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="discountAmount"
                                                    data-type="number"
                                                    :editor-options="
                                                        discountAmountOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99" />
                                                    <DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceByQty"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceByQtyOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    :col-count="2"
                                                    :col-span="2"
                                                    item-type="group"
                                                    caption=" "
                                                >
                                                    <DxItem
                                                        data-field="warehouseId"
                                                        data-type="number"
                                                        editor-type="dxSelectBox"
                                                        :editor-options="
                                                            warehouseSelectBoxOptions
                                                        "
                                                    ></DxItem>
                                                    <DxItem
                                                        data-field="Stanje na skladištu"
                                                        data-type="number"
                                                        :editor-options="
                                                            warehouseStatusOptions
                                                        "
                                                    />
                                                </DxItem>
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        data-field="warehouseId"
                                        data-type="number"
                                        caption="Skladište"
                                        :visible="false"
                                    >
                                        <DxLookup
                                            :data-source="warehouse"
                                            value-expr="id"
                                            display-expr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceByQty"
                                        data-type="number"
                                        caption="Iznos"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="discountAmount"
                                        data-type="number"
                                        caption="Rabat"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="discountPercentage"
                                        data-type="number"
                                        caption="Rab%"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Iznos s rabatom"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editOfferItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteOfferItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="true"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
                :visible="false"
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
                sort-order="desc"
                ><DxHeaderFilter :groupInterval="'month'"
            /></DxColumn>
            <DxColumn
                data-field="expireDate"
                data-type="date"
                caption="Datum isteka"
                format="dd/MM/yyyy"
                :visible="false"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            />
            <DxColumn data-field="status" data-type="string" caption="Status" />
            <DxColumn data-field="userId" data-type="number" caption="Kupac">
                <DxLookup
                    :data-source="dataSource"
                    value-expr="id"
                    :displayExpr="userName"
                />
            </DxColumn>
            <DxColumn
                data-field="remark"
                data-type="string"
                caption="Opis"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="totalAmountTaxed"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
                :allow-exporting="false"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editOffer"
                />
                <DxButton hint="Dupliciraj" icon="copy" :visible="true" />
                <DxButton
                    hint="Napravi račun"
                    icon="import"
                    :visible="true"
                    @click="generateInvoice"
                    :disabled="isOfferPaid"
                />
                <DxButton
                    hint="Izradi PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportTo"
                />
                <DxButton
                    hint="Pošalji"
                    icon="message"
                    :visible="true"
                    @click="sendEmailPopup"
                />
                <DxButton
                    hint="Na čekanju - pregled"
                    icon="search"
                    :visible="true"
                    @click="pendingReviewOffer"
                />
                <DxButton
                    hint="Prihvaćeno"
                    icon="check"
                    :visible="true"
                    @click="acceptedOffer"
                />
                <DxButton
                    hint="Plaćeno"
                    icon="money"
                    :visible="true"
                    @click="paidOffer"
                />
                <DxButton
                    hint="Odbijeno"
                    icon="close"
                    :visible="true"
                    @click="rejectedOffer"
                />
                <DxButton
                    hint="Obriši"
                    icon="trash"
                    :visible="true"
                    @click="deleteOffer"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
        <EmailPopup
            :resize-enabled="true"
            :drag-outside-boundary="true"
            :toolbarItems="emailPopupToolbarItems"
            title="Slanje ponude emailom"
            @initialized="getEmailPopupInstance"
            @hiding="cancelEmail"
        >
            <EmailForm
                :form-data="emailFormData"
                @initialized="getEmailFormInstance"
                validationGroup="email"
            >
                <DxItem :col-count="2" :col-span="2" item-type="group">
                    <DxSimpleItem
                        data-field="emailFrom"
                        :editor-options="emailFromOptions"
                        :label="{ text: 'Šalje' }"
                        ><DxRequiredRule
                            message="Email pošiljatelja je obavezan" /><DxEmailRule
                            message="Email pošiljatelja nije ispravan"
                    /></DxSimpleItem>
                    <DxSimpleItem
                        data-field="emailTo"
                        :editor-options="emailToOptions"
                        :label="{ text: 'Prima' }"
                        ><DxRequiredRule
                            message="Email primatelja je obavezan" /><DxEmailRule
                            message="Email primatelja nije ispravan"
                    /></DxSimpleItem>
                </DxItem>
                <DxSimpleItem
                    :col-span="2"
                    data-field="subject"
                    :editor-option="subjectOptions"
                    :label="{ text: 'Subjekt' }"
                    ><DxRequiredRule message="Subjekt je obavezan"
                /></DxSimpleItem>
                <DxSimpleItem
                    :col-span="2"
                    data-field="message"
                    editorType="dxTextArea"
                    :editor-options="messageOptions"
                    :label="{ text: 'Poruka' }"
                    ><DxRequiredRule message="Poruka je obavezna"
                /></DxSimpleItem>
                <DxItem :col-span="2">
                    <template #default>
                        <HtmlButton
                            class="dxbutton-potpis"
                            text="Otključaj potpis"
                            icon="unlock"
                            :visible="signatureStatus"
                            @click="changeSignatureStatus"
                        />
                        <HtmlButton
                            class="dxbutton-potpis"
                            text="Zaključaj potpis"
                            icon="lock"
                            :visible="!signatureStatus"
                            @click="changeSignatureStatus"
                        />
                        <HtmlButton
                            text="Obriši potpis"
                            icon="trash"
                            :disabled="signatureStatus"
                            @click="clearSignature"
                        />
                        <span v-if="!signatureStatus" style="margin-left: 5px">
                            Zaključaj potpis prije slanja kako bi se promjene
                            spremile!</span
                        >
                        <DxHtmlEditor
                            value-type="html"
                            :value="signature"
                            height="725px"
                            class="dxhtmleditor"
                            :read-only="signatureStatus"
                            @initialized="getHtmlEditorInstance"
                            :toolbar="htmlEditorToolbarItems"
                        >
                            <DxMediaResizing :enabled="true" />
                            <DxImageUpload file-upload-mode="base64" />
                        </DxHtmlEditor>
                    </template>
                </DxItem>
            </EmailForm>
        </EmailPopup>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import { DxPopup as EmailPopup } from "devextreme-vue/popup";
import {
    DxForm as EmailForm,
    DxSimpleItem,
    DxEmailRule,
} from "devextreme-vue/form";
import {
    DxHtmlEditor,
    DxMediaResizing,
    DxImageUpload,
} from "devextreme-vue/html-editor";
import "devextreme-vue/text-area";
import { DxButton as HtmlButton } from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import DataSource from "devextreme/data/data_source";
import { useOffer } from "@/composables/useOffer.js";

//Utils
import eventBus from "../../eventBus.js";
import { ref } from "vue";

export default {
    name: "offerTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
        EmailPopup,
        EmailForm,
        DxSimpleItem,
        DxHtmlEditor,
        DxMediaResizing,
        DxImageUpload,
        HtmlButton,
        DxEmailRule,
    },
    setup() {
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);

        //items instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const dateSelectInstance = ref(null);
        const priceByQtyInstance = ref(null);
        const priceTotalInstance = ref(null);
        const priceListItemInstance = ref(null);
        const discountAmountInstance = ref(null);
        const warehouseStatusInstance = ref(null);
        const expireDateSelectInstance = ref(null);
        const discountPercentageInstance = ref(null);

        //email
        const subjectInstance = ref(null);
        const emailPopupInstance = ref(null);
        const emailFormInstance = ref(null);
        const emailFromInstance = ref(null);
        const emailToInstance = ref(null);
        const messageOptions = ref({ height: 200 });
        const sendEmailFlag = ref(false);

        //html editor
        const htmlEditorInstance = ref(null);
        const signatureStatus = ref(true);

        //general data
        const qty = ref(1);
        const price = ref(0);
        const priceByQty = ref(0);
        const priceTotal = ref(0);
        const discountAmount = ref(0);
        const discountPercentage = ref(0);
        const number = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const priceListItemId = ref(null);
        const selectedExpireDate = ref(null);
        const preventRecursiveUpdate = ref(false);

        const {
            offer,
            offerItem,
            invoiceItem,
            invoice,
            customer,
            user,
            domain,
            domainUser,
            warehouse,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            emailSignatureDefault,
            warehouseItemStatusPriceAverage,
            invoiceItemData,
            editingOffer,
            editingOfferItem,
            offerItemData,
            rowsToBeDeleted,
            offerItemsBeforeEdit,
            editingOfferItemOldData,
            columnChooserMode,
            userSignature,
            pdfForEmail,
            emailFormData,
            priceTotalFormat,
            signature,
            adminUsername,
            adminId,
            domainId,
            businessYearId,
            handleGenerateInvoice,
            handleInitNewRowOffer,
            handleRowInsertingOffer,
            handleRowInsertedOffer,
            handleEditingStartOffer,
            handleEditCanceledOffer,
            handleRowUpdatingOffer,
            handleCancelOfferItem,
            handleDeleteOfferItem,
            handleSaveNewOfferItem,
            handleRowInsertingOfferItem,
            handleEditingStartOfferItem,
            handleSendEmailPopupData,
            handleSendEmail,
            handleExportingOfferRecap,
        } = useOffer();
        const dataSource = Number(domainId.value) === 1 ? user : customer;
        //Button options
        const offerAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addOffer();
            },
        });
        const offerItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addOfferItem();
            },
        });
        const offerRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });
        const offerRecapExportButtonOptions = ref({
            icon: "export",
            onClick: () => onExportingOfferRecap(),
        });

        //Toolbar items
        const offerPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    width: 120,
                    onClick: () => saveNewOffer(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelOffer(),
                },
            },
        ]);
        const offerItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    width: 120,
                    onClick: () => saveNewOfferItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelOfferItem(),
                },
            },
        ]);
        const emailPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Pošalji",
                    type: "success",
                    icon: "check",
                    width: 120,
                    useSubmitBehavior: true,
                    onClick: () => sendEmail(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelEmail(),
                },
            },
        ]);

        const htmlEditorToolbarItems = ref({
            items: [
                "undo",
                "redo",
                "separator",
                {
                    name: "size",
                    acceptedValues: [
                        "8pt",
                        "10pt",
                        "12pt",
                        "14pt",
                        "18pt",
                        "24pt",
                        "36pt",
                    ],
                },
                {
                    name: "font",
                    acceptedValues: [
                        "Arial",
                        "Georgia",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                    ],
                },
                "separator",
                "bold",
                "italic",
                "strike",
                "underline",
                "separator",
                "alignLeft",
                "alignCenter",
                "alignRight",
                "alignJustify",
                "separator",
                "orderedList",
                "bulletList",
                "separator",
                {
                    name: "header",
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                },
                "separator",
                "color",
                "background",
                "separator",
                "link",
                "image",
                "separator",
                "clear",
                "codeBlock",
                "blockquote",
                "separator",
            ],
            multiline: true,
        });

        //form item options
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const expireDateSelectOptions = ref({
            onInitialized: (e) => {
                expireDateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedExpireDate.value = e.value;
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            // disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: (e) => {
                number.value = e.value;
                numberInstance.value.option("value", number.value);
            },
        });
        const userSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: dataSource.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi kupca",
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedPriceListItem =
                    priceListItemInstance.value.option("value");
                if (selectedPriceListItem) {
                    const loadOptions = {
                        priceListItemId: selectedPriceListItem,
                        warehouseId: e.value,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceListItem.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    priceInstance.value.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
                discountAmountInstance.value.option("disabled", false);
                discountPercentageInstance.value.option("disabled", false);
            },
        });
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear,
            valueExpr: "id",
            disabled: true,
        });
        const qtyOptions = ref({
            value: qty.value,
            format: priceTotalFormat.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updatePriceByQty();
            },
        });
        const priceOptions = ref({
            value: price.value,
            format: priceTotalFormat.value,
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updatePriceByQty();
            },
        });
        const priceByQtyOptions = ref({
            value: priceByQty.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                priceByQtyInstance.value = e.component;
            },
        });
        const discountPercentageOptions = ref({
            value: discountPercentage.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                discountPercentageInstance.value = e.component;
                discountPercentageInstance.value.option(
                    "value",
                    discountPercentage.value
                );
            },
            onValueChanged: (e) => updateDiscountPercentage(e),
        });
        const discountAmountOptions = ref({
            value: discountAmount.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                discountAmountInstance.value = e.component;
                discountAmountInstance.value.option(
                    "value",
                    discountAmount.value
                );
            },
            onValueChanged: (e) => updateDiscountAmount(e),
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });
        const emailFromOptions = ref({
            // disabled: true,
            onInitialized: (e) => {
                emailFromInstance.value = e.component;
                // emailFromInstance.option("value", "dev@gtnet.hr");
            },
        });
        const emailToOptions = ref({
            onInitialized: (e) => {
                emailToInstance.value = e.component;
            },
        });
        const subjectOptions = ref({
            onInitialized: (e) => {
                subjectInstance.value = e.component;
            },
        });

        //instances
        const getOfferTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getOfferItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };
        const getHtmlEditorInstance = (e) => {
            htmlEditorInstance.value = e.component;
        };
        const getEmailPopupInstance = (e) => {
            emailPopupInstance.value = e.component;
        };
        const getEmailFormInstance = (e) => {
            emailFormInstance.value = e.component;
        };

        //offer table methods
        const onFocusedRowChangedOffer = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit("newFocusedRowOffer", selectedRowKey.value);
                }
            } else {
                eventBus.emit("newFocusedRowOffer", null);
            }
        };
        const addOffer = () => {
            tableInstance.value.addRow();
        };
        const editOffer = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelOffer = () => {
            tableInstance.value.cancelEditData();
        };
        const onInitNewRowOffer = async (e) => {
            const now = new Date();
            const nextThreeDays = new Date(now.setDate(now.getDate() + 3));
            const today = new Date(now.setDate(now.getDate() - 3));

            priceListItemId.value = null;
            tableInstance.value.option("focusedRowIndex", -1);
            number.value = await handleInitNewRowOffer(e);

            setTimeout(() => {
                dateSelectInstance.value.option("value", today.toISOString());
                expireDateSelectInstance.value.option(
                    "value",
                    nextThreeDays.toISOString()
                );
            }, 100);
        };
        const saveNewOffer = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingOffer = (e) => {
            handleRowInsertingOffer(
                e,
                selectedDate.value,
                selectedExpireDate.value,
                number.value
            );
        };
        const onRowInsertedOffer = async (e) => {
            await handleRowInsertedOffer(e);

            qty.value = 1;
            discountAmount.value = 0;
            discountPercentage.value = 0;
            tableInstance.value.refresh();
        };
        const onEditingStartOffer = (e) => {
            handleEditingStartOffer(e);

            const now = new Date().toISOString();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now
                );
                dateSelectInstance.value.option("value", e.data.date);
                expireDateSelectInstance.value.option(
                    "value",
                    e.data.expireDate
                );
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledOffer = () => {
            handleEditCanceledOffer();

            itemTableInstance.value.refresh();
        };
        const onRowUpdatingOffer = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            if (selectedExpireDate.value !== e.oldData.expireDate) {
                e.newData.expireDate = selectedExpireDate.value;
            }
            await handleRowUpdatingOffer(e);

            qty.value = 1;
            discountAmount.value = 0;
            discountPercentage.value = 0;
            tableInstance.value.refresh();
        };
        const onRowUpdatedOffer = () => {
            eventBus.emit("newOfferItemAdded");
        };
        const onExportingOfferRecap = () => {
            handleExportingOfferRecap(tableInstance.value);
        };

        //Offer item table methods
        const addOfferItem = () => {
            itemTableInstance.value.addRow();
        };
        const editOfferItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelOfferItem = () => {
            handleCancelOfferItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteOfferItem = (e) => {
            handleDeleteOfferItem(e);
            itemTableInstance.value.refresh();
        };
        const saveNewOfferItem = () => {
            handleSaveNewOfferItem(qty.value, price.value, priceTotal.value);
            itemTableInstance.value.saveEditData();
            qty.value = 1;
            discountAmount.value = 0;
            discountPercentage.value = 0;
        };
        const onRowInsertingOfferItem = (e) => {
            handleRowInsertingOfferItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                discountAmount.value,
                discountPercentage.value,
                priceListItemId.value
            );
            qty.value = 1;
            discountAmount.value = 0;
            discountPercentage.value = 0;
        };
        const onEditingStartOfferItem = async (e) => {
            qty.value = e.data.qty;
            handleEditingStartOfferItem(e);

            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
            price.value = e.data.price;
            priceListItemId.value = null;
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const deleteOffer = (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u obrisan?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "deleted"`;
                            notify({ message, width: 450 }, "success");
                            offer.value.update(focusedRowKey, {
                                status: "deleted",
                            });
                            tableInstance.value.refresh();
                        }
                    });
                }
            }
        };
        const acceptedOffer = (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u prihvaćen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "accepted"`;
                            notify({ message, width: 450 }, "success");
                            offer.value.update(focusedRowKey, {
                                status: "accepted",
                            });
                            tableInstance.value.refresh();
                        }
                    });
                }
            }
        };
        const rejectedOffer = (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u odbijen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "rejected"`;
                            notify({ message, width: 450 }, "success");

                            offer.value.update(focusedRowKey, {
                                status: "rejected",
                            });
                            tableInstance.value.refresh();
                        }
                    });
                }
            }
        };
        const paidOffer = (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u plaćen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "paid"`;
                            notify({ message, width: 450 }, "success");
                            offer.value.update(focusedRowKey, {
                                status: "paid",
                            });
                            tableInstance.value.refresh();
                        }
                    });
                }
            }
        };
        const pendingReviewOffer = (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u na čekanju - pregled?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "pending-review"`;
                            notify({ message, width: 450 }, "success");
                            offer.value.update(focusedRowKey, {
                                status: "pending-review",
                            });
                            tableInstance.value.refresh();
                        }
                    });
                }
            }
        };
        const exportTo = () => {
            const dontChangeStatus = ["paid", "accepted", "rejected"];
            let res = confirm(
                "Sigurno želite ispisati ponudu u obliku PDF-a?",
                "Potvrda"
            );
            res.then(async (e) => {
                if (e) {
                    eventBus.emit("exportOfferItemToPDF", sendEmailFlag.value);
                    const res = await offer.value.byKey(selectedRowKey.value);
                    if (!dontChangeStatus.includes(res.status)) {
                        const message = `ID: ${selectedRowKey.value}, status: "pending"`;
                        notify({ message, width: 450 }, "success");
                        offer.value.update(selectedRowKey.value, {
                            status: "pending",
                        });
                        tableInstance.value.refresh();
                    }
                }
            });
        };
        const generateInvoice = async (e) => {
            await handleGenerateInvoice(e);
            tableInstance.value.refresh();
            const message = "Račun napravljen!";
            notify({ message, width: 450 }, "success");
        };
        const sendEmailPopup = async () => {
            emailPopupInstance.value.show();
            await handleSendEmailPopupData(selectedRowKey.value);
        };
        //Email popup button options
        const cancelEmail = () => {
            if (!sendEmailFlag.value) {
                emailFormData.value = {
                    sourceType: "proforma",
                    sourceId: null,
                    senderId: null,
                    dateSent: null,
                    clientId: null,
                    emailFrom: null,
                    emailTo: null,
                    subject: null,
                    message: null,
                };
            }
            emailPopupInstance.value.hide();
        };
        const changeSignatureStatus = async () => {
            signatureStatus.value = !signatureStatus.value;
            if (htmlEditorInstance.value.option("isDirty")) {
                signature.value = htmlEditorInstance.value.option("value");
                await domainUser.value.update(adminId.value, {
                    emailSignature: signature.value,
                });
            }
        };
        const clearSignature = () => {
            signature.value = "";
        };
        const sendEmail = async () => {
            const isValid = emailFormInstance.value.validate("email");
            if (isValid.isValid) {
                const { emailFlag, responseData } = await handleSendEmail(
                    sendEmailFlag.value,
                    selectedRowKey.value
                );
                sendEmailFlag.value = emailFlag;
                notify(
                    responseData.message +
                        "! Status ponude promjenjen u - Na čekanju",
                    "success",
                    4000
                );
                emailPopupInstance.value.hide();
            }
        };

        //subscribe to events
        eventBus.on("offerPdfForEmail", (data) => {
            pdfForEmail.value = data;
        });
        eventBus.on("offerItems", (data) => {
            offerItemData.value = data;
        });
        eventBus.on("invoiceItems", (data) => {
            invoiceItemData.value = data;
        });

        //general methods
        const isOfferPaid = (e) => {
            if (e.row.data.status === "paid") return false;
            else return true;
        };
        const updateDiscountAmount = (e) => {
            if (!preventRecursiveUpdate.value) {
                preventRecursiveUpdate.value = true;
                discountAmount.value = e.value;
                discountAmountInstance.value.option(
                    "value",
                    discountAmount.value
                );
                priceTotal.value = priceByQty.value - discountAmount.value;
                priceTotalInstance.value.option(
                    "value",
                    Number(priceTotal.value)
                );
                discountPercentage.value =
                    priceByQty.value > 0
                        ? (discountAmount.value / priceByQty.value) * 100
                        : 0;
                discountPercentageInstance.value.option(
                    "value",
                    discountPercentage.value
                );
                preventRecursiveUpdate.value = false;
            }
        };
        const updateDiscountPercentage = (e) => {
            if (!preventRecursiveUpdate.value) {
                preventRecursiveUpdate.value = true;
                discountPercentage.value = e.value;
                discountPercentageInstance.value.option(
                    "value",
                    discountPercentage.value
                );
                discountAmount.value =
                    priceByQty.value * (discountPercentage.value / 100);
                discountAmountInstance.value.option(
                    "value",
                    discountAmount.value
                );
                priceTotal.value = priceByQty.value - discountAmount.value;
                priceTotalInstance.value.option(
                    "value",
                    Number(priceTotal.value)
                );
                preventRecursiveUpdate.value = false;
            }
        };
        const updatePriceByQty = () => {
            priceByQtyInstance.value.option("value", qty.value * price.value);
            priceByQty.value = qty.value * price.value;
            priceTotal.value = priceByQty.value - discountAmount.value;
            priceTotalInstance.value.option("value", Number(priceTotal.value));
            discountPercentage.value =
                priceByQty.value > 0
                    ? (discountAmount.value / priceByQty.value) * 100
                    : 0;
            discountPercentageInstance.value.option(
                "value",
                discountPercentage.value
            );
        };
        const userName = (item) => {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        };
        return {
            offer,
            offerItem,
            invoiceItem,
            invoice,
            customer,
            dataSource,
            domain,
            domainUser,
            warehouse,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            emailSignatureDefault,
            warehouseItemStatusPriceAverage,
            invoiceItemData,
            editingOffer,
            editingOfferItem,
            preventRecursiveUpdate,
            offerItemData,
            rowsToBeDeleted,
            offerItemsBeforeEdit,
            editingOfferItemOldData,
            columnChooserMode,
            userSignature,
            pdfForEmail,
            emailFormData,
            priceTotalFormat,
            adminUsername,
            signature,
            adminId,
            businessYearId,
            offerAddButtonOptions,
            offerItemAddButtonOptions,
            offerRefreshButtonOptions,
            columnChooserButtonOptions,
            offerPopupToolbarItems,
            offerItemPopupToolbarItems,
            emailPopupToolbarItems,
            dateSelectOptions,
            expireDateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            userSelectBoxOptions,
            htmlEditorToolbarItems,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceByQtyOptions,
            discountPercentageOptions,
            discountAmountOptions,
            priceTotalOptions,
            emailFromOptions,
            emailToOptions,
            subjectOptions,
            getOfferTableInstance,
            getOfferItemTableInstance,
            getHtmlEditorInstance,
            getEmailPopupInstance,
            getEmailFormInstance,
            messageOptions,
            onFocusedRowChangedOffer,
            editOffer,
            onInitNewRowOffer,
            onRowInsertingOffer,
            onRowInsertedOffer,
            onEditingStartOffer,
            onEditCanceledOffer,
            onRowUpdatingOffer,
            onRowUpdatedOffer,
            editOfferItem,
            deleteOfferItem,
            onRowInsertingOfferItem,
            onEditingStartOfferItem,
            deleteOffer,
            acceptedOffer,
            rejectedOffer,
            paidOffer,
            pendingReviewOffer,
            exportTo,
            generateInvoice,
            sendEmailPopup,
            cancelEmail,
            changeSignatureStatus,
            signatureStatus,
            clearSignature,
            sendEmail,
            isOfferPaid,
            updateDiscountAmount,
            updateDiscountPercentage,
            updatePriceByQty,
            userName,
            offerRecapExportButtonOptions,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerTable {
    max-width: 100%;
}
#offerItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
.dxbutton-potpis {
    margin-right: 5px;
}
.dxhtmleditor {
    margin-top: 10px;
}
</style>
