import { reactive, toRefs } from "vue";

import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { domainUser } from "@/stores/domainUserStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore.js";
import { intermediateWarehouse } from "@/stores/intermediateWarehouseStore.js";
import { intermediateWarehouseItem } from "@/stores/intermediateWarehouseItemStore.js";

import {
    updateWarehouseItemStatusOnNewIntermediateWarehouse,
    updateWarehouseItemStatusOnUpdatedIntermediateWarehouse,
    updateWarehouseItemStatusOnRemovedIntermediateWarehouse,
} from "@/utils/updateWarehouseItemStatus";

export function useIntermediateWarehouse() {
    const state = reactive({
        domain,
        warehouse,
        domainUser,
        businessYear,
        priceListItem,
        warehouseItemStatus,
        intermediateWarehouse,
        intermediateWarehouseItem,
        editingIntermediateWarehouse: null,
        editingIntermediateWarehouseItem: null,
        rowsToBeDeleted: [],
        intermediateWarehouseItemData: [],
        editingIntermediateWarehouseItemOldData: [],
        newEditingIntermediateWarehouseWarehouseItemStatus: [],
        columnChooserMode: "select",
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
        businessYearId: localStorage.getItem("businessYearId"),
    });

    function handleRowInsertingIntermediateWarehouse(e, number, date) {
        const now = new Date().toISOString();
        e.data = {
            ...e.data,
            date: date,
            number: number,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now,
            modifiedDate: now,
            domainId: Number(state.domainId),
        };
    }
    async function handleRowInsertedIntermediateWarehouse(e) {
        let intermediateWarehousePriceTotal = 0;
        const now = new Date().toISOString();
        for (let i = 0; i < state.intermediateWarehouseItemData.length; i++) {
            const item = state.intermediateWarehouseItemData[i];
            delete item.__KEY__;
            item.intermediateWarehouseId = e.key;
            item.priceTotal = Number(item.priceTotal);
            item.createdById = Number(state.adminId);
            item.modifiedById = Number(state.adminId);
            item.createdDate = now;
            item.modifiedDate = now;
            intermediateWarehouseItem.insert(item);
            await updateWarehouseItemStatusOnNewIntermediateWarehouse(
                item,
                e.data.fromWarehouseId,
                e.data.toWarehouseId
            );
            intermediateWarehousePriceTotal += item.priceTotal;
        }
        intermediateWarehouse.update(e.key, {
            priceTotal: Number(intermediateWarehousePriceTotal),
        });
        state.intermediateWarehouseItemData = [];
    }
    async function handleEditingStartIntermediateWarehouse(e) {
        state.editingIntermediateWarehouse = e.data;
        await intermediateWarehouseItem.load();
    }
    function handleEditCanceledIntermediateWarehouse() {
        state.rowsToBeDeleted = [];
        state.intermediateWarehouseItemData = [];
    }
    async function handleRowUpdatingIntermediateWarehouse(e, priceTotal) {
        let intermediateWarehousePriceTotal = 0;
        const now = new Date().toISOString();
        // console.log(state.rowsToBeDeleted);
        const allPromises = [];

        const deletePromises = state.rowsToBeDeleted.map((item) => {
            intermediateWarehouseItem
                .remove(item.id)
                .then(() => {
                    const loadOptions = {
                        priceListItemId: item.priceListItemId,
                        warehouseId: e.oldData.toWarehouseId,
                    };
                    return warehouseItemStatus.load(loadOptions);
                })
                .then((res) => {
                    const updateData = {
                        qty: res[0].qty - item.qty,
                    };
                    return warehouseItemStatus.update(res[0].id, updateData);
                })
                .then(() => {
                    const loadOptions = {
                        priceListItemId: item.priceListItemId,
                        warehouseId: e.oldData.fromWarehouseId,
                    };
                    return warehouseItemStatus.load(loadOptions);
                })
                .then((res) => {
                    const updateData = {
                        qty: res[0].qty + item.qty,
                    };
                    return warehouseItemStatus.update(res[0].id, updateData);
                });
        });
        allPromises.push(...deletePromises);
        allPromises.push(
            updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(
                state.newEditingIntermediateWarehouseWarehouseItemStatus,
                state.editingIntermediateWarehouseItemOldData,
                state.intermediateWarehouseItemData,
                e.oldData.fromWarehouseId,
                e.oldData.toWarehouseId
            )
        );

        const insertPromises = state.intermediateWarehouseItemData.map(
            (item) => {
                if (item.id) {
                    allPromises.push(intermediateWarehouseItem.remove(item.id));
                    delete item.id;
                }
                if (item.__KEY__) {
                    delete item.__KEY__;
                }
                Object.assign(item, {
                    intermediateWarehouseId: e.key,
                    priceTotal: Number(priceTotal),
                    createdById: Number(state.adminId),
                    modifiedById: Number(state.adminId),
                    createdDate: now,
                    modifiedDate: now,
                });
                intermediateWarehousePriceTotal += item.priceTotal;
                return intermediateWarehouseItem.insert(item);
            }
        );
        allPromises.push(...insertPromises);
        await Promise.all(allPromises);
        await intermediateWarehouse.update(e.key, {
            priceTotal: Number(intermediateWarehousePriceTotal),
        });
        state.rowsToBeDeleted = [];
        state.intermediateWarehouseItemData = [];
    }
    async function handleRowRemovingIntermediateWarehouse(e) {
        const deletePromises = state.intermediateWarehouseItemData.map(
            (item) => {
                return Promise.all([
                    intermediateWarehouseItem.remove(item.id),
                    updateWarehouseItemStatusOnRemovedIntermediateWarehouse(
                        item,
                        e.data.fromWarehouseId,
                        e.data.toWarehouseId
                    ),
                ]);
            }
        );
        await Promise.all(deletePromises);
    }
    function handleCancelItermediateWarehouseItem() {
        state.editingIntermediateWarehouseItemOldData.pop();
        state.editingIntermediateWarehouseItem = null;
    }
    function handleSaveNewIntermediateWarehouseItem(qty, price, priceTotal) {
        const now = new Date().toISOString();
        if (state.editingIntermediateWarehouseItem !== null) {
            state.editingIntermediateWarehouseItem.qty = qty;
            state.editingIntermediateWarehouseItem.price = price;
            state.editingIntermediateWarehouseItem.priceTotal =
                Number(priceTotal);
            state.editingIntermediateWarehouseItem.modifiedDate = now;
            state.editingIntermediateWarehouseItem.modifiedById = Number(
                state.adminId
            );
            state.newEditingIntermediateWarehouseWarehouseItemStatus.push(
                state.editingIntermediateWarehouseItem
            );
            state.intermediateWarehouseItemData.find((el) => {
                if (
                    state.editingIntermediateWarehouseItem.__KEY__ &&
                    el.__KEY__ == state.editingIntermediateWarehouseItem.__KEY__
                ) {
                    el = state.editingIntermediateWarehouseItem;
                } else if (
                    el.id === state.editingIntermediateWarehouseItem.id
                ) {
                    el = state.editingIntermediateWarehouseItem;
                }
            });

            state.editingIntermediateWarehouseItem = null;
        } else {
            state.editingIntermediateWarehouseItem = null;
        }
    }
    function handleRowInsertingIntermediateWarehouseItem(
        e,
        qty,
        price,
        priceTotal,
        priceListItemId
    ) {
        e.data = {
            ...e.data,
            qty: qty,
            price: price,
            priceTotal: Number(priceTotal),
            priceListItemId: priceListItemId,
        };
    }
    function handleEditingStartIntermediateWarehouseItem(e) {
        state.editingIntermediateWarehouseItem = e.data;
        const oldData = {
            ...state.editingIntermediateWarehouseItem,
        };
        state.editingIntermediateWarehouseItemOldData.push(oldData);
    }
    return {
        ...toRefs(state),
        handleRowInsertingIntermediateWarehouse,
        handleRowInsertedIntermediateWarehouse,
        handleEditingStartIntermediateWarehouse,
        handleEditCanceledIntermediateWarehouse,
        handleRowUpdatingIntermediateWarehouse,
        handleRowRemovingIntermediateWarehouse,
        handleCancelItermediateWarehouseItem,
        handleSaveNewIntermediateWarehouseItem,
        handleRowInsertingIntermediateWarehouseItem,
        handleEditingStartIntermediateWarehouseItem,
    };
}
