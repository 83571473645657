<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="priceListItemsTable"
            :data-source="priceListItem"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            :column-min-width="70"
            :column-auto-width="true"
            :allow-column-resizing="true"
            @initialized="getPriceListItemTableInstance"
            @init-new-row="onInitNewRowPriceListItem"
            @row-inserting="onRowInsertingPriceListItem"
            @row-inserted="onRowInsertedPriceListItem"
            @row-updating="onRowUpdatingPriceListItem"
            @row-updated="onRowUpdatedPriceListItem"
            @row-removed="onRowRemovedPriceListItem"
            @editing-start="onEditingStartPriceListItem"
            @exporting="onExporting"
        >
            <DxExport :enabled="true" :formats="['pdf']" />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="priceListItemTableState"
            />
            <DxToolbar>
                <DxItem
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem name="exportButton" />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing :allow-updating="true" :use-icons="true" mode="popup">
                <DxPopup
                    :show-title="true"
                    title="Informacije o stavci"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            item-type="group"
                            :col-count="3"
                            :col-span="3"
                            caption="Osnovne informacije"
                        >
                            <!-- <DxItem
                                data-field="itemGroupId"
                                data-type="number"
                            /> -->
                            <DxItem data-field="code" data-type="string">
                                <DxRequiredRule />
                            </DxItem>
                            <DxItem data-field="name" data-type="string"
                                ><DxRequiredRule
                            /></DxItem>
                            <DxItem
                                data-field="stockNumber"
                                data-type="string"
                            />
                            <DxItem
                                data-field="foreignName"
                                data-type="string"
                            />
                            <DxItem data-field="ean" data-type="string" />
                            <DxItem
                                data-field="customsNumber"
                                data-type="string"
                            />
                        </DxItem>
                        <DxItem
                            item-type="group"
                            :col-count="3"
                            :col-span="3"
                            caption="Tip/Dobavljač"
                        >
                            <DxItem data-field="measureUnit" data-type="string"
                                ><DxRequiredRule
                            /></DxItem>
                            <DxItem
                                data-field="vat"
                                data-type="number"
                                :readOnly="true"
                                ><DxRequiredRule
                            /></DxItem>
                            <DxItem
                                data-field="itemType"
                                data-type="string"
                                editor-type="dxSelectBox"
                                :editor-options="itemTypeOptions"
                                ><DxRequiredRule
                            /></DxItem>
                            <DxItem
                                data-field="supplierId"
                                data-type="number"
                                editor-type="dxSelectBox"
                                :editor-options="supplierSelectBoxOptions"
                                ><DxRequiredRule
                            /></DxItem>
                        </DxItem>
                        <DxItem
                            item-type="group"
                            :col-count="3"
                            :col-span="3"
                            caption="Informacije o cijeni"
                        >
                            <DxItem
                                data-field="latestProcurementPrice"
                                data-type="number"
                                :disabled="true"
                            />
                            <DxItem
                                data-field="priceCalculationType"
                                data-type="string"
                                editor-type="dxSelectBox"
                                :editor-options="priceCalculationTypeOptions"
                            />
                            <DxItem
                                data-field="wholesalePrice"
                                data-type="number"
                                :editor-options="wholesalePriceOptions"
                            >
                                <DxRequiredRule
                            /></DxItem>
                            <DxItem
                                data-field="vatAmount"
                                data-type="number"
                                :editor-options="vatAmountOptions"
                            />
                            <DxItem
                                data-field="retailPrice"
                                data-type="number"
                                :editor-options="retailPriceOptions"
                                ><DxRequiredRule
                            /></DxItem>
                            <DxItem data-field="margin" data-type="number" />
                        </DxItem>
                        <DxItem
                            item-type="group"
                            :col-count="3"
                            :col-span="3"
                            caption="Ostale informacije"
                        >
                            <DxItem
                                data-field="returnPackagingQty"
                                data-type="number"
                            />
                            <DxItem
                                data-field="orderedQty"
                                data-type="number"
                            />
                            <DxItem data-field="minStock" data-type="number" />
                            <DxItem data-field="maxStock" data-type="number" />
                            <DxItem
                                data-field="hidden"
                                data-type="number"
                                :editor-options="hiddenOptions"
                                editor-type="dxSelectBox"
                            />
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumnFixing :enabled="true" />
            <DxColumn
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
            />
            <DxColumn
                data-field="hidden"
                data-type="number"
                caption="Skriveno"
                :allow-exporting="false"
            />
            <!-- <DxColumn
                data-field="itemGroupId"
                data-type="number"
                caption="Grupa"
            /> -->
            <DxColumn data-field="code" data-type="string" caption="Šifra" />
            <DxColumn data-field="name" data-type="string" caption="Naziv" />
            <DxColumn
                data-field="stockNumber"
                data-type="string"
                caption="Kataloški broj"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="foreignName"
                data-type="string"
                caption="Strani naziv"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="ean"
                data-type="string"
                caption="EAN"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="customsNumber"
                data-type="string"
                caption="Carinski broj"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="measureUnit"
                data-type="string"
                caption="JMJ"
            />
            <DxColumn
                data-field="vat"
                data-type="number"
                caption="PDV"
                :allow-editing="false"
                :allow-exporting="false"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="itemType"
                data-type="string"
                caption="Roba/Usluga"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="returnPackagingQty"
                data-type="number"
                caption="Povratna ambalaža"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="orderedQty"
                data-type="number"
                caption="Naručeno"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="minStock"
                data-type="number"
                caption="Min. zaliha"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="maxStock"
                data-type="number"
                caption="Max. zaliha"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="latestProcurementPrice"
                data-type="number"
                caption="Zadnja nabavna"
                :allow-exporting="false"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="priceCalculationType"
                data-type="string"
                caption="Izračun cijene"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="wholesalePrice"
                data-type="number"
                caption="Veleprodajna"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="vatAmount"
                data-type="number"
                caption="Iznos PDV-a"
                :allow-exporting="false"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="retailPrice"
                data-type="number"
                caption="Maloprodajna"
                :allow-exporting="false"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="margin"
                data-type="number"
                caption="Marža"
                :allow-exporting="false"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="supplierId"
                data-type="number"
                caption="Dobavljač"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="supplier"
                    value-expr="id"
                    :displayExpr="supplierName"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListId"
                data-type="number"
                caption="Cijenik"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="priceList"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="edit"
                    @click="editPriceListItem"
                    :visible="areEditAndDeleteIconsVisible"
                />
                <!-- <DxButton
                    hint="Obriši"
                    icon="trash"
                    @click="deletePriceListItem"
                    :visible="areEditAndDeleteIconsVisible"
                /> -->
                <DxButton
                    hint="Spremi"
                    icon="check"
                    @click="savePriceListItem"
                    :visible="areSaveAndCancelIconsVisible"
                />
                <DxButton
                    hint="Odustani"
                    icon="close"
                    @click="cancelPriceListItem"
                    :visible="areSaveAndCancelIconsVisible"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxItem,
    DxForm,
    DxPopup,
    DxExport,
    DxColumn,
    DxButton,
    DxLookup,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxRequiredRule,
    DxStateStoring,
    DxColumnFixing,
    DxHeaderFilter,
    DxColumnChooser,
    DxColumnChooserSelection,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";

import { usePriceListItem } from "@/composables/usePriceListItem";
import { ref, watch } from "vue";

// //Utils
import eventBus from "../../eventBus.js";

export default {
    name: "priceListItemTableComponent",
    components: {
        DxItem,
        DxForm,
        DxPopup,
        DxColumn,
        DxExport,
        DxButton,
        DxLookup,
        DxEditing,
        DxToolbar,
        DxDataGrid,
        DxScrolling,
        DxSearchPanel,
        DxColumnFixing,
        DxStateStoring,
        DxHeaderFilter,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection,
    },
    setup() {
        //composable
        const {
            vat,
            supplier,
            vatAmount,
            priceList,
            domainUser,
            retailPrice,
            priceListItem,
            hiddenOptions,
            priceListData,
            wholesalePrice,
            itemTypeOptions,
            updateRetailPrice,
            columnChooserMode,
            updateWholesalePrice,
            getPriceListDataByKey,
            supplierSelectBoxOptions,
            handlePriceListExporting,
            priceCalculationTypeOptions,
            handleNewRowInitPriceListItem,
            handleRowUpdatingPriceListItem,
            handleEditingStartPriceListItem,
            handleRowInsertingPriceListItem,
        } = usePriceListItem();
        //Data
        const shouldShowTheDataGrid = ref(true);
        const focusedId = ref(false);
        const focusedRowId = ref(null);

        //Instances
        const tableInstance = ref(null);
        const vatAmountInstance = ref(null);
        const wholesalePriceInstance = ref(null);
        const retailPriceInstance = ref(null);

        //button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addNewPriceListItem();
            },
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });

        //form item options
        const vatAmountOptions = {
            value: vatAmount.value,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                vatAmountInstance.value = e.component;
            },
        };
        const wholesalePriceOptions = {
            value: wholesalePrice.value,
            format: "###,###,###,##0.00",
            // format: { style: 'currency'},
            onInitialized: (e) => {
                wholesalePriceInstance.value = e.component;
                wholesalePriceInstance.value.option(
                    "value",
                    wholesalePrice.value
                );
            },
            onValueChanged: (e) =>
                updateWholesalePrice(
                    e,
                    wholesalePriceInstance.value,
                    retailPriceInstance.value,
                    vatAmountInstance.value
                ),
        };
        const retailPriceOptions = {
            value: retailPrice.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                retailPriceInstance.value = e.component;
                retailPriceInstance.value.option("value", retailPrice.value);
            },
            onValueChanged: (e) =>
                updateRetailPrice(
                    e,
                    retailPriceInstance.value,
                    wholesalePriceInstance.value,
                    vatAmountInstance.value
                ),
        };

        //instance
        const getPriceListItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        const addNewPriceListItem = () => {
            tableInstance.value.addRow();
        };
        const editPriceListItem = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelPriceListItem = () => {
            tableInstance.value.cancelEditData();
        };
        const deletePriceListItem = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowPriceListItem = (e) => {
            handleNewRowInitPriceListItem(e, focusedRowId.value);
        };
        const savePriceListItem = () => {
            tableInstance.value.saveEditData();
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
        };
        const onRowInsertingPriceListItem = (e) => {
            handleRowInsertingPriceListItem(e);
        };
        const onRowInsertedPriceListItem = () => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
        };
        const onEditingStartPriceListItem = (e) => {
            handleEditingStartPriceListItem(e, tableInstance.value);
        };
        const onRowUpdatingPriceListItem = (e) => {
            handleRowUpdatingPriceListItem(e);
        };
        const onRowUpdatedPriceListItem = () => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
        };
        const onRowRemovedPriceListItem = () => {
            eventBus.emit("newFocusedRowPriceList", focusedRowId.value);
        };
        const onExporting = (e) => {
            handlePriceListExporting(e);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const exportToPdf = () => {
            tableInstance.value.exportPDF();
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        eventBus.on("newFocusedRowPriceList", async (id) => {
            if (id !== null) {
                await getPriceListDataByKey(id);
                focusedId.value = true;
                focusedRowId.value = id;
            } else {
                focusedId.value = false;
                focusedRowId.value = null;
            }
            tableInstance.value.refresh();
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        const supplierName = (item) => {
            return `${item.companyName}`;
        };
        const areEditAndDeleteIconsVisible = (e) => {
            return !e.row.isEditing ? true : false;
        };
        const areSaveAndCancelIconsVisible = (e) => {
            return e.row.isEditing ? true : false;
        };
        const formatedNumber = () => {
            // console.log(item);
        };

        return {
            formatedNumber,
            vat,
            supplier,
            priceList,
            vatAmount,
            domainUser,
            exportToPdf,
            onExporting,
            refreshData,
            retailPrice,
            supplierName,
            priceListItem,
            hiddenOptions,
            priceListData,
            wholesalePrice,
            itemTypeOptions,
            vatAmountOptions,
            addButtonOptions,
            openColumnChooser,
            columnChooserMode,
            editPriceListItem,
            savePriceListItem,
            retailPriceOptions,
            cancelPriceListItem,
            addNewPriceListItem,
            deletePriceListItem,
            refreshButtonOptions,
            shouldShowTheDataGrid,
            wholesalePriceOptions,
            supplierSelectBoxOptions,
            onInitNewRowPriceListItem,
            onRowUpdatedPriceListItem,
            onRowRemovedPriceListItem,
            columnChooserButtonOptions,
            onRowInsertedPriceListItem,
            onRowUpdatingPriceListItem,
            onRowInsertingPriceListItem,
            priceCalculationTypeOptions,
            onEditingStartPriceListItem,
            areEditAndDeleteIconsVisible,
            areSaveAndCancelIconsVisible,
            getPriceListItemTableInstance,
        };
    },
    // created() {
    //     this.supplierSelectBoxOptions = {
    //         dataSource: supplier,
    //         valueExpr: "id",
    //         searchEnabled: true,
    //         searchMode: "contains",
    //         placeholder: "Odaberi dobavljača",
    //     };
    // },
    // data() {
    //     return {
    //         //Stores
    //         supplier: supplier,
    //         priceList: priceList,
    //         administrator: loggedUser,
    //         priceListItem: priceListItem,

    //         //Instances
    //         vatAmountInstance: null,
    //         retailPriceInstance: null,
    //         priceListTableInstance: null,
    //         wholesalePriceInstance: null,

    //         //Options
    //         hiddenOptions: null,
    //         itemTypeOptions: null,
    //         vatAmountOptions: null,
    //         retailPriceOptions: null,
    //         wholesalePriceOptions: null,
    //         supplierSelectBoxOptions: null,
    //         priceCalculationTypeOptions: null,

    //         //Data
    //         vat: 25,
    //         vatAmount: 0,
    //         adminId: null,
    //         domainId: null,
    //         retailPrice: null,
    //         priceListData: null,
    //         businessYearId: null,
    //         wholesalePrice: null,
    //         columnChooserMode: "select",
    //         itemTypes: [{ value: "Roba" }, { value: "Usluga" }],
    //         priceCalculationTypes: [{ value: "Fiksna cijena" }],
    //         hiddenTypes: [
    //             { value: 0, name: "Ne" },
    //             { value: 1, name: "Da" },
    //         ],

    //         //General data
    //         poppins: poppins,
    //         focusedRowId: null,
    //         isFocusedRow: false,
    //         shouldShowTheDataGrid: true,
    //         preventRecursiveUpdate: false,

    //         //Button options
    //         addButtonOptions: {
    //             icon: "add",
    //             onClick: () => {
    //                 this.addNew();
    //             },
    //         },
    //         refreshButtonOptions: {
    //             icon: "refresh",
    //             onClick: () => {
    //                 this.refreshData();
    //             },
    //         },
    //         columnChooserButtonOptions: {
    //             icon: "columnchooser",
    //             onClick: () => {
    //                 this.openColumnChooser();
    //             },
    //         },
    //     };
    // },
    // created() {
    //     this.adminId = localStorage.getItem("userId");
    //     this.businessYearId = localStorage.getItem("businessYearId");
    //     this.domainId = localStorage.getItem("userDomainId");
    //     this.filterPriceListItem();
    //     this.supplierSelectBoxOptions = {
    //         dataSource: this.supplier,
    //         valueExpr: "id",
    //         searchEnabled: true,
    //         searchMode: "contains",
    //         placeholder: "Odaberi dobavljača",
    //     };
    //     this.itemTypeOptions = {
    //         items: this.itemTypes,
    //         displayExpr: "value",
    //         valueExpr: "value",
    //         placeholder: "Izaberi tip stavke",
    //     };
    //     this.priceCalculationTypeOptions = {
    //         items: this.priceCalculationTypes,
    //         displayExpr: "value",
    //         valueExpr: "value",
    //         placeholder: "Izaberi tip cijene",
    //     };
    //     this.hiddenOptions = {
    //         dataSource: this.hiddenTypes,
    //         displayExpr: "name",
    //         valueExpr: "value",
    //     };
    //     this.vatAmountOptions = {
    //         value: this.vatAmount,
    //         readOnly: true,
    //         onInitialized: (e) => {
    //             this.vatAmountInstance = e.component;
    //         },
    //     };
    //     this.wholesalePriceOptions = {
    //         value: this.wholesalePrice,
    //         onInitialized: (e) => {
    //             this.wholesalePriceInstance = e.component;
    //         },
    //         onValueChanged: this.updateWholesalePrice,
    //     };
    //     this.retailPriceOptions = {
    //         value: this.retailPrice,
    //         onInitialized: (e) => {
    //             this.retailPriceInstance = e.component;
    //         },
    //         onValueChanged: this.updateRetailPrice,
    //     };
    // },
    // watch: {
    //     isFocusedRow() {
    //         if (this.isFocusedRow) this.shouldShowTheDataGrid = true;
    //         else this.shouldShowTheDataGrid = false;
    //     },
    // },
    // methods: {
    //     //Instances
    //     getPriceListItemTableInstance(e) {
    //         this.priceListTableInstance = e.component;
    //         if (!this.isFocusedRow) this.shouldShowTheDataGrid = false;
    //     },

    //     //price list item table methods
    //     filterPriceListItem() {
    //         eventBus.on("newFocusedRowPriceList", async (id) => {
    //             // console.log(id);
    //             if (id != null) {
    //                 this.priceListData = await this.priceList.byKey(id);
    //                 // console.log(this.priceListData);
    //                 this.isFocusedRow = true;
    //                 this.focusedRowId = id;
    //             } else {
    //                 this.isFocusedRow = false;
    //                 this.focusedRowId = null;
    //             }
    //             this.priceListTableInstance.refresh();
    //         });
    //     },
    //     addNew() {
    //         this.priceListTableInstance.addRow();
    //     },
    //     editPriceListItem(e) {
    //         this.priceListTableInstance.editRow(e.row.rowIndex);
    //     },
    //     cancel() {
    //         this.priceListTableInstance.cancelEditData();
    //     },
    //     deletePriceListItem(e) {
    //         this.priceListTableInstance.deleteRow(e.row.rowIndex);
    //     },
    //     onInitNewRow(e) {
    //         e.data = {
    //             hidden: 0,
    //             vat: 25,
    //             priceListId: this.focusedRowId,
    //             priceCalculationType: "Fiksna cijena",
    //         };
    //     },
    //     savePriceListItem() {
    //         this.priceListTableInstance.saveEditData();
    //         eventBus.emit("newFocusedRowPriceList", this.focusedRowId);
    //     },
    //     onRowInserting(e) {
    //         const now = new Date();
    //         e.data = {
    //             ...e.data,
    //             wholesalePrice: this.wholesalePrice,
    //             retailPrice: this.retailPrice,
    //             createdById: Number(this.adminId),
    //             modifiedById: Number(this.adminId),
    //             createdDate: now.toISOString(),
    //             modifiedDate: now.toISOString(),
    //             domainId: Number(this.domainId),
    //         };
    //     },
    //     onRowInserted() {
    //         eventBus.emit("newFocusedRowPriceList", this.focusedRowId);
    //     },
    //     onEditingStart(e) {
    //         const now = new Date();
    //         setTimeout(() => {
    //             this.priceListTableInstance.cellValue(
    //                 e.component.getRowIndexByKey(e.key),
    //                 "modifiedDate",
    //                 now.toISOString()
    //             );
    //         }, 100);
    //     },
    //     onRowUpdating(e) {
    //         const now = new Date();
    //         e.newData = {
    //             ...e.newData,
    //             modifiedById: Number(this.adminId),
    //             modifiedDate: now.toISOString(),
    //         };
    //         if (this.retailPrice !== null) {
    //             e.newData.retailPrice = this.retailPrice;
    //         }
    //         if (this.wholesalePrice !== null) {
    //             e.newData.wholesalePrice = this.wholesalePrice;
    //         }
    //     },
    //     onRowUpdated() {
    //         eventBus.emit("newFocusedRowPriceList", this.focusedRowId);
    //     },
    //     onRowRemoved() {
    //         eventBus.emit("newFocusedRowPriceList", this.focusedRowId);
    //     },
    //     onExporting() {
    //         const doc = new jsPDF();
    //         doc.addFileToVFS("@/assets/Poppins-Regular.ttf", this.poppins);
    //         doc.addFont(
    //             "@/assets/Poppins-Regular.ttf",
    //             "Poppins-Regular",
    //             "normal"
    //         );
    //         doc.setFont("Poppins-Regular");
    //         doc.setFontSize(8);
    //         const lastPoint = { x: 0, y: 0 };
    //         doc.setTextColor(0, 0, 0);

    //         exportPDF({
    //             jsPDFDocument: doc,
    //             component: this.priceListTableInstance,
    //             topLeft: { x: 1, y: 5 },
    //             repeatHeaders: true,
    //             columnWidths: [15, 118, 20, 27],
    //             onRowExporting: (e) => {
    //                 e.rowHeight = 6;
    //             },
    //             customDrawCell({ rect, gridCell, pdfCell }) {
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.font.size = 8;
    //                 } else if (gridCell.rowType === "data") {
    //                     pdfCell.font.size = 7;
    //                 } else if (gridCell.rowType === "totalFooter") {
    //                     pdfCell.font.style = "normal";
    //                 }
    //                 if (lastPoint.x < rect.x + rect.w) {
    //                     lastPoint.x = rect.x + rect.w;
    //                 }
    //                 if (lastPoint.y < rect.y + rect.h) {
    //                     lastPoint.y = rect.y + rect.h;
    //                 }
    //             },
    //             customizeCell({ gridCell, pdfCell }) {
    //                 pdfCell.wordWrapEnabled = true;
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.textColor = "#000000";
    //                     pdfCell.backgroundColor = "#D3D3D3";
    //                     pdfCell.font.size = 7;
    //                 } else if (gridCell.rowType === "totalFooter") {
    //                     pdfCell.font.size = 7;
    //                 }
    //             },
    //         }).then(() => {
    //             const now = new Date();
    //             const vrijeme =
    //                 "Vrijeme izrade: " + now.toLocaleString("hr-HR");
    //             const fileData = `Cijenik: ${this.priceListData.name}`;

    //             const pageWidth = doc.internal.pageSize.getWidth();
    //             doc.setFontSize(12);
    //             doc.setPage(1);
    //             doc.setFontSize(8);
    //             doc.text(vrijeme, pageWidth - 15, 15, { align: "right" });
    //             doc.setFontSize(13);
    //             doc.text(fileData, 15, 15, { align: "left" });
    //             doc.setFontSize(8);
    //             doc.line(15, 17, pageWidth - 15, 17);
    //             doc.save(`Cijenik_${this.priceListData.name}.pdf`);
    //         });
    //     },

    //     //Button methods
    //     refreshData() {
    //         const message = "Podaci osvježeni!";
    //         this.priceListTableInstance.refresh();
    //         notify({ message, width: 450 }, "success");
    //     },
    //     exportToPdf() {
    //         this.priceListTableInstance.exportPDF();
    //     },
    //     openColumnChooser() {
    //         this.priceListTableInstance.showColumnChooser();
    //     },

    //     //General methods
    //     updateRetailPrice(e) {
    //         if (!this.preventRecursiveUpdate) {
    //             this.preventRecursiveUpdate = true;
    //             this.retailPrice = e.value;
    //             this.retailPriceInstance.option("value", this.retailPrice);
    //             this.wholesalePrice = this.retailPrice * 0.75;
    //             this.wholesalePriceInstance.option(
    //                 "value",
    //                 this.retailPrice * 0.75
    //             );
    //             this.vatAmount =
    //                 this.retailPrice -
    //                 this.wholesalePriceInstance.option("value");
    //             this.vatAmountInstance.option("value", this.vatAmount);

    //             this.preventRecursiveUpdate = false;
    //         }
    //     },
    //     updateWholesalePrice(e) {
    //         if (!this.preventRecursiveUpdate) {
    //             this.preventRecursiveUpdate = true;
    //             this.wholesalePrice = e.value;
    //             this.wholesalePriceInstance.option(
    //                 "value",
    //                 this.wholesalePrice
    //             );
    //             this.retailPrice = this.wholesalePrice * (1 + this.vat / 100);
    //             this.retailPriceInstance.option(
    //                 "value",
    //                 this.wholesalePrice * (1 + this.vat / 100)
    //             );
    //             this.vatAmount =
    //                 this.retailPriceInstance.option("value") -
    //                 this.wholesalePrice;
    //             this.vatAmountInstance.option("value", this.vatAmount);

    //             this.preventRecursiveUpdate = false;
    //         }
    //     },
    //     supplierName(item) {
    //         return `${item.name} ${item.lastname}`;
    //     },
    //     areEditAndDeleteIconsVisible(e) {
    //         return !e.row.isEditing ? true : false;
    //     },
    //     areSaveAndCancelIconsVisible(e) {
    //         return e.row.isEditing ? true : false;
    //     },
    // },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#priceListItemTable {
    max-width: 100%;
}
</style>
