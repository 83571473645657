import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const domainUser = new CustomStore({
    key: "id",
    load: async () => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}`,
                "POST",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DOMAIN_USER}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    },
});
