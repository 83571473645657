<template>
    <div class="container-general">
        <DxToolbar id="toolbar" :items="toolbarContent" />
        <!-- <nav-bar-component /> -->
        <DxDrawer
            :opened-state-mode="drawerMode"
            position="left"
            reveal-mode="slide"
            :opened="openState"
            template="listMenu"
        >
            <template #listMenu>
                <navigationList />
            </template>

            <div class="table-container">
                <div class="table">
                    <customer-table-component
                        v-if="tableToRender === 'Kupci'"
                    />
                    <supplier-table-component
                        v-if="tableToRender === 'Dobavljači'"
                    />
                    <warehouse-table-component
                        v-if="tableToRender === 'Skladište'"
                    />
                    <price-list-table-component
                        v-if="tableToRender === 'Cijenik'"
                    />
                    <dispatch-note-table-component
                        v-if="tableToRender === 'Otpremnica'"
                    />
                    <receipt-table-component
                        v-if="tableToRender === 'Primka'"
                    />
                    <initial-state-table-component
                        v-if="tableToRender === 'Početno stanje'"
                    />
                    <intermediate-warehouse-table-component
                        v-if="tableToRender === 'Međuskladišnica'"
                    />
                    <offer-table-component v-if="tableToRender === 'Ponuda'" />
                    <invoice-table-component v-if="tableToRender === 'Račun'" />
                    <settings-component v-if="tableToRender === 'Postavke'" />
                    <dashboard-component v-if="tableToRender === 'Home'" />
                </div>
                <div
                    class="split"
                    v-if="
                        tableToRender !== 'Dobavljači' &&
                        tableToRender !== '' &&
                        tableToRender !== 'Postavke'
                    "
                ></div>
                <div class="table">
                    <warehouse-item-status-table-component
                        v-if="tableToRender === 'Skladište'"
                    />
                    <price-list-item-table-component
                        v-if="tableToRender === 'Cijenik'"
                    />
                    <dispatch-note-item-table-component
                        v-if="tableToRender === 'Otpremnica'"
                    />
                    <receipt-item-table-component
                        v-if="tableToRender === 'Primka'"
                    />
                    <initial-state-item-table-component
                        v-if="tableToRender === 'Početno stanje'"
                    />
                    <intermediate-warehouse-item-table-component
                        v-if="tableToRender === 'Međuskladišnica'"
                    />
                    <offer-item-table-component
                        v-if="tableToRender === 'Ponuda'"
                    />
                    <invoice-item-table-component
                        v-if="tableToRender === 'Račun'"
                    />
                </div>
            </div>
        </DxDrawer>
    </div>
</template>

<script>
// Other components
import DxDrawer from "devextreme-vue/drawer";
import eventBus from "../../eventBus.js";
import DxToolbar from "devextreme-vue/toolbar";

// import navBarComponent from "@/components/navBarComponent.vue";
import settingsComponent from "@/components/settingsComponent.vue";
import dashboardComponent from "@/components/dashboardComponent.vue";
import navigationList from "@/components/navigationList.vue";

// Table components
import offerTableComponent from "@/components/offerTableComponent.vue";
import receiptTableComponent from "@/components/receiptTableComponent.vue";
import invoiceTableComponent from "@/components/invoiceTableComponent.vue";
import supplierTableComponent from "@/components/supplierTableComponent.vue";
import customerTableComponent from "@/components/customerTableComponent.vue";
import warehouseTableComponent from "@/components/warehouseTableComponent.vue";
import priceListTableComponent from "@/components/priceListTableComponent.vue";
import offerItemTableComponent from "@/components/offerItemTableComponent.vue";
import invoiceItemTableComponent from "@/components/invoiceItemTableComponent.vue";
import receiptItemTableComponent from "@/components/receiptItemTableComponent.vue";
import dispatchNoteTableComponent from "@/components/dispatchNoteTableComponent.vue";
import initialStateTableComponent from "@/components/initialStateTableComponent.vue";
import priceListItemTableComponent from "@/components/priceListItemTableComponent.vue";
import dispatchNoteItemTableComponent from "@/components/dispatchNoteItemTableComponent.vue";
import initialStateItemTableComponent from "@/components/initialStateItemTableComponent.vue";
import warehouseItemStatusTableComponent from "@/components/warehouseItemStatusTableComponent.vue";
import intermediateWarehouseTableComponent from "@/components/intermediateWarehouseTableComponent.vue";
import intermediateWarehouseItemTableComponent from "@/components/intermediateWarehouseItemTableComponent.vue";

export default {
    name: "generalView",
    components: {
        DxToolbar,
        DxDrawer,
        navigationList,
        // navBarComponent,
        settingsComponent,
        dashboardComponent,
        offerTableComponent,
        invoiceTableComponent,
        receiptTableComponent,
        supplierTableComponent,
        customerTableComponent,
        warehouseTableComponent,
        priceListTableComponent,
        offerItemTableComponent,
        receiptItemTableComponent,
        invoiceItemTableComponent,
        initialStateTableComponent,
        dispatchNoteTableComponent,
        priceListItemTableComponent,
        dispatchNoteItemTableComponent,
        initialStateItemTableComponent,
        warehouseItemStatusTableComponent,
        intermediateWarehouseTableComponent,
        intermediateWarehouseItemTableComponent,
    },
    data() {
        return {
            tableToRender: "Home",
            toolbarContent: null,
            openState: true,
            adminUsername: null,
            drawerMode: "shrink",
        };
    },
    mounted() {
        this.handleResize(); // Initialize the correct state
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    created() {
        this.whatTableToRender();
        this.adminUsername = localStorage.getItem("username");
        this.toolbarContent = [
            {
                widget: "dxButton",
                location: "before",
                options: {
                    icon: "menu",
                    stylingMode: "text",
                    onClick: () => {
                        this.openState = !this.openState;
                    },
                },
            },
        ];
    },
    methods: {
        handleResize() {
            // Adjust the mode based on screen width
            this.drawerMode = window.innerWidth <= 1024 ? "overlap" : "shrink";
        },
        whatTableToRender() {
            eventBus.on("tableToRender", (data) => {
                this.tableToRender = data;
                if (this.drawerMode === "overlap") {
                    this.openState = false;
                }
            });
        },
    },
};
</script>
<style scoped>
.container-general {
    display: flex;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/background.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* This line keeps the background fixed */
}
.table-container {
    margin: 2vh;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 100vh;
}
@media screen and (max-width: 1024px) {
    .table-container {
        margin-top: 30px;
    }
}
.table {
    max-width: 100vw;
    /* max-height: 50vh; */
    text-align: center;
    overflow: auto;
    max-height: 100vh;
}

.split {
    border: 0.2px solid #1c1c1c;
    /* width: 84.5vw; */
    max-width: 100vw;
}
#toolbar {
    background-color: var(--dx-component-color-bg);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    padding: 5px 10px;
    z-index: 10000;
    position: fixed;
}
</style>
