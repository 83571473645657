// import { dispatchNote } from "@/stores/dispatchNoteStore";
import { warehouseItemStatus } from "@/stores/warehouseItemStatusStore";
import { warehouseItemStatusPriceAverage } from "@/stores/warehouseItemStatusPriceAverageStore";

export async function updateWarehouseItemStatusOnNewDispatchNote(
    item,
    warehouseId
) {
    // console.log("UPDATING WAREHOUSE ITEM STATUS ON NEW DISPATCH NOTE");
    const loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: warehouseId,
    };
    const res = await warehouseItemStatus.load(loadOptions);
    if (res.length > 0) {
        const updateData = {
            qty: res[0].qty - item.qty,
        };
        warehouseItemStatus.update(res[0].id, updateData);
    } else {
        const newData = {
            priceListItemId: item.priceListItemId,
            priceLatest: item.price,
            priceAverage: item.price,
            qty: -item.qty,
            warehouseId: warehouseId,
        };
        warehouseItemStatus.insert(newData);
    }
}
export async function updateWarehouseItemStatusOnUpdatedDispatchNote(
    warehouseId,
    newEditingDispatchNoteItemWarehouseItemStatus,
    editingDispatchNoteItemOldData,
    dispatchNoteItemData
) {
    if (newEditingDispatchNoteItemWarehouseItemStatus.length > 0) {
        for (
            let i = 0;
            i < newEditingDispatchNoteItemWarehouseItemStatus.length;
            i++
        ) {
            const el = newEditingDispatchNoteItemWarehouseItemStatus[i];

            const element = editingDispatchNoteItemOldData.find((x) => {
                return x.priceListItemId === el.priceListItemId;
            });

            const loadOptions = {
                priceListItemId: el.priceListItemId,
                warehouseId: warehouseId,
            };
            const res = await warehouseItemStatus.load(loadOptions);
            const newQty = el.qty - element.qty;
            const updateData = {};
            if (newQty < 0) {
                updateData.qty = res[0].qty + Math.abs(newQty);
            } else {
                updateData.qty = res[0].qty - Math.abs(newQty);
            }

            await warehouseItemStatus.update(res[0].id, updateData);
        }
    }
    const filteredNestedTableData = [];
    for (let i = 0; i < dispatchNoteItemData.length; i++) {
        const element = dispatchNoteItemData[i];
        const loadOptions = {
            priceListItemId: element.priceListItemId,
            warehouseId: warehouseId,
        };
        const res = await warehouseItemStatus.load(loadOptions);
        if (
            !newEditingDispatchNoteItemWarehouseItemStatus.includes(element) &&
            res.length === 0
        ) {
            filteredNestedTableData.push(element);
        } else if (
            !newEditingDispatchNoteItemWarehouseItemStatus.includes(element) &&
            res.length === 1
        ) {
            const newQty = res[0].qty - element.qty;
            const updateData = {
                qty: newQty,
            };
            await warehouseItemStatus.update(res[0].id, updateData);
        }
    }
    if (filteredNestedTableData.length > 0) {
        for (let i = 0; i < filteredNestedTableData.length; i++) {
            const element = filteredNestedTableData[i];
            const newData = {
                priceListItemId: element.priceListItemId,
                priceLatest: element.price,
                priceAverage: element.price,
                qty: -element.qty,
                warehouseId: warehouseId,
            };
            await warehouseItemStatus.insert(newData);
        }
    }
}
export async function updateWarehouseItemStatusOnRemovedDispatchNote(
    item,
    warehouseId
) {
    // console.log("UPDATE WAREHOUSE ITEM STATUS ON REMOVED DISPATCH NOTE");
    const loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: warehouseId,
    };
    const res = await warehouseItemStatus.load(loadOptions);
    const updateData = {
        qty: res[0].qty + item.qty,
    };
    warehouseItemStatus.update(res[0].id, updateData);
}

export async function updateWarehouseItemStatusOnNewReceipt(
    item,
    warehouseId,
    domainId
) {
    // console.log("UPDATE WAREHOUSE ITEM STATUS ON NEW RECEIPT");
    const loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: warehouseId,
    };
    const res = await warehouseItemStatus.load(loadOptions);
    if (res.length > 0) {
        const updateData = {
            qty: res[0].qty + item.qty,
            priceLatest: item.price,
        };

        await Promise.all([
            warehouseItemStatus.update(res[0].id, updateData),
            warehouseItemStatusPriceAverage.update({
                pricelistItemID: item.priceListItemId,
                domainID: Number(domainId),
            }),
        ]);
    } else {
        const newData = {
            priceListItemId: item.priceListItemId,
            priceLatest: item.price,
            // priceAverage: item.price,
            qty: item.qty,
            warehouseId: warehouseId,
        };
        await Promise.all([
            warehouseItemStatus.insert(newData),
            warehouseItemStatusPriceAverage.update({
                pricelistItemID: item.priceListItemId,
                domainID: Number(domainId),
            }),
        ]);
    }
}
export async function updateWarehouseItemStatusOnUpdatedReceipt(
    newEditingReceiptItemWarehouseItemStatus,
    editingReceiptItemOldData,
    receiptItemData,
    warehouseId,
    domainId
) {
    if (newEditingReceiptItemWarehouseItemStatus.length > 0) {
        for (
            let i = 0;
            i < newEditingReceiptItemWarehouseItemStatus.length;
            i++
        ) {
            const el = newEditingReceiptItemWarehouseItemStatus[i];
            const element = editingReceiptItemOldData.find((x) => {
                return x.priceListItemId === el.priceListItemId;
            });
            const loadOptions = {
                priceListItemId: el.priceListItemId,
                warehouseId: warehouseId,
            };
            const res = await warehouseItemStatus.load(loadOptions);
            const newQty = el.qty - element.qty;
            const updateData = {
                qty: res[0].qty + newQty,
                // priceAverage: el.price,
                priceLatest: el.price,
            };
            await warehouseItemStatus.update(res[0].id, updateData);
            // TODO: Update cijene treba hendlati bolje u update-u
            // console.log(el.priceListItemId);
            await warehouseItemStatusPriceAverage.update({
                pricelistItemID: el.priceListItemId,
                domainID: Number(domainId),
            });
        }
    }
    const filteredNestedTableData = [];
    for (let i = 0; i < receiptItemData.length; i++) {
        const element = receiptItemData[i];
        const loadOptions = {
            priceListItemId: element.priceListItemId,
            warehouseId: warehouseId,
        };
        const res = await warehouseItemStatus.load(loadOptions);
        if (
            !newEditingReceiptItemWarehouseItemStatus.includes(element) &&
            res.length === 0
        ) {
            filteredNestedTableData.push(element);
        } else if (
            !newEditingReceiptItemWarehouseItemStatus.includes(element) &&
            res.length === 1
        ) {
            const newQty = res[0].qty + element.qty;
            const updateData = {
                qty: newQty,
                // priceAverage: element.price,
                priceLatest: element.price,
            };
            await warehouseItemStatus.update(res[0].id, updateData);
            await warehouseItemStatusPriceAverage.update({
                pricelistItemID: element.priceListItemId,
                domainID: Number(domainId),
            });
        }
    }
    if (filteredNestedTableData.length > 0) {
        for (let i = 0; i < filteredNestedTableData.length; i++) {
            const element = filteredNestedTableData[i];
            const newData = {
                priceListItemId: element.priceListItemId,
                priceLatest: element.price,
                // priceAverage: element.price,
                qty: element.qty,
                warehouseId: warehouseId,
            };
            await warehouseItemStatus.insert(newData);
            await warehouseItemStatusPriceAverage.update({
                pricelistItemID: element.priceListItemId,
                domainID: Number(domainId),
            });
        }
    }
}
export async function updateWarehouseItemStatusOnRemovedReceipt(
    item,
    warehouseId,
    domainId
) {
    // console.log("UPDATE WAREHOUSE ITEM STATUS ON REMOVED RECEIPT");
    const loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: warehouseId,
    };
    const res = await warehouseItemStatus.load(loadOptions);
    const updateData = {
        qty: res[0].qty - item.qty,
    };
    await Promise.all([
        warehouseItemStatus.update(res[0].id, updateData),
        warehouseItemStatusPriceAverage.update({
            pricelistItemID: item.priceListItemId,
            domainID: Number(domainId),
        }),
    ]);
}

export async function updateWarehouseItemStatusOnNewInitialState(
    item,
    warehouseId
) {
    const loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: warehouseId,
    };
    const res = await warehouseItemStatus.load(loadOptions);
    if (res.length > 0) {
        const updateData = {
            qty:
                item.qty > 0
                    ? res[0].qty + Math.abs(item.qty)
                    : res[0].qty - Math.abs(item.qty),
            priceLatest: item.price,
        };
        await warehouseItemStatus.update(res[0].id, updateData);
    } else {
        const newData = {
            priceListItemId: item.priceListItemId,
            priceLatest: item.price,
            priceAverage: item.price,
            qty: item.qty,
            warehouseId: warehouseId,
        };
        warehouseItemStatus.insert(newData);
    }
}

export async function updateWarehouseItemStatusOnUpdatedInitialState(
    newEditingInitialStateItemWarehouseItemStatus,
    editingInitialStateItemOldData,
    initialStateItemTableData,
    warehouseId
) {
    if (newEditingInitialStateItemWarehouseItemStatus.length > 0) {
        for (
            let i = 0;
            i < newEditingInitialStateItemWarehouseItemStatus.length;
            i++
        ) {
            const el = newEditingInitialStateItemWarehouseItemStatus[i];
            const element = editingInitialStateItemOldData.find((x) => {
                return x.priceListItemId === el.priceListItemId;
            });
            const loadOptions = {
                priceListItemId: el.priceListItemId,
                warehouseId: warehouseId,
            };
            const res = await warehouseItemStatus.load(loadOptions);
            const newQty = el.qty - element.qty;
            const updateData = {
                qty:
                    newQty > 0
                        ? res[0].qty + Math.abs(newQty)
                        : res[0].qty - Math.abs(newQty),
            };
            await warehouseItemStatus.update(res[0].id, updateData);
        }
    }
    for (let i = 0; i < initialStateItemTableData.length; i++) {
        const element = initialStateItemTableData[i];
        const loadOptions = {
            priceListItemId: element.priceListItemId,
            warehouseId: warehouseId,
        };
        const res = await warehouseItemStatus.load(loadOptions);
        if (
            !newEditingInitialStateItemWarehouseItemStatus.includes(element) &&
            res.length === 0
        ) {
            const newData = {
                priceListItemId: element.priceListItemId,
                priceLatest: element.price,
                priceAverage: element.price,
                qty: element.qty,
                warehouseId: warehouseId,
            };
            await warehouseItemStatus.insert(newData);
        } else if (
            !newEditingInitialStateItemWarehouseItemStatus.includes(element) &&
            res.length === 1
        ) {
            const updateData = {
                qty:
                    element.qty > 0
                        ? res[0].qty + Math.abs(element.qty)
                        : res[0].qty - Math.abs(element.qty),
            };
            await warehouseItemStatus.update(res[0].id, updateData);
        }
    }
}

export async function updateWarehouseItemStatusOnRemovedInitialState(
    item,
    warehouseId
) {
    const loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: warehouseId,
    };
    const res = await warehouseItemStatus.load(loadOptions);
    const updateData = {
        qty:
            item.qty > 0
                ? res[0].qty - Math.abs(item.qty)
                : res[0].qty + Math.abs(item.qty),
    };
    await warehouseItemStatus.update(res[0].id, updateData);
}

export async function updateWarehouseItemStatusOnNewIntermediateWarehouse(
    item,
    fromWarehouseId,
    toWarehouseId
) {
    let loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: fromWarehouseId,
    };
    let res = await warehouseItemStatus.load(loadOptions);
    if (res.length > 0) {
        const updateData = {
            qty: res[0].qty - item.qty,
        };
        await warehouseItemStatus.update(res[0].id, updateData);
    } else {
        const newData = {
            priceListItemId: item.priceListItemId,
            priceAverage: item.price,
            priceLatest: item.price,
            qty: -item.qty,
            warehouseId: fromWarehouseId,
        };
        await warehouseItemStatus.insert(newData);
    }
    loadOptions.warehouseId = toWarehouseId;
    res = await warehouseItemStatus.load(loadOptions);
    if (res.length > 0) {
        const updateData = {
            qty: res[0].qty + item.qty,
        };
        await warehouseItemStatus.update(res[0].id, updateData);
    } else {
        const newData = {
            priceListItemId: item.priceListItemId,
            priceLatest: item.price,
            priceAverage: item.price,
            qty: item.qty,
            warehouseId: toWarehouseId,
        };
        await warehouseItemStatus.insert(newData);
    }
}

//TODO: FIX kada se remove-a item sa međuskladišnice ne mijenja status skladišta
export async function updateWarehouseItemStatusOnUpdatedIntermediateWarehouse(
    newEditingIntermediateWarehouseWarehouseItemStatus,
    editingIntermediateWarehouseItemOldData,
    intermediateWarehouseItemData,
    fromWarehouseId,
    toWarehouseId
) {
    if (newEditingIntermediateWarehouseWarehouseItemStatus.length > 0) {
        for (
            let i = 0;
            i < newEditingIntermediateWarehouseWarehouseItemStatus.length;
            i++
        ) {
            const el = newEditingIntermediateWarehouseWarehouseItemStatus[i];
            const element = editingIntermediateWarehouseItemOldData.find(
                (x) => {
                    return x.priceListItemId === el.priceListItemId;
                }
            );
            let loadOptions = {
                priceListItemId: el.priceListItemId,
                warehouseId: toWarehouseId,
            };
            let res = await warehouseItemStatus.load(loadOptions);
            let newQty = el.qty - element.qty;
            const updateData = {};
            if (newQty < 0) {
                updateData.qty = res[0].qty - Math.abs(newQty);
            } else {
                updateData.qty = res[0].qty + Math.abs(newQty);
            }
            await warehouseItemStatus.update(res[0].id, updateData);
            loadOptions.warehouseId = fromWarehouseId;
            res = await warehouseItemStatus.load(loadOptions);
            if (newQty < 0) {
                updateData.qty = res[0].qty + Math.abs(newQty);
            } else {
                updateData.qty = res[0].qty - Math.abs(newQty);
            }
            await warehouseItemStatus.update(res[0].id, updateData);
        }
    }
    for (let i = 0; i < intermediateWarehouseItemData.length; i++) {
        const element = intermediateWarehouseItemData[i];
        let loadOptions = {
            priceListItemId: element.priceListItemId,
            warehouseId: fromWarehouseId,
        };
        let res = await warehouseItemStatus.load(loadOptions);
        if (
            !newEditingIntermediateWarehouseWarehouseItemStatus.includes(
                element
            ) &&
            res.length === 0
        ) {
            const newData = {
                priceListItemId: element.priceListItemId,
                priceLatest: element.price,
                priceAverage: element.price,
                qty: -element.qty,
                warehouseId: fromWarehouseId,
            };
            await warehouseItemStatus.insert(newData);
            loadOptions.warehouseId = toWarehouseId;
            res = await warehouseItemStatus.load(loadOptions);
            if (res.length > 0) {
                const updateData = {
                    qty: res[0].qty + element.qty,
                };
                await warehouseItemStatus.update(res[0].id, updateData);
            } else {
                const newData = {
                    priceListItemId: element.priceListItemId,
                    priceLatest: element.price,
                    priceAverage: element.price,
                    qty: element.qty,
                    warehouseId: toWarehouseId,
                };
                await warehouseItemStatus.insert(newData);
            }
        } else if (
            !newEditingIntermediateWarehouseWarehouseItemStatus.includes(
                element
            ) &&
            res.length === 1
        ) {
            let updateData = {
                qty: res[0].qty - element.qty,
            };
            await warehouseItemStatus.update(res[0].id, updateData);
            loadOptions.warehouseId = toWarehouseId;
            res = await warehouseItemStatus.load(loadOptions);
            if (res.length > 0) {
                updateData = {
                    qty: res[0].qty + element.qty,
                };
                await warehouseItemStatus.update(res[0].id, updateData);
            } else {
                const newData = {
                    priceListItemId: element.priceListItemId,
                    priceLatest: element.price,
                    priceAverage: element.price,
                    qty: element.qty,
                    warehouseId: toWarehouseId,
                };
                await warehouseItemStatus.insert(newData);
            }
        }
    }
}

export async function updateWarehouseItemStatusOnRemovedIntermediateWarehouse(
    item,
    fromWarehouseId,
    toWarehouseId
) {
    let loadOptions = {
        priceListItemId: item.priceListItemId,
        warehouseId: toWarehouseId,
    };
    let res = await warehouseItemStatus.load(loadOptions);
    let updateData = {
        qty: res[0].qty - item.qty,
    };
    await warehouseItemStatus.update(res[0].id, updateData);
    loadOptions.warehouseId = fromWarehouseId;
    res = await warehouseItemStatus.load(loadOptions);
    updateData.qty = res[0].qty + item.qty;
    await warehouseItemStatus.update(res[0].id, updateData);
}
