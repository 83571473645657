import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let filterId = null;
eventBus.on("newFocusedRowPriceList", (id) => {
    filterId = id;
});

export const priceListItem = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            // console.log(filterId);
            const filterCondition = {
                where: {
                    and: [],
                },
            };
            if (loadOptions.skip || loadOptions.take) {
                filterCondition.limit = loadOptions.take + 1;
                filterCondition.skip = loadOptions.skip;
            }
            if (
                filterId === null ||
                loadOptions.searchValue === null ||
                loadOptions.searchValue === ""
            ) {
                if (filterId !== null) {
                    filterCondition.where.and.push({ priceListId: filterId });
                } else {
                    filterCondition.where.and.splice(0, 1);
                }
                if (
                    loadOptions.searchValue !== null &&
                    loadOptions.searchValue !== ""
                ) {
                    filterCondition.where.and.push({
                        name: { regexp: `/${loadOptions.searchValue}/i` },
                    });
                } else {
                    filterCondition.where.and.splice(1, 1);
                }
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.PRICE_LIST_ITEM
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                filterId = null;
                return newData;
            } else {
                const newData = await sendRequest(
                    `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}`
                );
                filterId = null;
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}`,
                "POST",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.PRICE_LIST_ITEM}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    },
});
