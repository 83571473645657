import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { domainUser } from "@/stores/domainUserStore";
import { customer } from "@/stores/customerStore";
import { user } from "@/stores/userStore";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
export function useCustomer() {
    const state = reactive({
        domainUser,
        customer,
        user,
        saveLastData: false,
        lastSavedData: {},
        columnChooserMode: "select",
        customerTypes: [
            { key: "Privatni", value: "private" },
            { key: "Poslovni", value: "business" },
        ],
        billDeliveryMethods: [
            { key: "E-mail", value: "email" },
            { key: "Pošta", value: "post" },
            { key: "E-račun", value: "eracun" },
        ],
        status: [
            { key: "Novi", value: "new" },
            { key: "Aktivan", value: "active" },
            { key: "Neaktivan", value: "inactive" },
            { key: "Prekinut", value: "terminated-ovr" },
        ],
        newsletterOptions: [
            { key: "Da", value: 1 },
            { key: "Ne", value: 0 },
        ],
        domainName: null,
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
    });

    function handleNewRowInitCustomer(e) {
        if (state.saveLastData === true) {
            e.data = {
                ...state.lastSavedData,
            };
            state.saveLastData = false;
        } else {
            e.data = {
                ...e.data,
                billDeliveryMethod: "email",
                status: "active",
                userType: "private",
                newsletter: 1,
            };
        }
    }
    function handleRowInsertingCustomer(e) {
        const now = new Date();
        if (Number(state.domainId) !== 1) {
            e.data = {
                ...e.data,
                dateCreated: now.toISOString(),
                domainId: Number(state.domainId),
            };
        } else {
            e.data = {
                ...e.data,
                dateCreated: now.toISOString(),
            };
        }
        if (state.saveLastData) {
            state.lastSavedData = {
                ...e.data,
            };
        }
        return e.data;
    }
    function handleRowUpdatingCustomer(e) {
        // const now = new Date();
        e.newData = {
            ...e.newData,
        };
        return e.newData;
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.domainId);
        if (domainData !== null) state.domainName = domainData.name;
    }
    function handleExportCustomer(table) {
        const doc = new jsPDF();
        doc.setFontSize(8);
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 10 },
            repeatHeaders: true,
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            columnWidths: [50, 55, 30, 45],
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setFontSize(14);
            doc.setPage(1);
            doc.text(state.domainName, 15, 12);
            doc.text("Kupci", 15, 20);
            doc.setFontSize(8);
            doc.text(vrijeme, pageWidth - 15, 20, { align: "right" });
            doc.line(15, 22, pageWidth - 15, 22);
            const documentName = `Lista-Kupaca-${state.domainName}.pdf`;
            doc.save(documentName);
        });
    }
    return {
        ...toRefs(state),
        handleNewRowInitCustomer,
        handleRowInsertingCustomer,
        handleRowUpdatingCustomer,
        handleExportCustomer,
        getDomainData,
    };
}
