<template>
    <div class="table-container">
        <span class="table-name">Račun</span>
        <DxDataGrid
            id="invoiceTable"
            :data-source="invoice"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getInvoiceTableInstance"
            @init-new-row="onInitNewRowInvoice"
            @row-inserting="onRowInsertingInvoice"
            @row-inserted="onRowInsertedInvoice"
            @editing-start="onEditingStartInvoice"
            @row-updating="onRowUpdatingInvoice"
            @row-updated="onRowUpdatedInvoice"
            @focused-row-changed="onFocusedRowChangedInvoice"
            @edit-canceled="onEditCanceledInvoice"
            edit-template="invoiceEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="invoiceTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="invoiceAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="invoiceRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="invoiceRecapExportButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <!-- <DxItem
                    :options="textBoxOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxTextBox"
                /> -->
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o računu"
                    :toolbar-items="invoicePopupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="string"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>

                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>

                        <DxItem
                            data-field="remark"
                            data-type="string"
                            col-span="2"
                        >
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="userId"
                            data-type="number"
                            name="userName"
                            editor-type="dxSelectBox"
                            :editor-options="userSelectBoxOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem
                            data-field="delay"
                            data-type="number"
                            :editor-options="delayOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="offerId"
                            data-type="string"
                            col-span="2"
                        >
                        </DxItem>
                        <DxItem
                            data-field="paymentMethod"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="paymentMethodSelectBoxOptions"
                        >
                            <DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="dueDate"
                            data-type="date"
                            :editor-options="dueDateSelectOptions"
                            :disabled="true"
                            ><DxRequiredRule
                        /></DxItem>

                        <DxItem
                            data-field="dispatchNoteId"
                            data-type="string"
                            col-span="2"
                        >
                        </DxItem>
                        <DxItem
                            data-field="warehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="warehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem item-type="group" :col-span="1"></DxItem>
                        <DxItem
                            data-field="orderNumber"
                            data-type="string"
                            col-span="1"
                        >
                        </DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                        <!-- <DxItem item-type="group" :col-span="2"></DxItem> -->
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="invoiceItemTable"
                                    :data-source="invoiceItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="getInvoiceItemTableInstance"
                                    @editing-start="onEditingStartInvoiceItem"
                                    @row-inserting="onRowInsertingInvoiceItem"
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="550"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                invoiceItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            99999999999.99
                                                        " /><DxRequiredRule
                                                /></DxItem>

                                                <DxItem
                                                    data-field="discountPercentage"
                                                    data-type="number"
                                                    :editor-options="
                                                        discountPercentageOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            100
                                                        " /><DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="
                                                            99999999999.99
                                                        " /><DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="discountAmount"
                                                    data-type="number"
                                                    :editor-options="
                                                        discountAmountOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99" />
                                                    <DxRequiredRule
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceByQty"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceByQtyOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    :col-count="2"
                                                    :col-span="2"
                                                    item-type="group"
                                                    caption=" "
                                                >
                                                    <DxItem
                                                        data-field="Stanje na skladištu"
                                                        data-type="number"
                                                        :editor-options="
                                                            warehouseStatusOptions
                                                        "
                                                    />
                                                </DxItem>
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        data-field="warehouseId"
                                        data-type="number"
                                        caption="Skladište"
                                        :visible="false"
                                    >
                                        <DxLookup
                                            :data-source="warehouse"
                                            value-expr="id"
                                            display-expr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceByQty"
                                        data-type="number"
                                        caption="Iznos"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="discountAmount"
                                        data-type="number"
                                        caption="Rabat"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="discountPercentage"
                                        data-type="number"
                                        caption="Rab%"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Iznos s rabatom"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editInvoiceItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteInvoiceItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="true"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="number"
                data-type="string"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
                :visible="false"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
                sort-order="desc"
                ><DxHeaderFilter :groupInterval="'month'" />
            </DxColumn>
            <DxColumn
                data-field="delay"
                data-type="number"
                caption="Odgoda"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="userId"
                data-type="number"
                name="userType"
                caption="Vrsta korisnika"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="dataSource"
                    value-expr="id"
                    :displayExpr="userType"
                />
            </DxColumn>
            <DxColumn
                data-field="userId"
                data-type="number"
                caption="Kupac"
                name="userName"
            >
                <DxLookup
                    :data-source="dataSource"
                    value-expr="id"
                    :displayExpr="userName"
                />
            </DxColumn>
            <DxColumn
                data-field="userId"
                data-type="number"
                name="userOIB"
                caption="OIB"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="dataSource"
                    value-expr="id"
                    :displayExpr="userOIB"
                />
            </DxColumn>
            <DxColumn
                data-field="totalAmount"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
                :allow-exporting="false"
            >
            </DxColumn>
            <DxColumn
                data-field="totalDisocuntAmount"
                data-type="number"
                caption="Popust"
                format="###,###,###,##0.00"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="totalAmountDiscounted"
                data-type="number"
                caption="Sa popustom"
                format="###,###,###,##0.00"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="totalTaxAmount"
                data-type="number"
                caption="Porez"
                format="###,###,###,##0.00"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="totalAmountTaxed"
                data-type="number"
                caption="Sa porezom"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="dueDate"
                data-type="date"
                caption="Datum dospijeća"
                format="dd/MM/yyyy"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="delivery"
                data-type="date"
                caption="Datum dostave"
                format="dd/MM/yyyy"
                :allow-exporting="false"
            />
            <DxColumn data-field="status" data-type="string" caption="Status" />
            <DxColumn
                data-field="paymentDate"
                data-type="date"
                caption="Datum plaćanja"
                format="dd/MM/yyyy"
            />
            <DxColumn
                data-field="paymentMethod"
                data-type="string"
                caption="Način plaćanja"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="orderNumber"
                data-type="string"
                caption="Broj narudžbe"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="exchangeRate"
                data-type="number"
                caption="Devizni tečaj"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="workOrder"
                data-type="number"
                caption="Radni nalog"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="returnAmount"
                data-type="number"
                caption="Iznos povrata"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="dispatchNoteId"
                data-type="string"
                caption="Broj otpremnice"
                :customize-text="getDispatchNoteIds"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="offerId"
                data-type="number"
                caption="Broj ponude"
                :visible="false"
                :allow-exporting="false"
            />

            <DxColumn
                data-field="warehouseId"
                data-type="number"
                caption="Skladište"
                :visible="false"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    displayExpr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="remark"
                data-type="string"
                caption="Opis"
                :visible="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
                :allow-exporting="false"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editInvoice"
                />
                <DxButton hint="Dupliciraj" icon="copy" :visible="true" />
                <DxButton
                    hint="Napravi otpremnicu"
                    icon="import"
                    :visible="true"
                    @click="generateDispatchNote"
                />
                <DxButton
                    hint="Izradi PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportTo"
                />
                <DxButton
                    hint="Pošalji"
                    icon="message"
                    :visible="true"
                    @click="sendEmailPopup"
                />
                <DxButton
                    hint="Plaćeno"
                    icon="money"
                    :visible="true"
                    @click="paidInvoice"
                />
                <DxButton
                    hint="Obriši"
                    icon="trash"
                    :visible="true"
                    @click="deleteInvoice"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
        <EmailPopup
            :resize-enabled="true"
            :drag-outside-boundary="true"
            :toolbarItems="emailPopupToolbarItems"
            title="Slanje računa emailom"
            @initialized="getEmailPopupInstance"
            @hiding="cancelEmail"
        >
            <EmailForm
                :form-data="emailFormData"
                @initialized="getEmailFormInstance"
                validationGroup="email"
            >
                <DxItem :col-count="2" :col-span="2" item-type="group">
                    <DxSimpleItem
                        data-field="emailFrom"
                        :editor-options="emailFromOptions"
                        :label="{ text: 'Šalje' }"
                        ><DxRequiredRule
                            message="Email pošiljatelja je obavezan" /><DxEmailRule
                            message="Email pošiljatelja nije ispravan"
                    /></DxSimpleItem>
                    <DxSimpleItem
                        data-field="emailTo"
                        :editor-options="emailToOptions"
                        :label="{ text: 'Prima' }"
                        ><DxRequiredRule
                            message="Email primatelja je obavezan" /><DxEmailRule
                            message="Email primatelja nije ispravan"
                    /></DxSimpleItem>
                </DxItem>
                <DxSimpleItem
                    :col-span="2"
                    data-field="subject"
                    :editor-option="subjectOptions"
                    :label="{ text: 'Subjekt' }"
                    ><DxRequiredRule message="Subjekt je obavezan"
                /></DxSimpleItem>
                <DxSimpleItem
                    :col-span="2"
                    data-field="message"
                    editorType="dxTextArea"
                    :editor-options="messageOptions"
                    :label="{ text: 'Poruka' }"
                    ><DxRequiredRule message="Poruka je obavezna"
                /></DxSimpleItem>
                <DxItem :col-span="2">
                    <template #default>
                        <HtmlButton
                            class="dxbutton-potpis"
                            text="Otključaj potpis"
                            icon="unlock"
                            :visible="signatureStatus"
                            @click="changeSignatureStatus"
                        />
                        <HtmlButton
                            class="dxbutton-potpis"
                            text="Zaključaj potpis"
                            icon="lock"
                            :visible="!signatureStatus"
                            @click="changeSignatureStatus"
                        />
                        <HtmlButton
                            text="Obriši potpis"
                            icon="trash"
                            :disabled="signatureStatus"
                            @click="clearSignature"
                        />
                        <span v-if="!signatureStatus" style="margin-left: 5px">
                            Zaključaj potpis prije slanja kako bi se promjene
                            spremile!</span
                        >
                        <DxHtmlEditor
                            value-type="html"
                            :value="signature"
                            height="725px"
                            class="dxhtmleditor"
                            :read-only="signatureStatus"
                            @initialized="getHtmlEditorInstance"
                            :toolbar="htmlEditorToolbarItems"
                        >
                            <DxMediaResizing :enabled="true" />
                            <DxImageUpload file-upload-mode="base64" />
                        </DxHtmlEditor>
                    </template>
                </DxItem>
            </EmailForm>
        </EmailPopup>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import { DxPopup as EmailPopup } from "devextreme-vue/popup";
import {
    DxForm as EmailForm,
    DxSimpleItem,
    DxEmailRule,
} from "devextreme-vue/form";
import {
    DxHtmlEditor,
    DxMediaResizing,
    DxImageUpload,
} from "devextreme-vue/html-editor";
import "devextreme-vue/text-area";
import { DxButton as HtmlButton } from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import DataSource from "devextreme/data/data_source";
import { useInvoice } from "@/composables/useInvoice.js";
import eventBus from "../../eventBus.js";
import { ref } from "vue";

export default {
    name: "invoiceTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
        EmailPopup,
        EmailForm,
        DxSimpleItem,
        DxHtmlEditor,
        DxMediaResizing,
        DxImageUpload,
        HtmlButton,
        DxEmailRule,
    },
    setup() {
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);

        //items instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const delayInstance = ref(null);
        const numberInstance = ref(null);
        const dueDateInstance = ref(null);
        const warehouseInstance = ref(null);
        const dateSelectInstance = ref(null);
        const priceByQtyInstance = ref(null);
        const priceTotalInstance = ref(null);
        const priceListItemInstance = ref(null);
        const discountAmountInstance = ref(null);
        const warehouseStatusInstance = ref(null);
        const discountPercentageInstance = ref(null);

        // Email
        const subjectInstance = ref(null);
        const emailPopupInstance = ref(null);
        const emailFormInstance = ref(null);
        const emailFromInstance = ref(null);
        const emailToInstance = ref(null);
        const messageOptions = ref({ height: 200 });
        const sendEmailFlag = ref(false);

        //HTML Editor
        const signatureStatus = ref(true);
        const htmlEditorInstance = ref(null);

        //General data
        const qty = ref(1);
        const price = ref(0);
        const priceByQty = ref(0);
        const priceTotal = ref(0);
        const discountAmount = ref(0);
        const discountPercentage = ref(0);
        const number = ref(null);
        const selectedDate = ref(null);
        const selectedExpireDate = ref(null);
        const selectedRowKey = ref(null);
        const selectedWarehouseId = ref(null);
        const priceListItemId = ref(null);
        const delay = ref(0);
        const preventRecursiveUpdate = ref(false);
        const paymentMethods = ref([{ value: "Virman" }, { value: "Kartica" }]);

        const {
            dispatchNote,
            dispatchNoteItem,
            customer,
            user,
            domain,
            invoice,
            warehouse,
            domainUser,
            invoiceItem,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            emailSignatureDefault,
            warehouseItemStatusPriceAverage,
            editingInvoice,
            editingInvoiceItem,
            signature,
            invoiceItemData,
            rowsToBeDeleted,
            invoiceItemsBeforeEdit,
            editingInvoiceItemOldData,
            columnChooserMode,
            userSignature,
            pdfForEmail,
            emailFormData,
            priceTotalFormat,
            adminUsername,
            adminId,
            domainId,
            businessYearId,
            handleRowInsertingInvoice,
            handleRowInsertedInvoice,
            handleEditingStartInvoice,
            handleEditCanceledInvoice,
            handleRowUpdatingInvoice,
            handleCancelInvoiceItem,
            handleDeleteInvoiceItem,
            handleSaveNewInvoiceItem,
            handleRowInsertingInvoiceItem,
            handleEditingStartInvoiceItem,
            handleGenerateDispatchNote,
            handleDeleteInvoice,
            handleSendEmailPopupData,
            handleSendEmail,
            handleExportingInvoiceRecap,
        } = useInvoice();
        const dataSource = Number(domainId.value) === 1 ? user : customer;
        //Button options
        const invoiceAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addInvoice();
            },
        });
        const invoiceItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addInvoiceItem();
            },
        });
        const invoiceRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });
        const invoiceRecapExportButtonOptions = ref({
            icon: "export",
            onClick: () => onExportingInvoiceRecap(),
        });
        //TODO: -> Testing za filter po identu, stavci ili šifri stavke
        // const textBoxOptions = ref({
        //     placeholder: "Search...",
        //     onValueChanged: (e) => {
        //         if (e.value !== "") console.log(e);
        //     },
        // });

        //Toolbar items
        const invoicePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    width: 120,
                    onClick: () => saveNewInvoice(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelInvoice(),
                },
            },
        ]);
        const invoiceItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    width: 120,
                    onClick: () => saveNewInvoiceItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelInvoiceItem(),
                },
            },
        ]);
        const emailPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Pošalji",
                    type: "success",
                    icon: "check",
                    width: 120,
                    useSubmitBehavior: true,
                    onClick: () => sendEmail(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    width: 120,
                    onClick: () => cancelEmail(),
                },
            },
        ]);
        const htmlEditorToolbarItems = ref({
            items: [
                "undo",
                "redo",
                "separator",
                {
                    name: "size",
                    acceptedValues: [
                        "8pt",
                        "10pt",
                        "12pt",
                        "14pt",
                        "18pt",
                        "24pt",
                        "36pt",
                    ],
                },
                {
                    name: "font",
                    acceptedValues: [
                        "Arial",
                        "Georgia",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                    ],
                },
                "separator",
                "bold",
                "italic",
                "strike",
                "underline",
                "separator",
                "alignLeft",
                "alignCenter",
                "alignRight",
                "alignJustify",
                "separator",
                "orderedList",
                "bulletList",
                "separator",
                {
                    name: "header",
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                },
                "separator",
                "color",
                "background",
                "separator",
                "link",
                "image",
                "separator",
                "clear",
                "codeBlock",
                "blockquote",
                "separator",
            ],
            multiline: true,
        });

        //form item options
        const paymentMethodSelectBoxOptions = ref({
            items: paymentMethods.value,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi način plaćanja",
        });
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const dueDateSelectOptions = ref({
            onInitialized: (e) => {
                dueDateInstance.value = e.component;
            },
        });
        const delayOptions = ref({
            onInitialized: (e) => {
                delayInstance.value = e.component;
                delayInstance.value.option("value", delay.value);
            },
            onValueChanged: (e) => {
                delay.value = e.value;
                delayInstance.value.option("value", delay.value);
                const now = new Date();
                const dueDate = new Date(
                    now.setDate(now.getDate() + delay.value)
                );
                dueDateInstance.value.option("value", dueDate.toISOString());
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            // disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: (e) => {
                number.value = e.value;
                numberInstance.value.option("value", number.value);
            },
        });
        const userSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: dataSource.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi kupca",
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
            },
            onSelectionChanged: async (e) => {
                selectedWarehouseId.value = e.selectedItem.id;
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceListItem.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    priceInstance.value.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
                discountAmountInstance.value.option("disabled", false);
                discountPercentageInstance.value.option("disabled", false);
            },
        });
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear,
            valueExpr: "id",
            disabled: true,
        });
        const qtyOptions = ref({
            value: qty.value,
            format: priceTotalFormat.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updatePriceByQty();
            },
        });
        const priceOptions = ref({
            value: price.value,
            format: priceTotalFormat.value,
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updatePriceByQty();
            },
        });
        const priceByQtyOptions = ref({
            value: priceByQty.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                priceByQtyInstance.value = e.component;
            },
        });
        const discountPercentageOptions = ref({
            value: discountPercentage.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                discountPercentageInstance.value = e.component;
                discountPercentageInstance.value.option(
                    "value",
                    discountPercentage.value
                );
            },
            onValueChanged: (e) => updateDiscountPercentage(e),
        });
        const discountAmountOptions = ref({
            value: discountAmount.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                discountAmountInstance.value = e.component;
                discountAmountInstance.value.option(
                    "value",
                    discountAmount.value
                );
            },
            onValueChanged: (e) => updateDiscountAmount(e),
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: priceTotalFormat.value,
            disabled: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });
        const emailFromOptions = ref({
            // disabled: true,
            onInitialized: (e) => {
                emailFromInstance.value = e.component;
            },
        });
        const emailToOptions = ref({
            onInitialized: (e) => {
                emailToInstance.value = e.component;
            },
        });
        const subjectOptions = ref({
            onInitialized: (e) => {
                subjectInstance.value = e.component;
            },
        });

        //instances
        const getInvoiceTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getInvoiceItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };
        const getHtmlEditorInstance = (e) => {
            htmlEditorInstance.value = e.component;
        };
        const getEmailPopupInstance = (e) => {
            emailPopupInstance.value = e.component;
        };
        const getEmailFormInstance = (e) => {
            emailFormInstance.value = e.component;
        };

        //invoice table methods
        const onFocusedRowChangedInvoice = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit("newFocusedRowInvoice", selectedRowKey.value);
                }
            } else {
                eventBus.emit("newFocusedRowInvoice", null);
            }
        };
        const addInvoice = () => {
            tableInstance.value.addRow();
        };
        const editInvoice = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelInvoice = () => {
            tableInstance.value.cancelEditData();
            delay.value = 0;
        };
        const onInitNewRowInvoice = async (e) => {
            const now = new Date();
            const dueDate = new Date(now.setDate(now.getDate() + delay.value));
            const today = new Date(now.setDate(now.getDate()));
            e.data = {
                businessYearId: Number(businessYearId.value),
            };
            priceListItemId.value = null;
            tableInstance.value.option("focusedRowIndex", -1);
            invoiceItemData.value = [];
            setTimeout(() => {
                dateSelectInstance.value.option("value", today.toISOString());
                dueDateInstance.value.option("value", dueDate.toISOString());
            }, 100);
        };
        const saveNewInvoice = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingInvoice = (e) => {
            handleRowInsertingInvoice(
                e,
                selectedDate.value,
                number.value,
                delay.value,
                selectedWarehouseId.value
            );
        };
        const onRowInsertedInvoice = async (e) => {
            await handleRowInsertedInvoice(e);
            qty.value = 1;
            delay.value = 0;
            discountAmount.value = 0;
            discountPercentage.value = 0;
            tableInstance.value.refresh();
        };
        const onEditingStartInvoice = (e) => {
            handleEditingStartInvoice(e);
            const now = new Date();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                dateSelectInstance.value.option("value", e.data.date);
                dueDateInstance.value.option("value", e.data.dueDate);
                delayInstance.value.option("value", e.data.delay);
                numberInstance.value.option("value", e.data.number);
            }, 100);
            number.value = e.data.number;
            priceListItemId.value = null;
        };
        const onEditCanceledInvoice = () => {
            handleEditCanceledInvoice();
            itemTableInstance.value.refresh();
            delay.value = 0;
        };
        const onRowUpdatingInvoice = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            if (selectedExpireDate.value !== e.oldData.expireDate) {
                e.newData.expireDate = selectedExpireDate.value;
            }
            if (delay.value !== e.oldData.delay) {
                e.newData.delay = delay.value;
            }
            if (number.value !== e.oldData.number) {
                e.newData.number = number.value;
            }
            await handleRowUpdatingInvoice(e);
            qty.value = 1;
            delay.value = 0;
            discountAmount.value = 0;
            discountPercentage.value = 0;
            tableInstance.value.refresh();
        };
        const onRowUpdatedInvoice = () => {
            eventBus.emit("newInvoiceItemAdded");
        };
        const onExportingInvoiceRecap = () => {
            handleExportingInvoiceRecap(tableInstance.value);
        };

        //Invoice item table methods
        const addInvoiceItem = () => {
            itemTableInstance.value.addRow();
        };
        const editInvoiceItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelInvoiceItem = () => {
            handleCancelInvoiceItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteInvoiceItem = (e) => {
            handleDeleteInvoiceItem(e);
            itemTableInstance.value.refresh();
        };
        const saveNewInvoiceItem = () => {
            handleSaveNewInvoiceItem(qty.value, price.value, priceTotal.value);
            itemTableInstance.value.saveEditData();
            qty.value = 1;
            discountAmount.value = 0;
            discountPercentage.value = 0;
        };
        const onRowInsertingInvoiceItem = (e) => {
            handleRowInsertingInvoiceItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                discountAmount.value,
                discountPercentage.value,
                priceListItemId.value
            );

            qty.value = 1;
            discountAmount.value = 0;
            discountPercentage.value = 0;
        };
        const onEditingStartInvoiceItem = async (e) => {
            qty.value = e.data.qty;
            handleEditingStartInvoiceItem(e);
            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
            price.value = e.data.price;
            priceListItemId.value = null;
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const generateDispatchNote = async (e) => {
            const res = await handleGenerateDispatchNote(e);
            if (res) {
                notify({ res, width: 450 }, "error");
            } else {
                tableInstance.value.refresh();
                const message = "Otpremnica napravljena!";
                notify({ message, width: 450 }, "success");
            }
        };
        const deleteInvoice = async (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                if (focusedRowKey) {
                    try {
                        const res = await confirm(
                            "Jeste li sigurni da želite promjeniti status u obrisan?",
                            "Upozorenje"
                        );
                        if (res) {
                            await handleDeleteInvoice(e, focusedRowKey);
                            tableInstance.value.refresh();
                            const message = `ID: ${focusedRowKey}, status: "deleted"`;
                            notify({ message, width: 450 }, "success");
                        }
                    } catch (error) {
                        console.error(
                            "Error deleting invoice or dispatch notes:",
                            error
                        );
                        notify(
                            {
                                message:
                                    "Error deleting invoice or dispatch notes.",
                                width: 450,
                            },
                            "error"
                        );
                    }
                }
            }
        };
        const paidInvoice = (e) => {
            if (e.row) {
                const focusedRowKey = e.row.data.id;
                const now = new Date();
                if (focusedRowKey) {
                    let res = confirm(
                        "Jeste li sigurni da želite promjeniti status u plaćen?",
                        "Upozorenje"
                    );
                    res.then((e) => {
                        if (e) {
                            const message = `ID: ${focusedRowKey}, status: "paid"`;
                            notify({ message, width: 450 }, "success");
                            invoice.value.update(focusedRowKey, {
                                status: "paid",
                                paymentDate: now.toISOString(),
                            });
                            tableInstance.value.refresh();
                        }
                    });
                }
            }
        };
        const exportTo = () => {
            const dontChangeStatus = [
                "paid",
                "accepted",
                "rejected",
                "pending",
            ];
            let res = confirm(
                "Sigurno želite ispisati ponudu u obliku PDF-a?",
                "Potvrda"
            );
            res.then(async (e) => {
                if (e) {
                    eventBus.emit(
                        "exportInvoiceItemToPDF",
                        sendEmailFlag.value
                    );
                    const res = await invoice.value.byKey(selectedRowKey.value);
                    if (!dontChangeStatus.includes(res.status)) {
                        const message = `ID: ${selectedRowKey.value}, status: "pending"`;
                        notify({ message, width: 450 }, "success");
                        invoice.value.update(selectedRowKey.value, {
                            status: "pending",
                        });
                        tableInstance.value.refresh();
                    }
                }
            });
        };
        const sendEmailPopup = async () => {
            emailPopupInstance.value.show();
            await handleSendEmailPopupData(selectedRowKey.value);
        };

        //email popup button options
        const cancelEmail = () => {
            if (!sendEmailFlag.value) {
                emailFormData.value = {
                    sourceType: "proforma",
                    sourceId: null,
                    senderId: null,
                    dateSent: null,
                    clientId: null,
                    emailFrom: null,
                    emailTo: null,
                    subject: null,
                    message: null,
                };
            }
            emailPopupInstance.value.hide();
        };
        const changeSignatureStatus = async () => {
            signatureStatus.value = !signatureStatus.value;
            if (htmlEditorInstance.value.option("isDirty")) {
                signature.value = htmlEditorInstance.value.option("value");
                domainUser.value.update(adminId.value, {
                    emailSignature: signature.value,
                });
            }
        };
        const clearSignature = () => {
            signature.value = "";
        };
        const sendEmail = async () => {
            const isValid = emailFormInstance.value.validate("email");
            if (isValid.isValid) {
                const { emailFlag, responseData } = await handleSendEmail(
                    sendEmailFlag.value,
                    selectedRowKey.value
                );
                sendEmailFlag.value = emailFlag;
                notify(
                    responseData.message +
                        "! Status računa promjenjen u - Na čekanju",
                    "success",
                    4000
                );
                emailPopupInstance.value.hide();
            }
        };

        //subscribe to events
        eventBus.on("invoicePdfForEmail", (data) => {
            pdfForEmail.value = data;
        });
        eventBus.on("invoiceItems", (data) => {
            invoiceItemData.value = data;
        });

        //general methods
        const updateDiscountAmount = (e) => {
            if (!preventRecursiveUpdate.value) {
                preventRecursiveUpdate.value = true;
                discountAmount.value = e.value;
                discountAmountInstance.value.option(
                    "value",
                    discountAmount.value
                );
                priceTotal.value = priceByQty.value - discountAmount.value;
                priceTotalInstance.value.option(
                    "value",
                    Number(priceTotal.value)
                );
                discountPercentage.value =
                    priceByQty.value > 0
                        ? (discountAmount.value / priceByQty.value) * 100
                        : 0;
                discountPercentageInstance.value.option(
                    "value",
                    discountPercentage.value
                );
                preventRecursiveUpdate.value = false;
            }
        };
        const updateDiscountPercentage = (e) => {
            if (!preventRecursiveUpdate.value) {
                preventRecursiveUpdate.value = true;
                discountPercentage.value = e.value;
                discountPercentageInstance.value.option(
                    "value",
                    discountPercentage.value
                );
                discountAmount.value =
                    priceByQty.value * (discountPercentage.value / 100);
                discountAmountInstance.value.option(
                    "value",
                    discountAmount.value
                );
                priceTotal.value = priceByQty.value - discountAmount.value;
                priceTotalInstance.value.option(
                    "value",
                    Number(priceTotal.value)
                );
                preventRecursiveUpdate.value = false;
            }
        };
        const updatePriceByQty = () => {
            priceByQtyInstance.value.option("value", qty.value * price.value);
            priceByQty.value = qty.value * price.value;
            priceTotal.value = priceByQty.value - discountAmount.value;
            priceTotalInstance.value.option("value", Number(priceTotal.value));
            discountPercentage.value =
                priceByQty.value > 0
                    ? (discountAmount.value / priceByQty.value) * 100
                    : 0;
            discountPercentageInstance.value.option(
                "value",
                discountPercentage.value
            );
        };
        const userType = (item) => {
            return item.userType === "business" ? "Poslovni" : "Privatni";
        };
        const userOIB = (item) => {
            return item.vatId;
        };
        const userName = (item) => {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        };

        const getDispatchNoteIds = (item) => {
            if (item.value !== undefined && item.value !== null) {
                return item.value.replace(/[[\\]]/g, "");
            }
        };

        return {
            userOIB,
            userType,
            dispatchNote,
            dispatchNoteItem,
            customer,
            dataSource,
            domain,
            invoice,
            warehouse,
            domainUser,
            invoiceItem,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            emailSignatureDefault,
            warehouseItemStatusPriceAverage,
            // textBoxOptions,
            editingInvoice,
            editingInvoiceItem,
            signature,
            invoiceItemData,
            rowsToBeDeleted,
            invoiceItemsBeforeEdit,
            editingInvoiceItemOldData,
            columnChooserMode,
            userSignature,
            pdfForEmail,
            emailFormData,
            priceTotalFormat,
            adminUsername,
            adminId,
            domainId,
            businessYearId,
            messageOptions,
            invoiceAddButtonOptions,
            invoiceItemAddButtonOptions,
            invoiceRefreshButtonOptions,
            columnChooserButtonOptions,
            invoicePopupToolbarItems,
            invoiceItemPopupToolbarItems,
            emailPopupToolbarItems,
            htmlEditorToolbarItems,
            paymentMethodSelectBoxOptions,
            cancelEmail,
            dateSelectOptions,
            dueDateSelectOptions,
            delayOptions,
            warehouseStatusOptions,
            numberOptions,
            userSelectBoxOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceByQtyOptions,
            discountPercentageOptions,
            discountAmountOptions,
            priceTotalOptions,
            emailFromOptions,
            emailToOptions,
            subjectOptions,
            getInvoiceTableInstance,
            getInvoiceItemTableInstance,
            getHtmlEditorInstance,
            getEmailPopupInstance,
            getEmailFormInstance,
            onFocusedRowChangedInvoice,
            editInvoice,
            onInitNewRowInvoice,
            onRowInsertingInvoice,
            onRowInsertedInvoice,
            onEditingStartInvoice,
            onEditCanceledInvoice,
            onRowUpdatingInvoice,
            onRowUpdatedInvoice,
            editInvoiceItem,
            deleteInvoiceItem,
            onRowInsertingInvoiceItem,
            onEditingStartInvoiceItem,
            generateDispatchNote,
            deleteInvoice,
            paidInvoice,
            exportTo,
            sendEmailPopup,
            changeSignatureStatus,
            signatureStatus,
            clearSignature,
            getDispatchNoteIds,
            userName,
            invoiceRecapExportButtonOptions,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#invoiceTable {
    max-width: 100%;
}
#invoiceItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
.dxbutton-potpis {
    margin-right: 5px;
}
.dxhtmleditor {
    margin-top: 10px;
}
</style>
