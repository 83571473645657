import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { receipt } from "@/stores/receiptStore";
import { supplier } from "@/stores/supplierStore";
import { warehouse } from "@/stores/warehouseStore";
import { receiptItem } from "@/stores/receiptItemStore";
import { businessYear } from "@/stores/businessYearStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { jsPDF } from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { divWrapper } from "@/utils/divWrapper";

export function useReceiptItem() {
    const state = reactive({
        priceListItem,
        businessYear,
        receiptItem,
        supplier,
        warehouse,
        receipt,
        receiptData: null,
        supplierData: null,
        warehouseData: null,
        businessYearData: null,
        documentHeader: null,
        documentFooter: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        adminUsername: localStorage.getItem("username"),
        domainId: localStorage.getItem("userDomainId"),
    });

    async function getReceiptByKey(id) {
        state.receiptData = await receipt.byKey(id);
    }
    async function getSupplierByKey() {
        state.supplierData = await supplier.byKey(state.receiptData.supplierId);
    }
    async function getWarehouseByKey() {
        state.warehouseData = await warehouse.byKey(
            state.receiptData.warehouseId
        );
    }
    async function getBusinessYearByKey() {
        state.businessYearData = await businessYear.byKey(
            state.receiptData.businessYearId
        );
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.domainId);
        if (domainData.documentSections !== null) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    async function handleReceiptExporting(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            topLeft: { x: 1, y: 60 },
            repeatHeaders: true,
            columnWidths: [12, 100, 20, 16, 12, 20],
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const date = new Date(state.receiptData.date);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const dateAndPlace = `Pula ${day}.${month}.${year}`;
            const fileData = `Primka ${state.receiptData.number}/${state.businessYearData.year}`;
            const header = `${state.supplierData.companyName}`;
            const supplierStreet = `${state.supplierData.street}`;
            const supplierCity = `${state.supplierData.zipCode} ${state.supplierData.city}`;
            const supplierCountry = `${state.supplierData.country}`;
            const warehouse = `Skladište: ${state.warehouseData.name}`;
            const invoice = `Račun: ${state.receiptData.supplierInvoice}`;
            const itemsReceivedBy = state.adminUsername;
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(12);
            doc.setPage(1);
            doc.text(header, 19.5, 50);
            doc.setFontSize(8);
            doc.text(dateAndPlace, pageWidth - 15, 50, { align: "right" });
            doc.setFontSize(13);
            doc.text(fileData, pageWidth - 15, 60, { align: "right" });
            doc.setFontSize(8);
            doc.text(supplierStreet, 20, 55);
            doc.text(supplierCity, 20, 60);
            doc.text(supplierCountry, 20, 65);
            doc.text(warehouse, 92, 50);
            doc.text(invoice, 92, 55);
            doc.line(15, 44, 90, 44);
            doc.line(15, 70, 90, 70);
            doc.line(15, 44, 15, 70);
            doc.line(90, 44, 90, 70);
            doc.text("Robu primio/la:", pageWidth - 50, pageHeight - 60, {
                align: "center",
            });
            doc.line(
                pageWidth - 85,
                pageHeight - 45,
                pageWidth - 15,
                pageHeight - 45
            );
            doc.text(itemsReceivedBy, pageWidth - 50, pageHeight - 40, {
                align: "center",
            });
            const documentName = `Primka_${state.receiptData.number}_${state.businessYearData.year}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        },
                    });
                },
            });
        });
    }

    return {
        ...toRefs(state),
        handleReceiptExporting,
        getBusinessYearByKey,
        getWarehouseByKey,
        getSupplierByKey,
        getReceiptByKey,
        getDomainData,
    };
}
