<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="offerItemTable"
            :data-source="offerItem"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :show-toolbar="false"
            @initialized="getOfferItemTableInstance"
        >
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="offerItemTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                :allow-exporting="false"
                data-field="priceListItemId"
                data-type="number"
                caption="Šifra"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
                :width="200"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="measureUnitColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Cijena"
                name="priceColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="price"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Iznos"
                name="priceByQtyColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="priceByQty"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Rabat"
                name="discountAmountColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="discountAmount"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Rab%"
                name="discountPercentageColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="discountPercentage"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Konačan iznos"
                name="priceTotalColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="offerItem"
                    value-expr="id"
                    display-expr="priceTotal"
                />
            </DxColumn>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";

//composables
import { useOfferItem } from "@/composables/useOfferItem.js";

//utils
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "offerItemTableComponent",
    components: {
        DxColumn,
        DxLookup,
        DxDataGrid,
        DxScrolling,
        DxColumnFixing,
        DxStateStoring,
    },
    setup() {
        const {
            customer,
            domain,
            offerItem,
            getUserByKey,
            businessYear,
            getDomainData,
            priceListItem,
            getOfferByKey,
            priceTotalFormat,
            handleOfferExporting,
            getBusinessYearByKey,
        } = useOfferItem();
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        onMounted(() => getDomainData());

        const getOfferItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        // const onExporting = (tableInstance, sendEmailFlag) => {
        //     handleOfferExporting(tableInstance.value, sendEmailFlag);
        // };
        eventBus.on("newOfferItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowOffer", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getOfferByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        eventBus.on("exportOfferItemToPDF", (sendEmailFlag) => {
            handleOfferExporting(tableInstance.value, sendEmailFlag);
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        return {
            customer,
            domain,
            offerItem,
            // onExporting,
            businessYear,
            priceListItem,
            priceTotalFormat,
            shouldShowTheDataGrid,
            getOfferItemTableInstance,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerItemTable {
    width: 100%;
}
</style>
