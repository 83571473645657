<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="dispatchNoteItemTable"
            :data-source="dispatchNoteItem"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            @initialized="getDispatchNoteItemTableInstance"
        >
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="dispatchNoteItemTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Šifra"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                :allow-exporting="false"
                width="120"
                data-field="price"
                data-type="number"
                caption="Cijena"
                format="###,###,###,##0.00"
            />
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="measureUnitColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                :allow-exporting="false"
                width="120"
                data-field="priceTotal"
                data-type="number"
                format="###,###,###,##0.00"
                caption="Ukupno"
            />
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";

import { useDispatchNoteItem } from "@/composables/useDispatchNoteItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "dispatchNoteItemTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxLookup,
        DxStateStoring,
        // DxSummary,
        // DxTotalItem,
    },
    setup() {
        const {
            priceListItem,
            dispatchNoteItem,
            priceTotalFormat,
            getDispatchNoteByKey,
            getUserByKey,
            getBusinessYearByKey,
            handleDispatchNoteExporting,
            getDomainData,
        } = useDispatchNoteItem();
        onMounted(async () => {
            await getDomainData();
        });
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        const getDispatchNoteItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        eventBus.on("exportDispatchNote", () => {
            handleDispatchNoteExporting(tableInstance.value);
        });
        eventBus.on("newDispatchNoteItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowDispatchNote", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getDispatchNoteByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );
        return {
            priceListItem,
            dispatchNoteItem,
            priceTotalFormat,
            getDispatchNoteItemTableInstance,
            shouldShowTheDataGrid,
        };
    },
    // data() {
    //     return {
    //         //Stores
    //         user: user,
    //         dispatchNote: dispatchNote,
    //         businessYear: businessYear,
    //         priceListItem: priceListItem,
    //         dispatchNoteItem: dispatchNoteItem,

    //         //Instances
    //         dispatchNoteItemTableInstance: null,

    //         //Data
    //         userData: null,
    //         dispatchNoteData: null,
    //         businessYearData: null,

    //         //General data
    //         focusedId: false,
    //         shouldShowTheDataGrid: true,

    //         //Utils
    //         poppins: poppins,
    //         priceTotalFormat: {
    //             type: "fixedPoint",
    //             precision: 2,
    //         },
    //     };
    // },
    // created() {
    //     this.subscribeToNewItemEvent();
    //     this.subscribeToNewFocusedRow();
    // },
    // watch: {
    //     focusedId() {
    //         this.shouldShowTheDataGrid = this.focusedId ? true : false;
    //     },
    // },
    // methods: {
    //     //Instances
    //     getDispatchNoteItemdispatchNoteItemTableInstance(e) {
    //         this.dispatchNoteItemTableInstance = e.component;
    //         if (!this.focusedId) this.shouldShowTheDataGrid = false;
    //     },

    //     //DispatchNoteItem table methods
    //     onExporting() {
    //         const doc = new jsPDF();
    //         doc.addFileToVFS("@/assets/Poppins-Regular.ttf", this.poppins);
    //         doc.addFont(
    //             "@/assets/Poppins-Regular.ttf",
    //             "Poppins-Regular",
    //             "normal"
    //         );
    //         doc.setFont("Poppins-Regular");
    //         doc.setFontSize(8);
    //         const lastPoint = { x: 0, y: 0 };
    //         doc.setTextColor(0, 0, 0);

    //         exportPDF({
    //             jsPDFDocument: doc,
    //             component: this.dispatchNoteItemTableInstance,

    //             repeatHeaders: true,
    //             topLeft: { x: 1, y: 60 },
    //             columnWidths: [12, 136, 20, 12],
    //             onRowExporting: (e) => {
    //                 e.rowHeight = 6;
    //             },
    //             customDrawCell({ rect, gridCell, pdfCell }) {
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.font.size = 8;
    //                 } else if (gridCell.rowType === "data") {
    //                     pdfCell.font.size = 7;
    //                 }
    //                 if (lastPoint.x < rect.x + rect.w) {
    //                     lastPoint.x = rect.x + rect.w;
    //                 }
    //                 if (lastPoint.y < rect.y + rect.h) {
    //                     lastPoint.y = rect.y + rect.h;
    //                 }
    //             },
    //             customizeCell({ gridCell, pdfCell }) {
    //                 pdfCell.wordWrapEnabled = true;
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.textColor = "#000000";
    //                     pdfCell.backgroundColor = "#D3D3D3";
    //                     pdfCell.font.size = 7;
    //                 }
    //             },
    //         }).then(() => {
    //             const date = new Date(this.dispatchNoteData.date);
    //             const day = date.getDate();
    //             const month = date.getMonth() + 1;
    //             const year = date.getFullYear();
    //             const dateAndPlace = `Pula ${day}.${month}.${year}`;
    //             const fileData = `Otpremnica ${this.dispatchNoteData.number}/${this.businessYearData.year}`;
    //             const companyName = "Info Network d.o.o";
    //             const companyStreet = "Valturska 78/1, 52100 Pula";
    //             const companyPhone = "T: +385 052 637 000; F: +385 052 637 029";
    //             const companyOIB = "OIB: 92134883875";
    //             const companyGiro = "Žiro rn: 2340009-111064967 1";
    //             const companyIBAN = "IBAN: HR 31 2340009 11106  14967 1";
    //             const companySwift = "Swift Code: PBZGHR2X";
    //             const companyBank = "Privredna Banka Zagreb";
    //             const header =
    //                 this.userData.userType === "business"
    //                     ? `${this.userData.companyName}`
    //                     : `${this.userData.name} ${this.userData.lastname}`;
    //             const userStreet = `${this.userData.address}`;
    //             const userCity = `${this.userData.postalCode} ${this.userData.city}`;
    //             const userCountry = `${this.userData.country}`;
    //             const pageWidth = doc.internal.pageSize.getWidth();
    //             const footer = "Potpis primatelja: ";
    //             doc.setFontSize(12);
    //             doc.setPage(1);
    //             doc.text(header, 19.5, 50);
    //             doc.setFontSize(8);
    //             doc.text(dateAndPlace, pageWidth - 15, 50, { align: "right" });
    //             doc.setFontSize(15);
    //             doc.text(fileData, pageWidth - 15, 57, { align: "right" });
    //             doc.setFontSize(8);
    //             doc.text(companyName, 15, 15);
    //             doc.text(companyStreet, 15, 19);
    //             doc.text(companyPhone, 15, 23);
    //             doc.text(companyOIB, 15, 27);
    //             doc.text(companyGiro, pageWidth - 15, 15, { align: "right" });
    //             doc.text(companyIBAN, pageWidth - 15, 19, { align: "right" });
    //             doc.text(companySwift, pageWidth - 15, 23, { align: "right" });
    //             doc.text(companyBank, pageWidth - 15, 27, { align: "right" });
    //             doc.setFontSize(8);
    //             doc.text(userStreet, 20, 54);
    //             doc.text(userCity, 20, 58);
    //             doc.text(userCountry, 20, 65);
    //             doc.line(15, 70, 90, 70);
    //             doc.line(15, 45, 90, 45);
    //             doc.line(15, 45, 15, 70);
    //             doc.line(90, 45, 90, 70);
    //             doc.setFontSize(9);
    //             doc.setPage(doc.internal.getNumberOfPages());
    //             doc.text(footer, 196 - 15, 285 - 15, {
    //                 align: "right",
    //             });
    //             doc.line(lastPoint.x, 285, lastPoint.x - 58, 285);
    //             doc.save(
    //                 `Otpremnica_${this.dispatchNoteData.number}_${this.businessYearData.year}.pdf`
    //             );
    //         });
    //     },

    //     //Subscribe to events methods
    //     subscribeToNewItemEvent() {
    //         eventBus.on("newDispatchNoteItemAdded", () => {
    //             this.dispatchNoteItemTableInstance.refresh();
    //         });
    //     },
    //     subscribeToNewFocusedRow() {
    //         eventBus.on("newFocusedRowDispatchNote", async (data) => {
    //             if (data !== null) {
    //                 this.focusedId = true;
    //                 this.dispatchNoteData = await this.dispatchNote.byKey(data);
    //                 this.userData = await this.user.byKey(
    //                     this.dispatchNoteData.userId
    //                 );
    //                 this.businessYearData = await this.businessYear.byKey(
    //                     this.dispatchNoteData.businessYearId
    //                 );
    //             } else {
    //                 this.focusedId = false;
    //             }
    //             this.dispatchNoteItemTableInstance.refresh();
    //         });
    //     },
    // },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#dispatchNoteItemTable {
    width: 100%;
}
</style>
