<template>
    <div class="table-container">
        <span class="table-name">Skladište</span>
        <DxDataGrid
            id="warehouseTable"
            :data-source="warehouse"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :allow-column-resizing="true"
            :focused-row-enabled="true"
            @initialized="warehouseTableInstance"
            @focused-row-changed="onFocusedRowChangedWarehouse"
            @row-updating="onRowUpdatingWarehouse"
            @init-new-row="onInitNewRowWarehouse"
            @edit-canceled="onEditCanceledWarehouse"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="warehouseTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :use-icons="true"
                mode="row"
            >
                <!-- <DxPopup
                    :show-title="true"
                    title="Dodaj novo skladište"
                    :toolbar-items="popupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem
                        :col-count="2"
                        :col-span="2"
                        item-type="group"
                        caption="Informacije o skladištu"
                    >
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="name" data-type="string" />
                        <DxItem
                            data-field="domainId"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="businessYearId"
                            data-type="number"
                            :visible="false"
                        />
                    </DxItem>
                </DxForm> -->
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
                :allow-editing="false"
            />
            <DxColumn
                data-field="name"
                data-type="string"
                caption="Naziv"
                :allow-hiding="false"
            >
                <DxRequiredRule />
                <DxStringLengthRule :max="120" />
            </DxColumn>
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-editing="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-editing="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
                :allow-editing="false"
            />
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="edit"
                    @click="editWarehouse"
                    :visible="areEditAndDeleteIconsVisible"
                />
                <DxButton
                    hint="Spremi"
                    icon="check"
                    @click="saveNewWarehouse"
                    :visible="areSaveAndCancelIconsVisible"
                />
                <!-- <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteRow"
                /> -->
                <DxButton
                    hint="Odustani"
                    icon="close"
                    @click="cancelWarehouse"
                    :visible="areSaveAndCancelIconsVisible"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxItem,
    DxPager,
    DxButton,
    DxLookup,
    DxColumn,
    DxPaging,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxRequiredRule,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnChooser,
    DxStringLengthRule,
    DxColumnChooserSelection,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";

//Composables
import { useWarehouse } from "@/composables/useWarehouse.js";
// import { useSelectedRowKey } from "@/composables/useSelectedRowKey.js";

//Utils
import { ref } from "vue";
import eventBus from "../../eventBus.js";

export default {
    name: "warehouseTableComponent",
    components: {
        DxItem,
        DxPager,
        DxButton,
        DxLookup,
        DxColumn,
        DxPaging,
        DxToolbar,
        DxEditing,
        DxDataGrid,
        DxScrolling,
        DxSearchPanel,
        DxRequiredRule,
        DxHeaderFilter,
        DxStateStoring,
        DxColumnChooser,
        DxStringLengthRule,
        DxColumnChooserSelection,
    },
    setup() {
        //Data
        const {
            domain,
            warehouse,
            domainUser,
            businessYear,
            handleRowInit,
            handleRowUpdating,
            columnChooserMode,
        } = useWarehouse();
        const selectedRowKey = ref(null);

        //Instances
        const tableInstance = ref(null);

        //Button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addWarehouse();
            },
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });

        //instances
        const warehouseTableInstance = (e) => {
            tableInstance.value = e.component;
        };

        //warehouse table methods
        const onFocusedRowChangedWarehouse = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                eventBus.emit("newFocusedRowWarehouse", selectedRowKey.value);
            } else {
                eventBus.emit("newFocusedRowWarehouse", null);
            }
        };
        const addWarehouse = () => {
            tableInstance.value.addRow();
        };
        const editWarehouse = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelWarehouse = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteWarehouse = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowWarehouse = (e) => {
            tableInstance.value.option("focusedRowIndex", -1);
            handleRowInit(e);
        };
        const saveNewWarehouse = () => {
            tableInstance.value.saveEditData();
        };
        const onEditCanceledWarehouse = () => {
            tableInstance.value.refresh();
        };
        const onRowUpdatingWarehouse = (e) => {
            handleRowUpdating(e);
        };

        //Button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const areEditAndDeleteIconsVisible = (e) => {
            return !e.row.isEditing ? true : false;
        };
        const areSaveAndCancelIconsVisible = (e) => {
            return e.row.isEditing ? true : false;
        };

        return {
            areEditAndDeleteIconsVisible,
            saveNewWarehouse,
            cancelWarehouse,
            areSaveAndCancelIconsVisible,
            onRowUpdatingWarehouse,
            onEditCanceledWarehouse,
            editWarehouse,
            deleteWarehouse,
            onInitNewRowWarehouse,
            columnChooserMode,
            addButtonOptions,
            refreshButtonOptions,
            columnChooserButtonOptions,
            domain,
            warehouse,
            domainUser,
            businessYear,
            warehouseTableInstance,
            onFocusedRowChangedWarehouse,
        };
    },
    // data() {
    //     return {
    //         //Models
    //         warehouseModel: new WarehouseModel(),

    //         //Stores
    //         domain: domain,
    //         warehouse: warehouse,
    //         domainUser: domainUser,
    //         businessYear: businessYear,

    //         //Instances
    //         tableInstance: null,

    //         //Data
    //         adminId: null,
    //         domainId: null,
    //         businessYearId: null,
    //         selectedRowKey: null,
    //         columnChooserMode: "select",

    //         //Button options
    //         addButtonOptions: {
    //             icon: "add",
    //             onClick: () => {
    //                 this.addNew();
    //             },
    //         },
    //         refreshButtonOptions: {
    //             icon: "refresh",
    //             onClick: () => {
    //                 this.refreshData();
    //             },
    //         },
    //         columnChooserButtonOptions: {
    //             icon: "columnchooser",
    //             onClick: () => {
    //                 this.openColumnChooser();
    //             },
    //         },

    //         //Toolbar items
    //         popupToolbarItems: [
    //             {
    //                 widget: "dxButton",
    //                 location: "after",
    //                 toolbar: "bottom",
    //                 options: {
    //                     text: "Spremi",
    //                     onClick: this.saveNewData,
    //                 },
    //             },
    //             {
    //                 widget: "dxButton",
    //                 location: "after",
    //                 toolbar: "bottom",
    //                 options: {
    //                     text: "Odustani",
    //                     onClick: this.cancel,
    //                 },
    //             },
    //         ],
    //     };
    // },
    // created() {
    //     this.adminId = localStorage.getItem("userId");
    //     this.businessYearId = localStorage.getItem("businessYearId");
    //     this.domainId = localStorage.getItem("userDomainId");
    // },
    // methods: {
    //     //Instances
    //     warehouseTableInstance(e) {
    //         this.tableInstance = e.component;
    //     },

    //     //Warehouse table methods
    //     onFocusedRowChangedWarehouse(e) {
    //         if (e.row) {
    //             const focusedRowKey = e.row.data.id;
    //             this.selectedRowKey = focusedRowKey;
    //             if (this.selectedRowKey !== null) {
    //                 eventBus.emit(
    //                     "newFocusedRowWarehouse",
    //                     this.selectedRowKey
    //                 );
    //             }
    //         } else {
    //             eventBus.emit("newFocusedRowWarehouse", null);
    //         }
    //     },
    //     addNew() {
    //         this.tableInstance.addRow();
    //     },
    //     editRow(e) {
    //         this.tableInstance = e.component;
    //         this.tableInstance.editRow(e.row.rowIndex);
    //     },
    //     cancel() {
    //         this.tableInstance.cancelEditData();
    //     },
    //     deleteRow(e) {
    //         this.tableInstance = e.component;
    //         this.tableInstance.deleteRow(e.row.rowIndex);
    //     },
    //     onInitNewRow(e) {
    //         this.tableInstance.option("focusedRowIndex", -1);
    //         e.data = this.warehouseModel.handleRowInit(
    //             e,
    //             this.adminId,
    //             this.domainId,
    //             this.businessYearId
    //         );
    //     },
    //     saveNewData() {
    //         this.tableInstance.saveEditData();
    //     },
    //     onRowUpdating(e) {
    //         e.newData = this.warehouseModel.handleRowUpdating(e, this.adminId);
    //     },
    //     onEditCanceled() {
    //         this.tableInstance.refresh();
    //     },

    //     //Button methods
    //     refreshData() {
    //         const message = "Podaci osvježeni!";
    //         this.tableInstance.refresh();
    //         notify({ message, width: 450 }, "success");
    //     },

    //     openColumnChooser() {
    //         this.tableInstance.showColumnChooser();
    //     },
    //     areEditAndDeleteIconsVisible(e) {
    //         return !e.row.isEditing ? true : false;
    //     },
    //     areSaveAndCancelIconsVisible(e) {
    //         return e.row.isEditing ? true : false;
    //     },
    // },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#warehouseTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
