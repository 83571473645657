import { reactive, toRefs } from "vue";
import { domainUser } from "@/stores/domainUserStore";
import { priceList } from "@/stores/priceListStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { supplier } from "@/stores/supplierStore";
import DataSource from "devextreme/data/data_source";
import jsPDF from "jspdf";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { poppins } from "@/poppinsbase64";

export function usePriceListItem() {
    const itemTypes = [{ value: "Roba" }, { value: "Usluga" }];
    const priceCalculationTypes = [{ value: "Fiksna cijena" }];
    const hiddenTypes = [
        { value: 0, name: "Ne" },
        { value: 1, name: "Da" },
    ];
    const state = reactive({
        supplier,
        domainUser,
        priceList,
        priceListItem,
        vat: 25,
        vatAmount: 0,
        retailPrice: 0,
        priceListData: null,
        wholesalePrice: 0,
        preventRecursiveUpdate: false,
        columnChooserMode: "select",
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
        supplierSelectBoxOptions: {
            dataSource: new DataSource({
                store: supplier,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi dobavljača",
        },
        itemTypeOptions: {
            items: itemTypes,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip stavke",
        },
        priceCalculationTypeOptions: {
            items: priceCalculationTypes,
            displayExpr: "value",
            valueExpr: "value",
            placeholder: "Izaberi tip cijene",
        },
        hiddenOptions: {
            dataSource: hiddenTypes,
            displayExpr: "name",
            valueExpr: "value",
            placeholder: "Odaberi",
        },
    });

    function updateRetailPrice(e, retail, wholesale, vatAmount) {
        if (!state.preventRecursiveUpdate) {
            state.preventRecursiveUpdate = true;
            state.retailPrice = e.value;
            retail.option("value", state.retailPrice);
            state.wholesalePrice = state.retailPrice * 0.75;
            wholesale.option("value", state.wholesalePrice);
            state.vatAmount = state.retailPrice - state.wholesalePrice;
            vatAmount.option("value", state.vatAmount);
            state.preventRecursiveUpdate = false;
        }
    }
    function updateWholesalePrice(e, wholesale, retail, vatAmout) {
        if (!state.preventRecursiveUpdate) {
            state.preventRecursiveUpdate = true;
            state.wholesalePrice = e.value;
            wholesale.option("value", state.wholesalePrice);
            state.retailPrice = state.wholesalePrice * (1 + state.vat / 100);
            retail.option("value", state.retailPrice);
            state.vatAmount = state.retailPrice - state.wholesalePrice;
            vatAmout.option("value", state.vatAmount);
            state.preventRecursiveUpdate = false;
        }
    }
    function handleNewRowInitPriceListItem(e, focusedRowId) {
        e.data = {
            hidden: 0,
            vat: 25,
            priceListId: focusedRowId,
            priceCalculationType: "Fiksna cijena",
        };
    }
    function handleRowInsertingPriceListItem(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            wholesalePrice: state.wholesalePrice,
            retailPrice: state.retailPrice,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: Number(state.domainId),
        };
    }
    function handleEditingStartPriceListItem(e, table) {
        const now = new Date();
        setTimeout(() => {
            table.cellValue(
                e.component.getRowIndexByKey(e.key),
                "modifiedDate",
                now.toISOString()
            );
        }, 100);
    }
    function handleRowUpdatingPriceListItem(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: Number(state.adminId),
            modifiedDate: now.toISOString(),
        };
        if (state.retailPrice !== null) {
            e.newData.retailPrice = state.retailPrice;
        }
        if (state.wholesalePrice !== null) {
            e.newData.wholesalePrice = state.wholesalePrice;
        }
    }
    function handlePriceListExporting(e) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: e.component,
            topLeft: { x: 1, y: 5 },
            repeatHeaders: true,
            columnWidths: [15, 118, 20, 27],
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.style = "normal";
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                } else if (gridCell.rowType === "totalFooter") {
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const now = new Date();
            const vrijeme = "Vrijeme izrade: " + now.toLocaleString("hr-HR");
            const fileData = `Cijenik: ${state.priceListData.name}`;

            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setFontSize(12);
            doc.setPage(1);
            doc.setFontSize(8);
            doc.text(vrijeme, pageWidth - 15, 15, { align: "right" });
            doc.setFontSize(13);
            doc.text(fileData, 15, 15, { align: "left" });
            doc.setFontSize(8);
            doc.line(15, 17, pageWidth - 15, 17);
            doc.save(`Cijenik_${state.priceListData.name}.pdf`);
        });
    }
    async function getPriceListDataByKey(id) {
        if (id) {
            state.priceListData = await priceList.byKey(id);
        }
    }

    return {
        ...toRefs(state),
        updateRetailPrice,
        updateWholesalePrice,
        handleNewRowInitPriceListItem,
        handleRowInsertingPriceListItem,
        handleEditingStartPriceListItem,
        handleRowUpdatingPriceListItem,
        handlePriceListExporting,
        getPriceListDataByKey,
    };
}
