import { defineStore } from "pinia";
import config from "../../config.json";

export const useDomainUserStore = defineStore("domainUserStore", {
    state: () => ({ user: { id: 1, username: "admin", domainId: 1 } }),
    getters: {
        getDomainUser: (state) => state.user,
    },
    actions: {
        async loginDomainUser(userCredentials) {
            const request = {};
            request.method = "POST";
            request.headers = {
                "Content-Type": "application/json",
            };
            request.body = JSON.stringify(userCredentials);
            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.USERS}/${config.PATHS.LOGIN}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = await response.json();
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Error logging in: ", error);
                throw error;
            }
        },
        async registerNewDomain(requestData) {
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.REGISTER_DOMAIN}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error,
                };
            }
        },
        async forgotPassword(requestData) {
            console.log(requestData);
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.FORGOT_PASSWORD}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error,
                };
            }
        },
        async setNewPassword(requestData) {
            const request = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            };

            try {
                const response = await fetch(
                    `${config.API_URL}/${config.PATHS.SET_NEW_PASSWORD}`,
                    request
                );
                let res;
                if (response.status === 200) {
                    res = response;
                }
                if (!response.ok) {
                    res = await response.json();
                }
                return res;
            } catch (error) {
                console.error("Network error:", error);
                return {
                    success: false,
                    message:
                        "Nije moguće povezati se. Prijavite administratoru.",
                    error: error.message || error,
                };
            }
        },
    },
});
