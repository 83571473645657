import { reactive, toRefs } from "vue";
import { user } from "@/stores/userStore";
import { customer } from "@/stores/customerStore";
import { businessYear } from "@/stores/businessYearStore";
import { dispatchNote } from "@/stores/dispatchNoteStore";
import { priceListItem } from "@/stores/priceListItemStore";
import { dispatchNoteItem } from "@/stores/dispatchNoteItemStore";
import { domain } from "@/stores/domainStore";
import { divWrapper } from "@/utils/divWrapper";

import jsPDF from "jspdf";
import { poppins } from "@/poppinsbase64";
import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";

export function useDispatchNoteItem() {
    const state = reactive({
        priceListItem,
        dispatchNoteItem,
        userData: null,
        dispatchNoteData: null,
        businessYearData: null,
        documentHeader: null,
        documentFooter: null,
        priceTotalFormat: {
            type: "fixedPoint",
            precision: 2,
        },
        adminUsername: localStorage.getItem("username"),
        domainId: localStorage.getItem("userDomainId"),
    });
    async function getDispatchNoteByKey(id) {
        state.dispatchNoteData = await dispatchNote.byKey(id);
    }
    async function getUserByKey() {
        if (Number(state.domainId) === 1) {
            state.userData = await user.byKey(state.dispatchNoteData.userId);
        } else {
            state.userData = await customer.byKey(
                state.dispatchNoteData.userId
            );
        }
    }
    async function getBusinessYearByKey() {
        state.businessYearData = await businessYear.byKey(
            state.dispatchNoteData.businessYearId
        );
    }
    async function getDomainData() {
        const domainData = await domain.byKey(state.domainId);
        if (domainData.documentSections !== null) {
            const sections = JSON.parse(domainData.documentSections);
            state.documentHeader = sections.header
                ? sections.header
                : "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter = sections.footer
                ? sections.footer
                : "Ovdje postavite svoje podnožje za dokumente.";
        } else {
            state.documentHeader =
                "Ovdje postavite svoje zaglavlje za dokumente.";
            state.documentFooter =
                "Ovdje postavite svoje podnožje za dokumente.";
        }
        state.documentHeader = divWrapper(state.documentHeader);
        state.documentFooter = divWrapper(state.documentFooter);
    }
    function handleDispatchNoteExporting(table) {
        const doc = new jsPDF();
        doc.addFileToVFS("@/assets/Poppins-Regular.ttf", poppins);
        doc.addFont(
            "@/assets/Poppins-Regular.ttf",
            "Poppins-Regular",
            "normal"
        );
        doc.setFont("Poppins-Regular");
        doc.setFontSize(8);
        const lastPoint = { x: 0, y: 0 };
        doc.setTextColor(0, 0, 0);
        exportPDF({
            jsPDFDocument: doc,
            component: table,
            repeatHeaders: true,
            topLeft: { x: 1, y: 60 },
            columnWidths: [12, 136, 20, 12],
            onRowExporting: (e) => {
                e.rowHeight = 6;
            },
            customDrawCell({ rect, gridCell, pdfCell }) {
                if (gridCell.rowType === "header") {
                    pdfCell.font.size = 8;
                } else if (gridCell.rowType === "data") {
                    pdfCell.font.size = 7;
                }
                if (lastPoint.x < rect.x + rect.w) {
                    lastPoint.x = rect.x + rect.w;
                }
                if (lastPoint.y < rect.y + rect.h) {
                    lastPoint.y = rect.y + rect.h;
                }
            },
            customizeCell({ gridCell, pdfCell }) {
                pdfCell.wordWrapEnabled = true;
                if (gridCell.rowType === "header") {
                    pdfCell.textColor = "#000000";
                    pdfCell.backgroundColor = "#D3D3D3";
                    pdfCell.font.size = 7;
                }
            },
        }).then(() => {
            const date = new Date(state.dispatchNoteData.date);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const dateAndPlace = `Pula ${day}.${month}.${year}`;
            const fileData = `Otpremnica ${state.dispatchNoteData.number}/${state.businessYearData.year}`;
            const header =
                state.userData.userType === "business"
                    ? `${state.userData.companyName}`
                    : `${state.userData.name} ${state.userData.lastname}`;
            const userStreet = `${state.userData.address}`;
            const userCity = `${state.userData.postalCode} ${state.userData.city}`;
            const userCountry = `${state.userData.country}`;
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const footer = "Potpis primatelja: ";
            doc.setFontSize(12);
            doc.setPage(1);
            doc.text(header, 19.5, 50);
            doc.setFontSize(8);
            doc.text(dateAndPlace, pageWidth - 15, 50, { align: "right" });
            doc.setFontSize(15);
            doc.text(fileData, pageWidth - 15, 57, { align: "right" });
            doc.setFontSize(8);
            doc.text(userStreet, 20, 54);
            doc.text(userCity, 20, 58);
            doc.text(userCountry, 20, 65);
            doc.line(15, 70, 90, 70);
            doc.line(15, 45, 90, 45);
            doc.line(15, 45, 15, 70);
            doc.line(90, 45, 90, 70);
            doc.setFontSize(9);
            doc.setPage(doc.internal.getNumberOfPages());
            doc.text(footer, pageWidth - 50, pageHeight - 60, {
                align: "center",
            });
            doc.line(
                pageWidth - 85,
                pageHeight - 45,
                pageWidth - 15,
                pageHeight - 45
            );
            doc.text(state.adminUsername, pageWidth - 50, pageHeight - 40, {
                align: "center",
            });
            const documentName = `Otpremnica_${state.dispatchNoteData.number}_${state.businessYearData.year}.pdf`;
            doc.html(state.documentHeader, {
                x: 15,
                y: 10,
                width: 180,
                margin: [0, 0, 0, 0],
                windowWidth: 800,
                callback: (doc) => {
                    doc.html(state.documentFooter, {
                        x: 15,
                        y: pageHeight - 10,
                        width: 180,
                        windowWidth: 800,
                        margin: [0, 0, 0, 0],
                        callback: (doc) => {
                            doc.save(documentName);
                        },
                    });
                },
            });
        });
    }

    return {
        ...toRefs(state),
        getDispatchNoteByKey,
        getUserByKey,
        getBusinessYearByKey,
        handleDispatchNoteExporting,
        getDomainData,
    };
}
