import { reactive, toRefs } from "vue";
import { domain } from "@/stores/domainStore";
import { warehouse } from "@/stores/warehouseStore";
import { domainUser } from "@/stores/domainUserStore.js";
import { businessYear } from "@/stores/businessYearStore";

export function useWarehouse() {
    const state = reactive({
        domain: domain,
        warehouse: warehouse,
        domainUser: domainUser,
        businessYear: businessYear,
        columnChooserMode: "select",
        adminId: localStorage.getItem("userId"),
        domainId: localStorage.getItem("userDomainId"),
        businessYearId: localStorage.getItem("businessYearId"),
    });

    function handleRowInit(e) {
        const now = new Date();
        e.data = {
            ...e.data,
            createdById: Number(state.adminId),
            modifiedById: Number(state.adminId),
            createdDate: now.toISOString(),
            modifiedDate: now.toISOString(),
            domainId: Number(state.domainId),
            businessYearId: Number(state.businessYearId),
        };
    }

    function handleRowUpdating(e) {
        const now = new Date();
        e.newData = {
            ...e.newData,
            modifiedById: Number(state.adminId),
            modifiedDate: now.toISOString(),
        };
    }

    return {
        ...toRefs(state),
        handleRowInit,
        handleRowUpdating,
    };
}
