<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="receiptItemTable"
            :data-source="receiptItem"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            @initialized="getReceiptItemTableInstance"
        >
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="receiptItemTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                caption="Šifra"
                data-field="priceListItemId"
                data-type="number"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="price"
                data-type="number"
                caption="Cijena"
                format="#,###0.00"
            />
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="#,###0.00"
            />
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="measureUnitColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="priceTotal"
                data-type="number"
                format="#,###0.00"
                caption="Ukupno"
            />
            <DxSummary>
                <DxTotalItem
                    column="priceTotal"
                    summary-type="sum"
                    :value-format="priceTotalFormat"
                    display-format="{0}"
                />
            </DxSummary>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
    DxSummary,
    DxTotalItem,
} from "devextreme-vue/data-grid";

//Utils
import eventBus from "../../eventBus.js";
import { useReceiptItem } from "@/composables/useReceiptItem.js";
import { ref, watch, onMounted } from "vue";

export default {
    name: "receiptItemTableComponent",
    components: {
        DxColumn,
        DxLookup,
        DxSummary,
        DxDataGrid,
        DxTotalItem,
        DxScrolling,
        DxColumnFixing,
        DxStateStoring,
    },
    setup() {
        const {
            priceListItem,
            businessYear,
            receiptItem,
            supplier,
            warehouse,
            receipt,
            priceTotalFormat,
            handleReceiptExporting,
            getBusinessYearByKey,
            getWarehouseByKey,
            getSupplierByKey,
            getReceiptByKey,
            getDomainData,
        } = useReceiptItem();
        onMounted(async () => {
            await getDomainData();
        });
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        const getReceiptItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };

        eventBus.on("exportReceipt", () => {
            handleReceiptExporting(tableInstance.value);
        });
        eventBus.on("newReceiptItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowReceipt", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getReceiptByKey(data);
                await getSupplierByKey();
                await getWarehouseByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );

        return {
            priceListItem,
            businessYear,
            receiptItem,
            supplier,
            warehouse,
            receipt,
            priceTotalFormat,
            getReceiptItemTableInstance,
            shouldShowTheDataGrid,
        };
    },
    // data() {
    //     return {
    //         //Stores
    //         receipt: receipt,
    //         supplier: supplier,
    //         warehouse: warehouse,
    //         receiptItem: receiptItem,
    //         businessYear: businessYear,
    //         priceListItem: priceListItem,

    //         //Instances
    //         receiptItemTableInstance: null,

    //         //Data
    //         receiptData: null,
    //         supplierData: null,
    //         warehouseData: null,
    //         businessYearData: null,

    //         //General data
    //         poppins: poppins,
    //         focusedId: false,
    //         shouldShowTheDataGrid: true,
    //         priceTotalFormat: {
    //             type: "fixedPoint",
    //             precision: 2,
    //         },
    //     };
    // },
    // created() {
    //     this.subscribeToNewItemEvent();
    //     this.subscribeToNewFocusedRow();
    // },
    // watch: {
    //     focusedId() {
    //         this.shouldShowTheDataGrid = this.focusedId ? true : false;
    //     },
    // },
    // methods: {
    //     //Instances
    //     getReceiptItemTableInstance(e) {
    //         this.receiptItemTableInstance = e.component;
    //         if (!this.focusedId) this.shouldShowTheDataGrid = false;
    //     },

    //     //Receipt item table methods
    //     onExporting() {
    //         const doc = new jsPDF();
    //         doc.addFileToVFS("@/assets/Poppins-Regular.ttf", this.poppins);
    //         doc.addFont(
    //             "@/assets/Poppins-Regular.ttf",
    //             "Poppins-Regular",
    //             "normal"
    //         );
    //         doc.setFont("Poppins-Regular");
    //         doc.setFontSize(8);
    //         const lastPoint = { x: 0, y: 0 };
    //         doc.setTextColor(0, 0, 0);

    //         exportPDF({
    //             jsPDFDocument: doc,
    //             component: this.receiptItemTableInstance,
    //             topLeft: { x: 1, y: 20 },
    //             repeatHeaders: true,
    //             columnWidths: [12, 100, 20, 16, 12, 20],
    //             onRowExporting: (e) => {
    //                 e.rowHeight = 6;
    //             },
    //             customDrawCell({ rect, gridCell, pdfCell }) {
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.font.size = 8;
    //                 } else if (gridCell.rowType === "data") {
    //                     pdfCell.font.size = 7;
    //                 } else if (gridCell.rowType === "totalFooter") {
    //                     pdfCell.font.style = "normal";
    //                 }
    //                 if (lastPoint.x < rect.x + rect.w) {
    //                     lastPoint.x = rect.x + rect.w;
    //                 }
    //                 if (lastPoint.y < rect.y + rect.h) {
    //                     lastPoint.y = rect.y + rect.h;
    //                 }
    //             },
    //             customizeCell({ gridCell, pdfCell }) {
    //                 pdfCell.wordWrapEnabled = true;
    //                 if (gridCell.rowType === "header") {
    //                     pdfCell.textColor = "#000000";
    //                     pdfCell.backgroundColor = "#D3D3D3";
    //                     pdfCell.font.size = 7;
    //                 } else if (gridCell.rowType === "totalFooter") {
    //                     pdfCell.font.size = 7;
    //                 }
    //             },
    //         }).then(() => {
    //             const date = new Date(this.receiptData.date);
    //             const day = date.getDate();
    //             const month = date.getMonth() + 1;
    //             const year = date.getFullYear();
    //             const dateAndPlace = `Pula ${day}.${month}.${year}`;
    //             const fileData = `Primka ${this.receiptData.number}/${this.businessYearData.year}`;
    //             const header = `${this.supplierData.companyName}`;
    //             const supplierStreet = `${this.supplierData.street}`;
    //             const supplierCity = `${this.supplierData.zipCode} ${this.supplierData.city}`;
    //             const supplierCountry = `${this.supplierData.country}`;
    //             const warehouse = `Skladište: ${this.warehouseData.name}`;
    //             const invoice = `Račun: ${this.receiptData.supplierInvoice}`;
    //             const pageWidth = doc.internal.pageSize.getWidth();
    //             doc.setFontSize(12);
    //             doc.setPage(1);
    //             doc.text(header, 19.5, 13);
    //             doc.setFontSize(8);
    //             doc.text(dateAndPlace, pageWidth - 15, 12, { align: "right" });
    //             doc.setFontSize(13);
    //             doc.text(fileData, pageWidth - 15, 20, { align: "right" });
    //             doc.setFontSize(8);
    //             doc.text(supplierStreet, 20, 17);
    //             doc.text(supplierCity, 20, 21);
    //             doc.text(supplierCountry, 20, 28);
    //             doc.text(warehouse, 92, 12);
    //             doc.text(invoice, 92, 16);
    //             doc.line(15, 30, 90, 30);
    //             doc.line(15, 8, 90, 8);
    //             doc.line(15, 8, 15, 30);
    //             doc.line(90, 8, 90, 30);
    //             doc.save(
    //                 `Primka_${this.receiptData.number}_${this.businessYearData.year}.pdf`
    //             );
    //         });
    //     },

    //     //Subscribe to events
    //     subscribeToNewItemEvent() {
    //         eventBus.on("newReceiptItemAdded", () => {
    //             this.receiptItemTableInstance.refresh();
    //         });
    //     },
    //     subscribeToNewFocusedRow() {
    //         eventBus.on("newFocusedRowReceipt", async (data) => {
    //             if (data !== null) {
    //                 this.focusedId = true;
    //                 this.receiptData = await this.receipt.byKey(data);
    //                 this.supplierData = await this.supplier.byKey(
    //                     this.receiptData.supplierId
    //                 );
    //                 this.warehouseData = await this.warehouse.byKey(
    //                     this.receiptData.warehouseId
    //                 );
    //                 this.businessYearData = await this.businessYear.byKey(
    //                     this.warehouseData.businessYearId
    //                 );
    //             } else {
    //                 this.focusedId = false;
    //             }
    //             this.receiptItemTableInstance.refresh();
    //         });
    //     },
    // },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#receiptItemTable {
    width: 100%;
}
</style>
