<template>
    <div class="table-container">
        <span class="table-name">Kupci</span>
        <DxDataGrid
            id="customerTable"
            :data-source="dataSource"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            @initialized="getCustomerTableInstance"
            @row-updating="onRowUpdatingCustomer"
            @row-inserting="onRowInsertingCustomer"
            @row-inserted="onRowInsertedCustomer"
            @init-new-row="onInitNewRowCustomer"
        >
            <DxToolbar>
                <DxItem
                    :options="addButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="refreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="exportButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="customerTableState"
            />

            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :allowed-page-sizes="[10, 20, 30]"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
            />

            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Dodaj novog kupca"
                    :toolbar-items="popupToolbarItems"
                    :drag-outside-boundary="true"
                />
                <DxForm>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Informacije o kupcu"
                    >
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem data-field="vatId" data-type="string">
                            <DxRequiredRule />
                            <DxStringLengthRule
                                :max="11"
                                :min="11"
                                message="OIB mora sadržavati 11 brojeva."
                            />
                        </DxItem>

                        <DxItem data-field="name" data-type="string">
                            <DxRequiredRule
                                v-if="userType === 'private'"
                                :key="`private-name-${userType}`"
                        /></DxItem>
                        <DxItem data-field="lastname" data-type="string">
                            <DxRequiredRule
                                v-if="userType === 'private'"
                                :key="`private-lastname-${userType}`"
                            />
                        </DxItem>
                        <DxItem data-field="sign" data-type="string">
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="companyName" data-type="string"
                            ><DxRequiredRule v-if="userType === 'business'"
                        /></DxItem>
                        <DxItem
                            data-field="userType"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="userTypeSelectBoxOptions"
                        >
                            <DxRequiredRule
                        /></DxItem>
                    </DxItem>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Adresa/Kontakt"
                    >
                        <DxItem data-field="postalCode" data-type="string">
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="city" data-type="string"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="address" data-type="string"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="country" data-type="string"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="email" data-type="string">
                            <DxEmailRule />
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="mobile" data-type="string"></DxItem>
                        <DxItem data-field="tel" data-type="string"></DxItem>
                        <DxItem data-field="fax" data-type="string"></DxItem>
                    </DxItem>
                    <DxItem
                        :col-count="3"
                        :col-span="2"
                        item-type="group"
                        caption="Ostale informacije"
                    >
                        <DxItem
                            data-field="billDeliveryMethod"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="billDeliveryMethodSelectBoxOptions"
                        ></DxItem>
                        <DxItem data-field="note" data-type="string"></DxItem>
                        <DxItem
                            data-field="status"
                            data-type="string"
                            editor-type="dxSelectBox"
                            :editor-options="statusSelectBoxOptions"
                        ></DxItem>
                        <DxItem
                            data-field="newsletter"
                            editor-type="dxSelectBox"
                            :editor-options="newsletterSelectBoxOptions"
                            data-type="number"
                        ></DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="status"
                data-type="string"
                caption="Status"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="sign"
                data-type="string"
                caption="Skr. naziv"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="vatId"
                data-type="string"
                caption="OIB"
                :allow-hiding="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="name"
                data-type="string"
                caption="Ime"
                :allow-hiding="false"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="lastname"
                data-type="string"
                caption="Prezime"
                :allow-hiding="false"
                :allow-exporting="false"
            />

            <DxColumn
                data-field="companyName"
                data-type="string"
                caption="Naziv firme"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="userType"
                data-type="string"
                caption="Tip kupca"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="address"
                data-type="string"
                caption="Adresa"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="city"
                data-type="string"
                caption="Grad"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="postalCode"
                data-type="string"
                caption="Poštanski broj"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="country"
                data-type="string"
                caption="Država"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="tel"
                data-type="string"
                caption="Telefon"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="mobile"
                data-type="string"
                caption="Mobitel"
            />
            <DxColumn data-field="email" data-type="string" caption="E-mail" />
            <DxColumn
                data-field="fax"
                data-type="string"
                caption="Fax"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="billDeliveryMethod"
                data-type="string"
                caption="Način dostave računa"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="note"
                data-type="string"
                caption="Napomena"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="newsletter"
                data-type="string"
                caption="Novine"
                :allow-exporting="false"
            />

            <DxColumn
                data-field="dateCreated"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-exporting="false"
            />
            <!-- <DxColumn
                data-field="createdDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Kreirano"
                :allow-exporting="false"
            /> -->
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                format="dd/MM/yyyy HH:mm"
                caption="Uređeno"
                :allow-exporting="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-exporting="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
                :allow-exporting="false"
            />
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editCustomer"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteCustomer"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxItem,
    DxForm,
    DxPager,
    DxPopup,
    DxButton,
    DxPaging,
    DxColumn,
    DxLookup,
    DxToolbar,
    DxEditing,
    DxDataGrid,
    DxEmailRule,
    DxScrolling,
    DxSearchPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxRequiredRule,
    DxColumnChooser,
    DxStringLengthRule,
    DxColumnChooserSelection,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";

import { ref, onMounted } from "vue";
import { useCustomer } from "@/composables/useCustomer";

export default {
    name: "customerTableComponent",
    components: {
        DxItem,
        DxForm,
        DxPager,
        DxPopup,
        DxButton,
        DxPaging,
        DxColumn,
        DxLookup,
        DxToolbar,
        DxEditing,
        DxDataGrid,
        DxEmailRule,
        DxScrolling,
        DxSearchPanel,
        DxHeaderFilter,
        DxStateStoring,
        DxRequiredRule,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxStringLengthRule,
    },
    setup() {
        const {
            domainUser,
            customer,
            user,
            columnChooserMode,
            customerTypes,
            status,
            billDeliveryMethods,
            newsletterOptions,
            saveLastData,
            lastSavedData,
            adminId,
            domainId,
            handleNewRowInitCustomer,
            handleRowInsertingCustomer,
            handleRowUpdatingCustomer,
            handleExportCustomer,
            getDomainData,
        } = useCustomer();
        const dataSource = Number(domainId.value) === 1 ? user : customer;
        onMounted(async () => {
            await getDomainData();
        });

        const userType = ref("");

        //instances
        const tableInstance = ref(null);
        const userTypeInstance = ref(null);

        //button options
        const addButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addCustomer();
            },
        });
        const refreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });
        const exportButtonOptions = ref({
            icon: "export",
            onClick: () => {
                exportCustomer();
            },
        });

        //toolbar items
        const popupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi i novi",
                    onClick: () => saveAndContinueCustomer(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveCustomer(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelCustomer(),
                },
            },
        ]);

        //form item options
        const userTypeSelectBoxOptions = ref({
            items: customerTypes.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi tip kupca",
            onInitialized: (e) => {
                userTypeInstance.value = e.component;
            },
            onSelectionChanged: (e) => {
                userType.value = e.selectedItem.value;
                // userType.value
                // console.log(formInstance.value.getEditor("name"));
            },
        });
        // const validationCallback = (e) => {
        //     if (userType.value === "private") {
        //         if (
        //             e.formItem.dataField === "name" ||
        //             e.formItem.dataField === "lastname"
        //         ) {
        //             e.rule.type = "required";
        //         } else {
        //             e.rule.type = "custom";
        //             return true;
        //         }
        //     } else if (userType.value === "business") {
        //         console.log(e);
        //         if (e.formItem.dataField === "companyName") {
        //             e.rule.type = "required";
        //         } else {
        //             e.rule.type = "custom";
        //             return true;
        //         }
        //     }
        //     console.log(e);
        // };
        const billDeliveryMethodSelectBoxOptions = ref({
            items: billDeliveryMethods.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi način dostave",
        });
        const statusSelectBoxOptions = ref({
            items: status.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi status kupca",
        });
        const newsletterSelectBoxOptions = ref({
            items: newsletterOptions.value,
            displayExpr: "key",
            valueExpr: "value",
            placeholder: "Izaberi pretplatu",
        });

        //instance getters
        const getCustomerTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        // const getFormInstance = (e) => {
        //     formInstance.value = e.component;
        // };

        //supplier table methods
        const addCustomer = () => {
            tableInstance.value.addRow();
        };
        const editCustomer = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelCustomer = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteCustomer = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowCustomer = (e) => {
            handleNewRowInitCustomer(e);
        };
        const saveCustomer = () => {
            saveLastData.value = false;
            lastSavedData.value = {};
            tableInstance.value.saveEditData();
        };
        const saveAndContinueCustomer = () => {
            saveLastData.value = true;
            tableInstance.value.saveEditData();
        };
        const onRowInsertingCustomer = (e) => {
            e.data = handleRowInsertingCustomer(e);
        };
        const onRowInsertedCustomer = () => {
            if (saveLastData.value) {
                addCustomer();
            }
            tableInstance.value.refresh();
        };
        const onRowUpdatingCustomer = (e) => {
            e.newData = handleRowUpdatingCustomer(e);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const exportCustomer = () => {
            handleExportCustomer(tableInstance.value);
        };
        return {
            domainUser,
            customer,
            dataSource,
            columnChooserMode,
            customerTypes,
            adminId,
            domainId,
            handleNewRowInitCustomer,
            handleRowInsertingCustomer,
            handleRowUpdatingCustomer,
            handleExportCustomer,
            getCustomerTableInstance,
            editCustomer,
            deleteCustomer,
            onInitNewRowCustomer,
            onRowInsertingCustomer,
            onRowInsertedCustomer,
            onRowUpdatingCustomer,
            addButtonOptions,
            refreshButtonOptions,
            columnChooserButtonOptions,
            exportButtonOptions,
            popupToolbarItems,
            userTypeSelectBoxOptions,
            userType,
            billDeliveryMethodSelectBoxOptions,
            statusSelectBoxOptions,
            newsletterSelectBoxOptions,
            // validationCallback,
            // getFormInstance,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#supplierTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
