import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";

let filterId = null;
eventBus.on("newFocusedRowIntermediateWarehouse", (id) => {
    filterId = id;
});

export const intermediateWarehouseItem = new CustomStore({
    key: "id",
    load: async () => {
        try {
            if (filterId !== null) {
                const filterCondition = {
                    where: {
                        intermediateWarehouseId: filterId,
                    },
                };
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.INTERMEDIATE_WAREHOUSE_ITEM
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                eventBus.emit("intermediateWarehouseItems", newData);
                return newData;
            } else {
                eventBus.emit("intermediateWarehouseItems", []);
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.INTERMEDIATE_WAREHOUSE_ITEM}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.INTERMEDIATE_WAREHOUSE_ITEM}/${id}`,
                "PATCH",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.INTERMEDIATE_WAREHOUSE_ITEM}`,
                "POST",
                {
                    values: values,
                }
            );
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.INTERMEDIATE_WAREHOUSE_ITEM}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    },
});
