<template>
    <div class="table-container">
        <DxDataGrid
            v-if="shouldShowTheDataGrid"
            id="invoiceItemTable"
            :data-source="invoiceItem"
            :repaint-changes-only="true"
            :allow-column-resizing="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :show-toolbar="false"
            @initialized="getInvoiceItemTableInstance"
        >
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="invoiceItemTableState"
            />
            <DxScrolling column-rendering-mode="virtual" />
            <DxColumnFixing :enabled="true" />
            <DxColumn data-field="id" data-type="number" :visible="false" />
            <DxColumn
                :allow-exporting="false"
                data-field="priceListItemId"
                data-type="number"
                caption="Šifra"
                name="codeColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="code"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="Naziv"
                name="nameColumn"
                :width="200"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="priceListItemId"
                data-type="number"
                caption="JMJ"
                name="measureUnitColumn"
            >
                <DxLookup
                    :data-source="priceListItem"
                    value-expr="id"
                    display-expr="measureUnit"
                />
            </DxColumn>
            <DxColumn
                width="120"
                data-field="qty"
                data-type="number"
                caption="Kol."
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Cijena"
                name="priceColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="invoiceItem"
                    value-expr="id"
                    display-expr="price"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Iznos"
                name="priceByQtyColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="invoiceItem"
                    value-expr="id"
                    display-expr="priceByQty"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Rabat"
                name="discountAmountColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="invoiceItem"
                    value-expr="id"
                    display-expr="discountAmount"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Rab%"
                name="discountPercentageColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="invoiceItem"
                    value-expr="id"
                    display-expr="discountPercentage"
                />
            </DxColumn>
            <DxColumn
                data-field="id"
                data-type="number"
                caption="Konačan iznos"
                name="priceTotalColumn"
                :format="priceTotalFormat"
            >
                <DxLookup
                    :data-source="invoiceItem"
                    value-expr="id"
                    display-expr="priceTotal"
                />
            </DxColumn>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxLookup,
    DxStateStoring,
} from "devextreme-vue/data-grid";
// import { exportDataGrid as exportPDF } from "devextreme/pdf_exporter";
import { useInvoiceItem } from "@/composables/useInvoiceItem.js";
import eventBus from "../../eventBus.js";
import { ref, watch, onMounted } from "vue";
// import bwipjs from "bwip-js";

export default {
    name: "invoiceItemTableComponent",
    components: {
        DxColumn,
        DxLookup,
        DxDataGrid,
        DxScrolling,
        DxColumnFixing,
        DxStateStoring,
    },
    setup() {
        const {
            customer,
            invoice,
            domain,
            invoiceItem,
            businessYear,
            priceListItem,
            priceTotalFormat,
            getDomainData,
            getInvoiceByKey,
            getUserByKey,
            getBusinessYearByKey,
            handleInvoiceExporting,
        } = useInvoiceItem();
        const tableInstance = ref(null);
        const focusedId = ref(false);
        const shouldShowTheDataGrid = ref(true);

        onMounted(() => getDomainData());

        const getInvoiceItemTableInstance = (e) => {
            tableInstance.value = e.component;
            if (!focusedId.value) shouldShowTheDataGrid.value = false;
        };
        // const onExporting = (tableInstance, sendEmailFlag) => {
        //     handleInvoiceExporting(tableInstance.value, sendEmailFlag);
        // };
        eventBus.on("newInvoiceItemAdded", () => {
            tableInstance.value.refresh();
        });
        eventBus.on("newFocusedRowInvoice", async (data) => {
            if (data !== null) {
                focusedId.value = true;
                await getInvoiceByKey(data);
                await getUserByKey();
                await getBusinessYearByKey();
            } else {
                focusedId.value = false;
            }
            tableInstance.value.refresh();
        });
        eventBus.on("exportInvoiceItemToPDF", (sendEmailFlag) => {
            handleInvoiceExporting(tableInstance.value, sendEmailFlag);
        });
        watch(
            () => focusedId.value,
            () => {
                shouldShowTheDataGrid.value = focusedId.value ? true : false;
            }
        );

        return {
            customer,
            invoice,
            domain,
            invoiceItem,
            businessYear,
            priceListItem,
            priceTotalFormat,
            getInvoiceItemTableInstance,
            shouldShowTheDataGrid,
            // onExporting
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#invoiceItemTable {
    width: 100%;
}
</style>
