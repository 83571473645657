<template>
    <div class="table-container">
        <span class="table-name">Početno stanje</span>
        <DxDataGrid
            id="initialStateTable"
            :data-source="initialState"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getInitialStateTableInstance"
            @init-new-row="onInitNewRowInitialState"
            @row-inserting="onRowInsertingInitialState"
            @row-inserted="onRowInsertedInitialState"
            @editing-start="onEditingStartInitialState"
            @row-updating="onRowUpdatingInitialState"
            @row-updated="onRowUpdatedInitialState"
            @row-removing="onRowRemovingInitialState"
            @focused-row-changed="onFocusedRowChangedInitialState"
            @edit-canceled="onEditCanceledInitialState"
            edit-template="initialStateEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="initialStateTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="initialStateAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="initialStateRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o početnom stanju"
                    :toolbar-items="initialStatePopupToolbarItems"
                    :drag-outside-boundary="true"
                    @shown="onPopupShown"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>

                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="remark" data-type="string">
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="warehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="warehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                    </DxItem>
                    <DxItem item-type="group" :col-span="2">
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="initialStateItemTable"
                                    :data-source="initialStateItemTableData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="
                                        getInitialStateItemTableInstance
                                    "
                                    @editing-start="
                                        onEditingStartInitialStateItem
                                    "
                                    @row-inserting="
                                        onRowInsertingInitialStateItem
                                    "
                                >
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="350"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                initialStateItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="Stanje na skladištu"
                                                    data-type="number"
                                                    :editor-options="
                                                        warehouseStatusOptions
                                                    "
                                                />
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Ukupna cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editInitialStateItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteInitialStateItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="warehouseId"
                data-type="number"
                caption="Skladište"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
            />
            <DxColumn data-field="remark" data-type="string" caption="Opis" />
            <DxColumn
                data-field="priceTotal"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editInitialState"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteInitialState"
                />
                <DxButton
                    hint="Ispiši PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportInitialState"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";

//Utils
import { useInitialState } from "@/composables/useInitialState";
import { ref } from "vue";
import eventBus from "../../eventBus.js";

export default {
    name: "initialStateTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
    },
    setup() {
        //instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);
        const dateSelectInstance = ref(null);
        const warehouseStatusInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const priceListItemInstance = ref(null);
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const priceTotalInstance = ref(null);

        //general data
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const priceListItemId = ref(null);
        const qty = ref(1);
        const price = ref(0);
        const priceTotal = ref(0);
        const number = ref(0);
        const {
            domain,
            domainUser,
            warehouse,
            businessYear,
            initialState,
            initialStateItem,
            priceListItem,
            warehouseItemStatus,
            columnChooserMode,
            priceTotalFormat,
            businessYearId,
            rowsToBeDeleted,
            initialStateItemTableData,
            handleRowInsertingInitialState,
            handleRowInsertedInitialState,
            handleEditingStartInitialState,
            handleEditCanceledInitialState,
            handleRowUpdatingInitialState,
            handleRowRemovingInitialState,
            handleCancelInitialStateItem,
            handleSaveNewInitialStateItem,
            handleRowInsertingInitialStateItem,
            handleEditingStartInitialStateItem,
        } = useInitialState();

        //button options
        const initialStateAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addInitialState();
            },
        });
        const initialStateItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addInitialStateItem();
            },
        });
        const initialStateRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });

        //toolbar items
        const initialStatePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewInitialState(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelInitialState(),
                },
            },
        ]);
        const initialStateItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewInitialStateItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelInitialStateItem(),
                },
            },
        ]);

        //form item options
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear,
            valueExpr: "id",
            readOnly: true,
        });
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: () => {
                numberInstance.value.option("disabled", false);
            },
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
            },
            onSelectionChanged: async (e) => {
                if (!warehouseInstance.value.option("disabled")) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: businessYearId.value,
                    };
                    const res = await initialState.value.load(loadOptions);
                    if (res.length > 0) {
                        numberInstance.value.option("value", res[0].number + 1);
                        number.value = res[0].number + 1;
                    } else {
                        numberInstance.value.option("value", 1);
                        number.value = 1;
                    }
                }
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceListItem.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.wholesalePrice !== null) {
                    priceInstance.value.option("value", res.wholesalePrice);
                } else {
                    priceInstance.value.option("value", 1);
                }
                priceListItemId.value = e.value;
            },
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceOptions = ref({
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceTotalOptions = ref({
            value: priceTotal,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });

        //instances
        const getInitialStateTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getInitialStateItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };

        //initial state table methods
        const onFocusedRowChangedInitialState = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit(
                        "newFocusedRowInitialState",
                        selectedRowKey.value
                    );
                }
            } else {
                eventBus.emit("newFocusedRowInitialState", null);
            }
        };
        const addInitialState = () => {
            tableInstance.value.addRow();
        };
        const editInitialState = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelInitialState = () => {
            tableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteInitialState = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowInitialState = (e) => {
            const now = new Date();
            e.data = {
                businessYearId: Number(businessYearId.value),
            };
            priceListItemId.value = null;
            tableInstance.value.option("focusedRowIndex", -1);
            initialStateItemTableData.value = [];
            setTimeout(() => {
                dateSelectInstance.value.option("value", now.toISOString());
            }, 100);
        };
        const saveNewInitialState = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingInitialState = (e) => {
            handleRowInsertingInitialState(e, number.value, selectedDate.value);
        };
        const onRowInsertedInitialState = async (e) => {
            await handleRowInsertedInitialState(e);
            qty.value = 1;
            tableInstance.value.refresh();
        };
        const onEditingStartInitialState = async (e) => {
            await handleEditingStartInitialState(e);
            const now = new Date();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                warehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledInitialState = () => {
            handleEditCanceledInitialState();
        };
        const onRowUpdatingInitialState = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingInitialState(e);
            initialStateItemTableData.value = [];
            qty.value = 1;
        };
        const onRowUpdatedInitialState = () => {
            eventBus.emit("newInitialStateItemAdded");
            tableInstance.value.refresh();
        };
        const onRowRemovingInitialState = async (e) => {
            await handleRowRemovingInitialState(e);
        };
        const exportInitialState = () => {
            eventBus.emit("exportInitialState");
        };

        //initial state item table methods
        const addInitialStateItem = () => {
            itemTableInstance.value.addRow();
        };
        const editInitialStateItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelInitialStateItem = () => {
            handleCancelInitialStateItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteInitialStateItem = (e) => {
            initialStateItemTableData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            itemTableInstance.value.refresh();
        };
        const saveNewInitialStateItem = () => {
            handleSaveNewInitialStateItem(
                qty.value,
                price.value,
                priceTotal.value
            );
            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingInitialStateItem = (e) => {
            handleRowInsertingInitialStateItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
        };
        const onEditingStartInitialStateItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartInitialStateItem(e);
            price.value = e.data.price;
            priceListItemId.value = null;
            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };

        //general methods
        const onPopupShown = () => {
            warehouseInstance.value.focus();
        };
        const updateTotalValue = () => {
            priceTotal.value = (qty.value * price.value).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };

        //events
        eventBus.on("initialStateItems", (data) => {
            initialStateItemTableData.value = data;
        });
        return {
            initialStateItemAddButtonOptions,
            initialStateRefreshButtonOptions,
            columnChooserButtonOptions,
            initialStatePopupToolbarItems,
            initialStateItemPopupToolbarItems,
            getInitialStateItemTableInstance,
            onRowUpdatedInitialState,
            onEditCanceledInitialState,
            onEditingStartInitialState,
            onRowRemovingInitialState,
            onRowInsertingInitialStateItem,
            editInitialState,
            onRowUpdatingInitialState,
            deleteInitialState,
            onEditingStartInitialStateItem,
            onRowInsertingInitialState,
            onRowInsertedInitialState,
            editInitialStateItem,
            domain,
            domainUser,
            warehouse,
            businessYear,
            initialState,
            onFocusedRowChangedInitialState,
            initialStateItem,
            deleteInitialStateItem,
            priceListItem,
            warehouseItemStatus,
            columnChooserMode,
            priceTotalFormat,
            businessYearSelectBoxOptions,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceTotalOptions,
            getInitialStateTableInstance,
            initialStateAddButtonOptions,
            onInitNewRowInitialState,
            onPopupShown,
            initialStateItemTableData,
            exportInitialState,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#initialStateTable {
    max-width: 100%;
}
#initialStateItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
