<template>
    <div class="table-container">
        <span class="table-name">Otpremnica</span>
        <DxDataGrid
            id="dispatchNoteTable"
            :data-source="dispatchNote"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getDispatchNoteTableInstance"
            @init-new-row="onInitNewRowDispatchNote"
            @row-inserting="onRowInsertingDispatchNote"
            @row-inserted="onRowInsertedDispatchNote"
            @editing-start="onEditingStartDispatchNote"
            @row-updating="onRowUpdatingDispatchNote"
            @row-updated="onRowUpdatedDispatchNote"
            @row-removing="onRowRemovingDispatchNote"
            @focused-row-changed="onFocusedRowChangedDispatchNote"
            @edit-canceled="onEditCanceledDispatchNote"
            @selection-changed="onSelectionChangedDispatchNote"
            edit-template="dispatchNoteEditor"
        >
            <DxPaging :page-size="20" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="dispatchNoteTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="dispatchNoteAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="dispatchNoteRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="generateInvoiceFromSelectionOptions"
                    :disabled="selectedRowKeys.length === 0"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o otpremnici"
                    :toolbar-items="dispatchNotePopupToolbarItems"
                    :drag-outside-boundary="true"
                    @shown="onPopupShown"
                />
                <DxForm>
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>
                        <DxItem
                            data-field="userId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="userSelectBoxOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>
                        <DxItem data-field="remark" data-type="string">
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="warehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="warehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="invoiceId" data-type="number" />
                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                    </DxItem>
                    <DxItem item-type="group" :col-span="2">
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="dispatchNoteItemTable"
                                    :data-source="dispatchNoteItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="
                                        getDispatchNoteItemTableInstance
                                    "
                                    @editing-start="
                                        onEditingStartDispatchNoteItem
                                    "
                                    @row-inserting="
                                        onRowInsertingDispatchNoteItem
                                    "
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxToolbar>
                                        <DxItem
                                            id="addButton"
                                            :options="
                                                dispatchNoteItemAddButtonOptions
                                            "
                                            location="after"
                                            locate-in-menu="auto"
                                            widget="dxButton"
                                        />
                                    </DxToolbar>
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="350"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                dispatchNoteItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule />
                                                </DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="Stanje na skladištu"
                                                    data-type="number"
                                                    :editor-options="
                                                        warehouseStatusOptions
                                                    "
                                                />
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Nabavna cijena"
                                        format="#,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="#,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Ukupna cijena"
                                        format="#,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editDispatchNoteItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteDispatchNoteItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="warehouseId"
                data-type="number"
                caption="Skladište"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
            />
            <DxColumn data-field="userId" data-type="number" caption="Kupac">
                <DxLookup
                    :data-source="dataSource"
                    value-expr="id"
                    :displayExpr="userName"
                />
            </DxColumn>
            <DxColumn data-field="remark" data-type="string" caption="Opis" />
            <DxColumn
                data-field="invoiceId"
                data-type="number"
                caption="Broj računa"
            >
                <!-- <DxLookup
                    :data-source="invoice"
                    value-expr="id"
                    display-expr="number"
                /> -->
            </DxColumn>
            <DxColumn
                data-field="priceTotal"
                data-type="number"
                caption="Ukupno"
                format="###,###,###,##0.00"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editDispatchNote"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteDispatchNote"
                />
                <DxButton
                    hint="Izradi PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportDispatchNote"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
            <DxSelection show-check-boxes-mode="always" mode="multiple" />
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
    DxSelection,
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import eventBus from "../../eventBus.js";
import { useDispatchNote } from "@/composables/useDispatchNote.js";
import { useInvoice } from "@/composables/useInvoice.js";
import { ref } from "vue";

export default {
    name: "dispatchNoteTableComponent",
    components: {
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
        DxSelection,
    },
    setup() {
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);

        //item instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const dateSelectInstance = ref(null);
        const priceTotalInstance = ref(null);
        const priceListItemInstance = ref(null);
        const warehouseStatusInstance = ref(null);

        //general data
        const qty = ref(1);
        const price = ref(0);
        const number = ref(null);
        const priceTotal = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const selectedRowKeys = ref([]);
        const priceListItemId = ref(null);

        const {
            customer,
            user,
            domain,
            invoice,
            warehouse,
            businessYear,
            dispatchNote,
            dispatchNoteItem,
            domainUser,
            dispatchNoteItemData,
            priceTotalFormat,
            adminId,
            domainId,
            businessYearId,
            columnChooserMode,
            warehouseItemStatus,
            priceListItem,
            rowsToBeDeleted,
            handleRowInsertingDispatchNote,
            handleRowInsertedDispatchNote,
            handleEditingStartDispatchNote,
            handleEditCanceledDispatchNote,
            handleRowUpdatingDispatchNote,
            handleRowRemovingDispatchNote,
            handleCancelDispatchNoteItem,
            handleSaveNewDispatchNoteItem,
            handleRowInsertingDispatchNoteItem,
            handleEditingStartDispatchNoteItem,
        } = useDispatchNote();
        const dataSource = Number(domainId.value) === 1 ? user : customer;
        const { generateInvoiceFromDispatchNotes } = useInvoice();

        //button item options
        const dispatchNoteAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addDispatchNote();
            },
        });
        const dispatchNoteItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addDispatchNoteItem();
            },
        });
        const dispatchNoteRefreshButtonOptions = ref({
            icon: "refresh",
            onClick: () => {
                refreshData();
            },
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            onClick: () => {
                openColumnChooser();
            },
        });
        const generateInvoiceFromSelectionOptions = ref({
            icon: "import",
            hint: "Napravi račun",
            onClick: () => {
                generateInvoiceFromSelectedDispatchNotes();
            },
        });
        //Toolbar items
        const dispatchNotePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewDispatchNote(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelDispatchNote(),
                },
            },
        ]);
        const dispatchNoteItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewDispatchNoteItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelDispatchNoteItem(),
                },
            },
        ]);

        //form item options
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: () => {
                numberInstance.value.option("disabled", false);
            },
        });
        const userSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: dataSource.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi kupca",
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
                warehouseInstance.value.focus();
            },
            onSelectionChanged: async (e) => {
                if (!warehouseInstance.value.option("disabled")) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: Number(businessYearId.value),
                    };
                    const res = await dispatchNote.value.load(loadOptions);
                    // console.log(res);
                    if (res.length > 0) {
                        numberInstance.value.option("value", res[0].number + 1);
                        number.value = res[0].number + 1;
                    } else {
                        numberInstance.value.option("value", 1);
                        number.value = 1;
                    }
                }
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: priceListItem.value,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                // console.log(e);
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                // console.log(this.warehouseInstance.option("value"));
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    priceInstance.value.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
            },
        });
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear.value,
            valueExpr: "id",
            disabled: true,
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceOptions = ref({
            disabled: true,
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });

        //instances
        const getDispatchNoteTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getDispatchNoteItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };

        //dispatch note table methods
        const onSelectionChangedDispatchNote = (e) => {
            selectedRowKeys.value = e.selectedRowKeys;
        };
        const onFocusedRowChangedDispatchNote = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit(
                        "newFocusedRowDispatchNote",
                        selectedRowKey.value
                    );
                }
            } else {
                eventBus.emit("newFocusedRowDispatchNote", null);
            }
        };
        const addDispatchNote = () => {
            tableInstance.value.addRow();
        };
        const editDispatchNote = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelDispatchNote = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteDispatchNote = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowDispatchNote = (e) => {
            const now = new Date();
            e.data = {
                businessYearId: Number(businessYearId.value),
            };
            priceListItemId.value = null;
            tableInstance.value.option("focusedRowIndex", -1);
            dispatchNoteItemData.value = [];
            setTimeout(() => {
                dateSelectInstance.value.option("value", now.toISOString());
            }, 100);
        };
        const saveNewDispatchNote = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingDispatchNote = (e) => {
            handleRowInsertingDispatchNote(e, number.value, selectedDate.value);
        };
        const onRowInsertedDispatchNote = async (e) => {
            await handleRowInsertedDispatchNote(e);
            qty.value = 1;
            tableInstance.value.refresh();
        };
        const onEditingStartDispatchNote = async (e) => {
            handleEditingStartDispatchNote(e);
            const now = new Date();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                warehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledDispatchNote = () => {
            handleEditCanceledDispatchNote();
            itemTableInstance.value.refresh();
        };
        const onRowUpdatingDispatchNote = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingDispatchNote(e);
            qty.value = 1;
        };
        const onRowUpdatedDispatchNote = () => {
            eventBus.emit("newDispatchNoteItemAdded");
            tableInstance.value.refresh();
        };
        //TODO: Obrisati id iz računa?
        const onRowRemovingDispatchNote = async (e) => {
            await handleRowRemovingDispatchNote(e);
        };
        const exportDispatchNote = () => {
            eventBus.emit("exportDispatchNote");
        };

        //dispatch note item methods
        const addDispatchNoteItem = () => {
            itemTableInstance.value.addRow();
        };
        const editDispatchNoteItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const deleteDispatchNoteItem = (e) => {
            dispatchNoteItemData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            itemTableInstance.value.refresh();
        };
        const cancelDispatchNoteItem = () => {
            handleCancelDispatchNoteItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const saveNewDispatchNoteItem = () => {
            handleSaveNewDispatchNoteItem(
                qty.value,
                price.value,
                priceTotal.value
            );

            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingDispatchNoteItem = (e) => {
            handleRowInsertingDispatchNoteItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
            qty.value = 1;
        };
        const onEditingStartDispatchNoteItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartDispatchNoteItem(e);
            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
            price.value = e.data.price;
            priceListItemId.value = null;
        };

        //button methods
        const refreshData = () => {
            // console.log("TEST");
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const generateInvoiceFromSelectedDispatchNotes = async () => {
            try {
                const res = await generateInvoiceFromDispatchNotes(
                    selectedRowKeys.value,
                    adminId.value,
                    businessYearId.value,
                    domainId.value
                );
                if (res) {
                    tableInstance.value.clearSelection();
                    tableInstance.value.refresh();
                    const message = "Račun napravljen.";
                    notify(
                        {
                            message,
                            width: 450,
                        },
                        "success"
                    );
                }
            } catch (error) {
                console.error(error);
                notify(
                    {
                        message: error.message,
                        width: 450,
                    },
                    "warning"
                );
            }
        };

        //General methods
        const updateTotalValue = () => {
            priceTotal.value = (qty.value * price.value).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };
        const onPopupShown = () => {
            warehouseInstance.value.focus();
        };
        const userName = (item) => {
            return item.userType === "business"
                ? `${item.id} | ${item.companyName}, ${item.name} ${item.lastname}`
                : item.name || item.lastname
                ? `${item.id} | ${item.name} ${item.lastname}`
                : `${item.id} | ${item.sign}`;
        };

        //subscribe to events
        eventBus.on("dispatchNoteItems", (data) => {
            dispatchNoteItemData.value = data;
        });

        return {
            dataSource,
            domain,
            invoice,
            warehouse,
            businessYear,
            dispatchNote,
            dispatchNoteItem,
            priceListItem,
            domainUser,
            dispatchNoteItemData,
            priceTotalFormat,
            adminId,
            domainId,
            businessYearId,
            columnChooserMode,
            dispatchNoteAddButtonOptions,
            dispatchNoteItemAddButtonOptions,
            dispatchNoteRefreshButtonOptions,
            columnChooserButtonOptions,
            generateInvoiceFromSelectionOptions,
            dispatchNotePopupToolbarItems,
            dispatchNoteItemPopupToolbarItems,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            userSelectBoxOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            selectedRowKeys,
            priceOptions,
            priceTotalOptions,
            getDispatchNoteTableInstance,
            getDispatchNoteItemTableInstance,
            onSelectionChangedDispatchNote,
            onFocusedRowChangedDispatchNote,
            editDispatchNote,
            deleteDispatchNote,
            onInitNewRowDispatchNote,
            saveNewDispatchNote,
            onRowInsertingDispatchNote,
            onRowInsertedDispatchNote,
            onEditingStartDispatchNote,
            onEditCanceledDispatchNote,
            onRowUpdatingDispatchNote,
            onRowUpdatedDispatchNote,
            onRowRemovingDispatchNote,
            addDispatchNoteItem,
            editDispatchNoteItem,
            deleteDispatchNoteItem,
            cancelDispatchNoteItem,
            saveNewDispatchNoteItem,
            onRowInsertingDispatchNoteItem,
            onEditingStartDispatchNoteItem,
            refreshData,
            openColumnChooser,
            generateInvoiceFromSelectedDispatchNotes,
            updateTotalValue,
            onPopupShown,
            userName,
            exportDispatchNote,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#dispatchNoteTable {
    max-width: 100%;
}
#dispatchNoteItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
