import bwipjs from "bwip-js";

export function generateBarcode(data) {
    const canvas = document.createElement("canvas");
    try {
        bwipjs.toCanvas(canvas, {
            bcid: "pdf417",
            text: data,
            scale: 3,
            height: 20,
            includetext: false,
            textxalign: "center",
            errorCorrectionLevel: "H",
        });
    } catch (e) {
        console.error(e);
    }
    return canvas;
}
