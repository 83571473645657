<template>
    <div class="table-container">
        <span class="table-name">Primka</span>
        <DxDataGrid
            id="receiptTable"
            :data-source="receipt"
            :repaint-changes-only="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :column-auto-width="true"
            :hover-state-enabled="true"
            :focused-row-enabled="true"
            :row-alternation-enabled="true"
            :allow-column-resizing="true"
            @initialized="getReceiptTableInstance"
            @init-new-row="onInitNewRowReceipt"
            @row-inserting="onRowInsertingReceipt"
            @row-inserted="onRowInsertedReceipt"
            @editing-start="onEditingStartReceipt"
            @row-updating="onRowUpdatingReceipt"
            @row-updated="onRowUpdatedReceipt"
            @row-removing="onRowRemovingReceipt"
            @focused-row-changed="onFocusedRowChangedReceipt"
            @edit-canceled="onEditCanceledReceipt"
            @exporting="onExportingReceipt"
            edit-template="receiptEditor"
        >
            <DxPaging :page-size="20" />
            <DxExport :enabled="true" :formats="['pdf']" />
            <DxPager
                :visible="true"
                display-mode="full"
                :show-info="true"
                :show-navigation-buttons="true"
                :show-page-size-selector="true"
                :allowed-page-sizes="[10, 20, 30]"
            />
            <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="receiptTableState"
            />
            <DxToolbar>
                <DxItem
                    id="addButton"
                    :options="receiptAddButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="receiptRefreshButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="columnChooserButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    :options="receiptExportButtonOptions"
                    location="after"
                    locate-in-menu="auto"
                    widget="dxButton"
                />
                <DxItem
                    name="searchPanel"
                    location="after"
                    locate-in-menu="auto"
                />
            </DxToolbar>
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxEditing
                :allow-deleting="true"
                :allow-updating="true"
                :allow-adding="true"
                :use-icons="true"
                mode="popup"
            >
                <DxPopup
                    :show-title="true"
                    title="Informacije o primci"
                    :toolbar-items="receiptPopupToolbarItems"
                    :drag-outside-boundary="true"
                    @shown="onAddPopupShown"
                    @hidden="onAddPopupHidden"
                />
                <DxForm @initialized="getReceiptFormInstance">
                    <DxItem :col-count="3" :col-span="2" item-type="group">
                        <DxItem
                            data-field="id"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem item-type="group" col-count="3">
                            <DxItem
                                data-field="number"
                                data-type="number"
                                col-span="2"
                                :editor-options="numberOptions"
                            >
                                <DxRequiredRule />
                                <DxRangeRule :min="1" :max="2147483648" />
                            </DxItem>
                            <DxItem
                                data-field="businessYearId"
                                data-type="number"
                                :editor-options="businessYearSelectBoxOptions"
                            >
                                <DxLabel :visible="false"></DxLabel
                            ></DxItem>
                        </DxItem>

                        <DxItem
                            data-field="supplierId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="supplierSelectBoxOptions"
                        >
                            <DxRequiredRule />
                        </DxItem>

                        <DxItem data-field="remark" data-type="string">
                            <DxStringLengthRule
                                :max="250"
                                message="Opis ne može biti više od 250 znakova."
                            />
                        </DxItem>
                        <DxItem
                            data-field="warehouseId"
                            data-type="number"
                            editor-type="dxSelectBox"
                            :editor-options="warehouseSelectBoxOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem data-field="supplierInvoice" data-type="string"
                            ><DxRequiredRule
                        /></DxItem>

                        <DxItem data-field="calculationId" data-type="number" />
                        <DxItem
                            data-field="date"
                            data-type="date"
                            :editor-options="dateSelectOptions"
                            ><DxRequiredRule
                        /></DxItem>
                        <DxItem
                            data-field="supplierDispatchNote"
                            data-type="string"
                        />

                        <DxItem data-field="dueDate" data-type="date" />

                        <DxItem
                            data-field="modifiedById"
                            data-type="number"
                            :visible="false"
                        />
                        <DxItem
                            data-field="modifiedDate"
                            data-type="datetime"
                            :visible="false"
                        />
                        <DxItem col-span="3">
                            <template #default>
                                <DxDataGrid
                                    id="receiptItemTable"
                                    :data-source="receiptItemData"
                                    :show-column-lines="true"
                                    :show-row-lines="true"
                                    :show-borders="true"
                                    :column-auto-width="true"
                                    @initialized="getReceiptItemTableInstance"
                                    @editing-start="onEditingStartReceiptItem"
                                    @row-inserting="onRowInsertingReceiptItem"
                                >
                                    <DxPaging :page-size="7" />
                                    <DxPager
                                        :visible="true"
                                        display-mode="full"
                                        :show-info="true"
                                        :show-navigation-buttons="true"
                                        :show-page-size-selector="true"
                                    />
                                    <DxEditing
                                        :allow-deleting="true"
                                        :allow-updating="true"
                                        :allow-adding="true"
                                        :use-icons="true"
                                        mode="popup"
                                    >
                                        <DxPopup
                                            :max-height="350"
                                            :max-width="650"
                                            :show-title="true"
                                            title="Informacije o stavci"
                                            :toolbar-items="
                                                receiptItemPopupToolbarItems
                                            "
                                            :drag-outside-boundary="true"
                                            @shown="onItemPopupShown"
                                            @hidden="onItemPopupHidden"
                                        />
                                        <DxForm>
                                            <DxItem
                                                :col-count="1"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="priceListItemId"
                                                    data-type="number"
                                                    editor-type="dxSelectBox"
                                                    :editor-options="
                                                        priceListItemSelectBoxOptions
                                                    "
                                                >
                                                    <DxRequiredRule
                                                /></DxItem>
                                            </DxItem>
                                            <DxItem
                                                :col-count="2"
                                                :col-span="2"
                                                item-type="group"
                                            >
                                                <DxItem
                                                    data-field="price"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="qty"
                                                    data-type="number"
                                                    :editor-options="qtyOptions"
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="priceTotal"
                                                    data-type="number"
                                                    :editor-options="
                                                        priceTotalOptions
                                                    "
                                                >
                                                    <DxRangeRule
                                                        :min="0"
                                                        :max="99999999999.99"
                                                /></DxItem>
                                                <DxItem
                                                    data-field="Stanje na skladištu"
                                                    data-type="number"
                                                    :editor-options="
                                                        warehouseStatusOptions
                                                    "
                                                />
                                            </DxItem>
                                        </DxForm>
                                    </DxEditing>
                                    <DxColumnFixing :enabled="true" />
                                    <DxColumn
                                        data-field="priceListItemId"
                                        data-type="number"
                                        caption="Roba"
                                    >
                                        <DxLookup
                                            :data-source="priceListItem"
                                            value-expr="id"
                                            displayExpr="name"
                                        />
                                    </DxColumn>
                                    <DxColumn
                                        width="120"
                                        data-field="price"
                                        data-type="number"
                                        caption="Nabavna cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="qty"
                                        data-type="number"
                                        caption="Količina"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxColumn
                                        width="120"
                                        data-field="priceTotal"
                                        data-type="number"
                                        caption="Ukupna cijena"
                                        format="###,###,###,##0.00"
                                    />
                                    <DxSummary>
                                        <DxTotalItem
                                            column="priceTotal"
                                            summary-type="sum"
                                            :value-format="priceTotalFormat"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                    <DxColumn
                                        type="buttons"
                                        :auto-width="true"
                                        :fixed="true"
                                    >
                                        <DxButton
                                            hint="Uredi"
                                            icon="rename"
                                            :visible="true"
                                            @click="editReceiptItem"
                                        />
                                        <DxButton
                                            hint="Obriši"
                                            icon="clear"
                                            :visible="true"
                                            @click="deleteReceiptItem"
                                        />
                                    </DxColumn>
                                </DxDataGrid>
                            </template>
                        </DxItem>
                    </DxItem>
                </DxForm>
            </DxEditing>
            <DxColumn
                :width="80"
                data-field="id"
                data-type="number"
                caption="ID"
                :visible="false"
                :allow-exporting="false"
                :allow-filtering="false"
                :show-in-column-chooser="false"
            />
            <DxColumn
                data-field="number"
                data-type="number"
                caption="Broj"
                :allow-hiding="false"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="businessYearId"
                data-type="number"
                caption="God."
                :allow-exporting="false"
                :allow-filtering="false"
            >
                <DxLookup
                    :data-source="businessYear"
                    value-expr="id"
                    display-expr="year"
                />
            </DxColumn>
            <DxColumn
                data-field="warehouseId"
                data-type="number"
                caption="Skladište"
            >
                <DxLookup
                    :data-source="warehouse"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                data-field="date"
                data-type="date"
                caption="Datum"
                format="dd/MM/yyyy"
            >
                <DxHeaderFilter :groupInterval="'month'" />
            </DxColumn>
            <DxColumn
                data-field="supplierId"
                data-type="number"
                caption="Dobavljač"
                :allow-filtering="false"
            >
                <DxLookup
                    :data-source="supplier"
                    value-expr="id"
                    displayExpr="companyName"
                />
            </DxColumn>
            <DxColumn
                data-field="calculationId"
                data-type="number"
                caption="Kalkulacija"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="remark"
                data-type="string"
                caption="Opis"
                :allow-exporting="false"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="priceTotal"
                data-type="number"
                caption="Iznos"
                format="###,###,###,##0.00"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="supplierInvoice"
                data-type="string"
                caption="Broj računa"
                :allow-exporting="false"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="supplierDispatchNote"
                data-type="string"
                caption="Broj otpremnice"
                :allow-exporting="false"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="dueDate"
                data-type="date"
                caption="Dospijeće"
                format="dd/MM/yyyy"
                :allow-exporting="false"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="createdById"
                data-type="number"
                caption="Kreirao"
                :allow-exporting="false"
                :allow-filtering="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="createdDate"
                data-type="datetime"
                caption="Kreirano"
                format="dd/MM/yyyy HH:mm"
                :allow-exporting="false"
                :allow-filtering="false"
            />
            <DxColumn
                data-field="modifiedById"
                data-type="number"
                caption="Uredio"
                :allow-exporting="false"
                :allow-filtering="false"
            >
                <DxLookup
                    :data-source="domainUser"
                    value-expr="id"
                    display-expr="username"
                />
            </DxColumn>
            <DxColumn
                data-field="modifiedDate"
                data-type="datetime"
                caption="Uređeno"
                format="dd/MM/yyyy HH:mm"
                :allow-exporting="false"
                :allow-filtering="false"
            />

            <DxColumn
                data-field="domainId"
                data-type="number"
                caption="Domena"
                :visible="false"
                :allow-hiding="false"
                :show-in-column-chooser="false"
                :allow-exporting="false"
                :allow-filtering="false"
            >
                <DxLookup
                    :data-source="domain"
                    value-expr="id"
                    display-expr="name"
                />
            </DxColumn>
            <DxColumn
                type="buttons"
                :auto-width="true"
                :fixed="true"
                :show-in-column-chooser="false"
            >
                <DxButton
                    hint="Uredi"
                    icon="rename"
                    :visible="true"
                    @click="editReceipt"
                />
                <DxButton
                    hint="Obriši"
                    icon="clear"
                    :visible="true"
                    @click="deleteReceipt"
                />
                <DxButton
                    hint="Izradi PDF"
                    icon="pdffile"
                    :visible="true"
                    @click="exportReceipt"
                />
            </DxColumn>
            <DxColumnChooser :enabled="true" :mode="columnChooserMode">
                <DxColumnChooserSelection
                    :allow-select-all="true"
                    :select-by-click="true"
                    :recursive="true"
                />
            </DxColumnChooser>
            <DxSummary>
                <DxTotalItem
                    column="priceTotal"
                    summary-type="sum"
                    :value-format="priceTotalFormat"
                    display-format="UKUPNO: {0}"
                />
            </DxSummary>
        </DxDataGrid>
    </div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule,
    DxExport,
} from "devextreme-vue/data-grid";

import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";

import eventBus from "../../eventBus.js";
import { ref, onMounted } from "vue";
import { useReceipt } from "@/composables/useReceipt";
export default {
    name: "receiptTableComponent",
    components: {
        DxForm,
        DxItem,
        DxPopup,
        DxPager,
        DxLabel,
        DxColumn,
        DxButton,
        DxLookup,
        DxPaging,
        DxExport,
        DxEditing,
        DxToolbar,
        DxSummary,
        DxDataGrid,
        DxTotalItem,
        DxRangeRule,
        DxScrolling,
        DxSearchPanel,
        DxColumnFixing,
        DxHeaderFilter,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxStringLengthRule,
        DxColumnChooserSelection,
    },
    setup() {
        onMounted(() => {
            document.addEventListener("keydown", keyDownEvent, false);
        });
        //table instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);

        //items instances
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const priceTotalInstance = ref(null);
        const dateSelectInstance = ref(null);
        const receiptFormInstance = ref(null);
        const priceListItemInstance = ref(null);
        const warehouseStatusInstance = ref(null);

        //general data
        const qty = ref(1);
        const price = ref(0);
        const number = ref(null);
        const priceTotal = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const priceListItemId = ref(null);
        const addPopupOpened = ref(null);
        const addItemPopupOpened = ref(null);

        const {
            domain,
            domainUser,
            customer,
            receipt,
            supplier,
            warehouse,
            receiptItem,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            rowsToBeDeleted,
            businessYearId,
            receiptItemData,
            columnChooserMode,
            priceTotalFormat,
            handleRowInsertingReceipt,
            handleRowInsertedReceipt,
            handleEditingStartReceipt,
            handleEditCanceledReceipt,
            handleRowUpdatingReceipt,
            handleRowRemovingReceipt,
            handleExportingReceiptRecap,
            handleCancelReceiptItem,
            handleSaveNewReceiptItem,
            handleRowInsertingReceiptItem,
            handleEditingStartReceiptItem,
        } = useReceipt();

        //button item options
        const receiptAddButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu primku",
            onClick: () => addReceipt(),
        });
        const receiptItemAddButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu stavku",
            onClick: () => addReceiptItem(),
        });
        const receiptRefreshButtonOptions = ref({
            icon: "refresh",
            hint: "Osvježi tablicu",
            onClick: () => refreshData(),
        });
        const receiptExportButtonOptions = ref({
            icon: "export",
            hint: "Rekapitulacija primke",
            onClick: () => onExportingReceipt(),
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            hint: "Odaberi stupce",
            onClick: () => openColumnChooser(),
        });

        //toolbar items
        const receiptPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewReceipt(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelReceipt(),
                },
            },
        ]);
        const receiptItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    onClick: () => saveNewReceiptItem(),
                },
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    onClick: () => cancelReceiptItem(),
                },
            },
        ]);

        //form item options
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            },
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            },
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: (e) => {
                number.value = e.value;
                numberInstance.value.option("disabled", false);
            },
        });
        const supplierSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: supplier.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi dobavljača",
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
            },
            onSelectionChanged: async (e) => {
                if (!warehouseInstance.value.option("disabled")) {
                    const loadOptions = {
                        warehouseId: e.selectedItem.id,
                        businessYearId: businessYearId.value,
                    };
                    const res = await receipt.value.load(loadOptions);
                    if (res.length > 0) {
                        number.value = res[0].number + 1;
                        numberInstance.value.option("value", number.value);
                    } else {
                        number.value = 1;
                        numberInstance.value.option("value", 1);
                    }
                }
            },
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceListItem.value,
                paginate: true,
                pageSize: 30,
            }),
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId,
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.latestProcurementPrice !== null) {
                    priceInstance.value.option(
                        "value",
                        res.latestProcurementPrice
                    );
                } else {
                    priceInstance.value.option("value", 0);
                }
                priceListItemId.value = e.value;
            },
        });
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear,
            valueExpr: "id",
            disabled: true,
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceOptions = ref({
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            },
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: "###,###,###,##0.00",
            disabled: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
        });

        //instances
        const getReceiptTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getReceiptItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };
        const getReceiptFormInstance = (e) => {
            receiptFormInstance.value = e.component;
        };

        //receipt table methods
        const onFocusedRowChangedReceipt = (e) => {
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit("newFocusedRowReceipt", selectedRowKey.value);
                }
            } else {
                eventBus.emit("newFocusedRowReceipt", null);
            }
        };
        const addReceipt = () => {
            tableInstance.value.addRow();
        };
        const editReceipt = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelReceipt = () => {
            tableInstance.value.cancelEditData();
        };
        const deleteReceipt = (e) => {
            tableInstance.value.deleteRow(e.row.rowIndex);
        };
        const onInitNewRowReceipt = (e) => {
            const now = new Date().toISOString();
            e.data = {
                businessYearId: Number(businessYearId.value),
            };
            priceListItemId.value = null;
            tableInstance.value.option("focusedRowIndex", -1);
            receiptItemData.value = [];
            setTimeout(() => {
                dateSelectInstance.value.option("value", now);
            }, 100);
        };
        const saveNewReceipt = () => {
            tableInstance.value.saveEditData();
        };
        const onRowInsertingReceipt = (e) => {
            handleRowInsertingReceipt(e, number.value, selectedDate.value);
        };
        const onRowInsertedReceipt = async (e) => {
            await handleRowInsertedReceipt(e);
            qty.value = 1;
            tableInstance.value.refresh();
            addPopupOpened.value = false;
        };
        const onEditingStartReceipt = async (e) => {
            await handleEditingStartReceipt(e);
            const now = new Date().toISOString();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now
                );
                warehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledReceipt = () => {
            handleEditCanceledReceipt();
        };
        const onRowUpdatingReceipt = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingReceipt(e);
            receiptItemData.value = [];
            qty.value = 1;
        };
        const onRowUpdatedReceipt = () => {
            eventBus.emit("newReceiptItemAdded");
            tableInstance.value.refresh();
        };
        const onRowRemovingReceipt = async (e) => {
            await handleRowRemovingReceipt(e);
        };
        const onExportingReceipt = async () => {
            await handleExportingReceiptRecap(tableInstance.value);
        };
        const exportReceipt = () => {
            eventBus.emit("exportReceipt");
        };

        //receipt item table methods
        const addReceiptItem = () => {
            itemTableInstance.value.addRow();
            addItemPopupOpened.value = true;
        };
        const editReceiptItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelReceiptItem = () => {
            handleCancelReceiptItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteReceiptItem = (e) => {
            receiptItemData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            itemTableInstance.value.refresh();
        };
        const saveNewReceiptItem = () => {
            handleSaveNewReceiptItem(qty.value, price.value, priceTotal.value);
            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingReceiptItem = (e) => {
            handleRowInsertingReceiptItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
            qty.value = 1;
        };
        const onEditingStartReceiptItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartReceiptItem(e);
            price.value = e.data.price;
            priceListItemId.value = null;
            priceListItemInstance.value.option("disabled", true);
        };

        //button methods
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };

        //element events
        const keyDownEvent = (e) => {
            if (
                e.key === "Insert" &&
                addPopupOpened.value === true &&
                addItemPopupOpened.value === false
            ) {
                if (itemTableInstance.value) addReceiptItem();
            }
        };
        const onAddPopupShown = () => {
            warehouseInstance.value.focus();
            addPopupOpened.value = true;
        };
        const onAddPopupHidden = () => {
            addPopupOpened.value = false;
        };
        const onItemPopupShown = () => {
            addItemPopupOpened.value = true;
        };
        const onItemPopupHidden = () => {
            addItemPopupOpened.value = false;
        };

        //general methods
        const updateTotalValue = () => {
            priceTotal.value = (qty.value * price.value).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };
        const supplierName = (item) => {
            return `${item.name} ${item.lastname}`;
        };

        //subscribe to events
        eventBus.on("receiptItems", (data) => {
            receiptItemData.value = data;
        });

        return {
            domain,
            domainUser,
            customer,
            receipt,
            supplier,
            warehouse,
            receiptItem,
            businessYear,
            priceListItem,
            warehouseItemStatus,
            rowsToBeDeleted,
            businessYearId,
            receiptItemData,
            columnChooserMode,
            priceTotalFormat,
            receiptAddButtonOptions,
            receiptItemAddButtonOptions,
            receiptRefreshButtonOptions,
            receiptExportButtonOptions,
            columnChooserButtonOptions,
            receiptPopupToolbarItems,
            receiptItemPopupToolbarItems,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            supplierSelectBoxOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            businessYearSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceTotalOptions,
            getReceiptTableInstance,
            getReceiptItemTableInstance,
            getReceiptFormInstance,
            onFocusedRowChangedReceipt,
            editReceipt,
            cancelReceipt,
            deleteReceipt,
            onInitNewRowReceipt,
            saveNewReceipt,
            onRowInsertingReceipt,
            onRowInsertedReceipt,
            onEditingStartReceipt,
            onEditCanceledReceipt,
            onRowUpdatingReceipt,
            onRowUpdatedReceipt,
            onRowRemovingReceipt,
            onExportingReceipt,
            addReceiptItem,
            editReceiptItem,
            cancelReceiptItem,
            deleteReceiptItem,
            saveNewReceiptItem,
            onRowInsertingReceiptItem,
            onEditingStartReceiptItem,
            onAddPopupShown,
            onAddPopupHidden,
            onItemPopupShown,
            onItemPopupHidden,
            supplierName,
            exportReceipt,
        };
    },
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#receiptTable {
    max-width: 100%;
}
#receiptItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: left;
}
</style>
